import React from "react";
import { User } from "../../models/User";
import {
	MainForm,
	ButtonsWrap,
	ButtonPop,
} from "../Forms/EditUsersForm/EditUsersForm";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import colors from "../../ui/colors";
import styled from "styled-components";
import { Subscriptor } from "../AdministradorPanel/AdministradorPanel";
import { useDeleteUserMutation } from "../../apis/users.api";
import { Toaster, toast } from "sonner";

interface AdministradorDeleteProps {
	setDeleteStatus: React.Dispatch<React.SetStateAction<boolean>>;
	setDeleteIcon: React.Dispatch<React.SetStateAction<number | null>>;
	UserSelected: User;
}
export const AdministradorDelete: React.FC<AdministradorDeleteProps> = ({
	setDeleteStatus,
	setDeleteIcon,
	UserSelected,
}) => {
	const [deleteUser] = useDeleteUserMutation();
	const handleDelete = async () => {
		try {
			const response = await deleteUser(UserSelected._id);
			if ("error" in response) {
				if ("data" in response.error) {
					const data: any = response.error.data;
					if ("message" in data) {
						const errorMessage = JSON.stringify(data.message).replace(
							/[[\]"]/g,
							" "
						);
						toast.error(errorMessage.toUpperCase());
					} else {
						toast.error(`An error occurred: ${response.error}`);
					}
				}
				return;
			}
			toast.success("Usuario Eliminado con éxito");
			setDeleteStatus((prevState) => !prevState);
			setDeleteIcon(null);
		} catch (error) {
			toast.error(`An error occurred`);
		}
	};
	return (
		<>
			<Toaster position="top-center" richColors />
			<MainForm>
				<Title>Eliminar usuario</Title>
				<Message>
					Esta acción eliminará permanentemente todos los datos asociados con el
					usuario, incluyendo sus archivos, ajustes y registros. Por favor,
					confirma tu elección antes de continuar.
				</Message>
				<Subscriptor>
					<AccountCircleOutlinedIcon
						style={{
							fontSize: "1rem",
							color: `${colors.green}`,
							paddingRight: "2.5%",
						}}
					/>
					{UserSelected.username}
				</Subscriptor>
			</MainForm>
			<ButtonsWrap>
				<ButtonPop
					onClick={handleDelete}
					type="submit"
					backgroundColor={`${colors.primaryGreen}`}
					textColor={`${colors.white}`}
				>
					{`ELIMINAR USUARIO`}
				</ButtonPop>

				<ButtonPop
					onClick={() => {
						setDeleteStatus((prevState) => !prevState);
						setDeleteIcon(null);
					}}
					backgroundColor={`${colors.white}`}
					textColor={`${colors.primaryGreen}`}
				>
					{`CANCELAR`}
				</ButtonPop>
			</ButtonsWrap>
		</>
	);
};

const Title = styled.h1`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 20.5423px;
	line-height: 25px;

	color: ${colors.primaryGreen};
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Message = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 0.75rem;
	line-height: 1.125rem;

	text-align: center;
	color: ${colors.primaryGreen};
	margin-bottom: 5%;
`;
