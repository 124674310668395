import * as yup from "yup";

// Error messages
export const REQUIRED = "The field is required";
export const VALID_PASSWORD = "Please enter a valid password";
export const VALID_EMAIL = "Please enter a valid email";
export const ENTER_CITY = "Please enter a city";
export const ENTER_STATE = "Please enter a state";
export const ENTER_POSTAL_CODE = "Please enter a postal code";
export const ENTER_COUNTRY = "Enter a valid country";
export const ONLY_LETTERS = "Only letters are allowed";

// Field validation schemas
const genericSchema = yup.string().required(REQUIRED);
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const nameSchema = yup
	.string()
	.matches(/^[a-zA-Z]+$/, ONLY_LETTERS)
	.required(REQUIRED);
const nameSimpleSchema = yup.string().required(REQUIRED);
const lastNameSchema = yup
	.string()
	.matches(/^[a-zA-Z]+$/, ONLY_LETTERS)
	.required(REQUIRED);
const passwordSchema = yup
	.string()
	.min(5)
	.matches(passwordRules, { message: VALID_PASSWORD })
	.required(VALID_PASSWORD);
const emailSchema = yup.string().email(VALID_EMAIL).required();
const departmentSchema = yup.string().optional();
const citySchema = yup.string().required(ENTER_CITY);
const stateSchema = yup.string().required(ENTER_STATE);
const postalCodeSchema = yup.string().required(ENTER_POSTAL_CODE);
const countrySchema = yup.string().required(ENTER_COUNTRY);
const TitleSchema = yup.string().required(REQUIRED);
const ContentSchema = yup.string().required(REQUIRED);

// Main schemas
export const AddContactSchema = yup.object().shape({
	name: nameSchema,
	lastName: lastNameSchema,
	email: emailSchema,
});

export const AddNewSchema = yup.object().shape({
	title: TitleSchema,
	content: ContentSchema,
});

export const AddCompanySchema = yup.object().shape({
	nameCompany: nameSimpleSchema,
});
export const AddressSchema = yup.object().shape({
	department: departmentSchema,
	city: citySchema,
	state: stateSchema,
	zip: postalCodeSchema,
	country: countrySchema,
});

export const CreateUserSchema = yup.object().shape({
	username: nameSchema,
	password: passwordSchema,
	email: emailSchema,
	role: genericSchema,
	position: genericSchema,
	company: genericSchema,
});

export const LoginSchema = yup.object().shape({
	username: nameSchema,
	password: passwordSchema,
});

export const AddressInitial = {
	department: "",
	city: "",
	state: "",
	zip: "",
	country: "",
};

export const LoginInitial = { username: "", password: "" };

export const ContactInitial = { name: "", lastName: "", email: "" };

export const NewsInitial = { title: "", content: "" };

export const CreateUserInitial = {
	username: "",
	password: "",
	email: "",
	role: "",
	position: "",
	company: "",
};
