import styled from "styled-components";
import colors from "../ui/colors";

export const LogoImg = styled.img`
	margin-left: 1rem;
	width: 3rem;
	height: 3rem;
`;

export const NameImg = styled.img`
	margin-left: 2.5rem;
	width: 12rem;
	height: 1rem;
`;

export const LogoHeadingDiv = styled.div`
	position: absolute;
	width: 13.54%;
	height: 63.75%;
	margin: 0;
	padding: 0;
	left: 4.5%;
	top: 15%;

	${NameImg} {
		position: absolute;
		left: 17%;
		top: 37.25%;
	}

	${LogoImg} {
		position: absolute;
		left: 0%;
		top: 0%;
	}
`;
export const MenuButton = styled.button`
	width: 2.01%;
	height: 40%;
	margin: 0;
	padding: 0;
	border-radius: 50%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
	cursor: pointer;
	::before {
		content: "";
		position: absolute;
		width: 125%;
		height: 125%;
		border-radius: 50%;
		background-color: transparent;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		box-sizing: border-box;
	}

	:hover::before {
		background-color: #83ac98; /* Set hover background color */
		opacity: 0.7; /* Set hover opacity */
	}
`;

export const SearchDiv = styled.div`
	width: 13.54%;
`;

export const WrapperDiv = styled.div`
	display: flex;
	padding-right: 2rem;
	width: 100%;
	height: 100%;
	align-self: flex-end;
	align-items: center;
	justify-content: flex-end;
`;
export const NewAddDiv = styled.div`
	margin: 0;
	padding: 0;
	border-radius: 2px;
`;
export const LandingHeaderDiv = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	max-height: 5rem;
	background-color: ${colors.white};
	display: flex;
	border-bottom: 2px solid ${colors.primaryGreen};
	align-element: center;

	${MenuButton} {
		position: absolute;
		left: 2.08%;
		top: 30%;
	}

	${NewAddDiv} {
		position: absolute;
		left: 76.81%;
		top: 35%;
	}

	${SearchDiv} {
		position: absolute;
		top: 35%;
		left: 84.34%;
	}
`;
