import { useState } from "react";

export default function useHeader() {
	const [isHeaderActivated, setIsHeaderActivated] = useState<boolean>(false);

	const handleHeader = () =>
		setIsHeaderActivated((prevIsHeaderActivated) => !prevIsHeaderActivated);

	return { isHeaderActivated, handleHeader };
}
