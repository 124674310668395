import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../ui/colors";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
	TableFooter,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TableBody,
	Table,
	Box,
} from "@mui/material";
import { FolderResumeProps } from "../FolderResume/FolderResume";

export const LiquidationTable: React.FC<FolderResumeProps> = ({
	FolderData,
}) => {
	const LiquidationData = FolderData.Carpetas.LiquidacionesAduaneras;
	const [totalImport, setTotalImport] = useState<number>(0);
	const EmptyMessage = "Sin datos asignados";

	const allSameMoneda = LiquidationData?.every(
		(data) => data.Moneda === LiquidationData[0]?.Moneda
	);

	const coinBase = allSameMoneda
		? LiquidationData?.[0]?.Moneda === "1"
			? "PESOS"
			: LiquidationData?.[0]?.Moneda === "2"
			? "USD"
			: ""
		: "";

	useEffect(() => {
		const importTotal = LiquidationData?.reduce(
			(accumulator, data) =>
				accumulator + parseInt(data.Importe.replace("$", "")),
			0
		);
		setTotalImport(importTotal || 0);
	}, [LiquidationData]);

	return (
		<Box sx={{ width: "100%" }}>
			<TitleWrap>
				<Title>Liquidación</Title>
			</TitleWrap>
			<Paper elevation={0} style={{ backgroundColor: colors.white }}>
				<StyledTableContainer
					style={{
						maxHeight: "200px",
						borderRadius: "8px",
						boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
					}}
				>
					<Table
						sx={{
							minWidth: "350px",
							background: colors.white,
							height: 100,
							borderRadius: "4px",
						}}
						aria-label="ClientsTable"
					>
						<TableHead>
							<TableRow>
								<StyledTableCell>Concepto</StyledTableCell>
								<StyledTableCell>Total</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{LiquidationData && LiquidationData.length > 0 ? (
								LiquidationData.map((data, index) => (
									<TableRow
										key={index}
										style={{
											fontFamily: "Poppins",
											fontStyle: "normal",
											fontWeight: 500,
											fontSize: "0.75rem",
											lineHeight: "1rem",
											letterSpacing: "0.198851px",
											color: colors.deepGreen,
										}}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<StyledTableCell align="center" scope="row">
											{data.Concepto}
										</StyledTableCell>
										<StyledTableCell align="center">
											{`$ ${parseInt(data.Importe)}.00 ${
												data.Moneda === "1"
													? "PESOS"
													: data.Moneda === "2"
													? "USD"
													: ""
											}`}
										</StyledTableCell>
									</TableRow>
								))
							) : (
								<>
									<TableRow
										style={{
											fontFamily: "Poppins",
											fontStyle: "normal",
											fontWeight: 500,
											fontSize: "0.75rem",
											lineHeight: "1rem",
											letterSpacing: "0.198851px",
											color: colors.deepGreen,
										}}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<StyledTableCell colSpan={5} align="center">
											{EmptyMessage.toUpperCase()}
										</StyledTableCell>
									</TableRow>
								</>
							)}
						</TableBody>
						<TableFooter style={{}}>
							<TableRow
								style={{
									position: "sticky",
									bottom: 0,
									margin: 0,
									backgroundColor: colors.primaryGreen,
								}}
							>
								<StyledTableCell align="center">Total</StyledTableCell>
								<StyledTableCell align="center">{`$ ${totalImport} ${coinBase}`}</StyledTableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</StyledTableContainer>
			</Paper>
		</Box>
	);
};

export default LiquidationTable;
const Title = styled.h1`
	width: 100%;
	height: 100%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 1rem;
	line-height: 22px;

	display: flex;
	align-items: center;
	color: ${colors.green};
`;

const TitleWrap = styled.div`
	display: flex;
	width: 100%;
	height: 10%;
	align-items: center;
	justify-content: space-around;
	border-bottom: 1px solid ${colors.primaryGreen};
	margin-bottom: 3%;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.primaryGreen,
		color: colors.white,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198519px",
		textAlign: "center",
	},
	[`&.${tableCellClasses.body}`]: {
		height: "15px",
		alignItems: "center",
		textAlign: "center",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198851px",
		color: colors.deepGreen,
		borderBottom: `1px solid ${colors.primaryGreen}`,
	},
	[`&.${tableCellClasses.footer}`]: {
		color: colors.white,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198519px",
		textAlign: "center",
		margin: 0,
	},
}));
const StyledTableContainer = styled(TableContainer)`
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.primaryGreen};
		border-radius: 0px 8px 8px 0px;
		border: 1px solid ${colors.primaryGreen};
	}
`;
