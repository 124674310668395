import React from "react";
import PopupForm from "../PopupForm/popupForm";
import styled from "styled-components";

interface PopupTriggerInterface {
	openPopup: boolean;
	setopenPopUp: React.Dispatch<React.SetStateAction<boolean>>;
	handdleForm: string;
	sethanddleForm: React.Dispatch<React.SetStateAction<string>>;
	Username: string;
}
export const PopupTrigger: React.FC<PopupTriggerInterface> = ({
	openPopup,
	setopenPopUp,
	handdleForm,
	sethanddleForm,
	Username,
}) => {
	return (
		<>
			{openPopup && (
				<Popup>
					<PopupForm
						trigger={openPopup}
						setTrigger={setopenPopUp}
						form={handdleForm}
						formType={handdleForm}
						Username={Username}
					/>
				</Popup>
			)}
		</>
	);
};

export default PopupTrigger;

export const Popup = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(31, 107, 69, 0.8);
	z-index: 2;
`;
