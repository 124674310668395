import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PopupTriggerUser from "../PopupTriggerUsers/popupTriggerUsers";
import UserInformationTable from "../../../template/UserInformation/UserInformation";
import AdministradorPanel from "../../../template/AdministradorPanel/AdministradorPanel";
import { UpdateDataCreator } from "../../../helpers/DataCreator";
import { useLocation } from "react-router-dom";

export const CreatorMain: React.FC = () => {
	const location = useLocation();
	const userInfo = location.state?.userInfo;
	const { allUsersDataLoading: usersDataLoading, refetchAllUsers } =
		UpdateDataCreator();

	useEffect(() => {
		refetchAllUsers();
	}, [refetchAllUsers]);

	const [openPopup, setopenPopUp] = useState<boolean>(false);
	const [form, setForm] = useState<string>("");
	useEffect(() => {
		if (!openPopup) {
			refetchAllUsers();
		}
	}, [openPopup, refetchAllUsers]);
	const AdminitratorPanelHandler = (Trigger: boolean, form: string) => {
		setopenPopUp(Trigger);
		setForm(form);
	};
	return (
		<>
			<PopupTriggerUser
				openPopup={openPopup}
				setopenPopUp={setopenPopUp}
				userValidated={userInfo._id}
				formSelection={form}
				UserRole={userInfo.role[0]}
			/>
			<UserProfileWrap>
				<ResumeDiv>
					<UserInformationTable userInfo={userInfo} />
				</ResumeDiv>
				<AdministratorWrap>
					<AdministradorPanel
						PopController={AdminitratorPanelHandler}
						usersDataLoading={usersDataLoading}
						UserRole={userInfo.role[0]}
					/>
				</AdministratorWrap>
			</UserProfileWrap>
		</>
	);
};
export default CreatorMain;
const ResumeDiv = styled.div`
	width: 100%;
	height: 100%;
	max-height: 684px;
	max-width: 1089px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-radius: 8px;
`;

const AdministratorWrap = styled.div`
	width: 100%;
	height: 100%;
	max-height: 978px;
	max-width: 1089px;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const UserProfileWrap = styled.div`
	display: grid;
	padding: 0 2rem;
	padding-top: 3.5%;
	grid-template-columns: 1.06fr 0.925fr;
	grid-template-rows: 342px;
	grid-column-gap: 2.5%;
`;
