import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../colors";

interface Clients {
	ClientName: string;
	Folders: string[];
	onClientNameClick: (clientName: string, folder: string) => void;
	onSelectionChange: () => void;
	isActive: boolean;
	index: number;
	Company_id: string;
}

export const ClientShowerBar: React.FC<Clients> = ({
	ClientName,
	Company_id,
	Folders,
	onClientNameClick,
	onSelectionChange,
	isActive = false,
	index,
}) => {
	const [Hidden, setIsHidden] = useState<boolean>(true);
	const [activeFolder, setActiveFolder] = useState<string>("");
	const handleClick = () => {
		if (!Hidden && isActive) {
			onClientNameClick(Company_id, activeFolder);
		} else {
			setIsHidden(!Hidden);
			onSelectionChange();
			onClientNameClick(Company_id, "");
		}
		setIsHidden(!Hidden);
	};

	const handleFolderClick = (folder: string) => {
		setActiveFolder(folder);
		onSelectionChange();
		onClientNameClick(Company_id, folder);
	};

	useEffect(() => {
		if (!isActive) {
			setActiveFolder("");
			setIsHidden(true);
		}
	}, [isActive]);

	return (
		<ShowerDiv key={index}>
			<Client onClick={handleClick} isActive={isActive}>
				{ClientName}
			</Client>
			{!Hidden && (
				<FolderDisplay key={ClientName}>
					{[...Folders].sort().map((folder, index) => (
						<FolderSpan
							key={folder + index}
							onClick={() => handleFolderClick(folder)}
							isActive={folder === activeFolder}
						>
							{folder}
						</FolderSpan>
					))}
				</FolderDisplay>
			)}
		</ShowerDiv>
	);
};

const FolderSpan = styled.span<{ isActive: boolean }>`
	width: 100%;
	margin: 0;
	padding: 0;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: ${colors.white};
	padding-left: 15%;
	margin-bottom: 2%;
	text-align: left;
	cursor: pointer;

	:hover {
		font-weight: 700;
		color: ${colors.white};
	}

	${({ isActive }) =>
		isActive &&
		`
    font-weight: 700;
    color: ${colors.white};
  `}
`;

const FolderDisplay = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
`;

const Client = styled.h2<{ isActive: boolean }>`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.375rem;
	color: ${colors.white};
	padding-left: 10%;
	display: flex;
	cursor: pointer;

	:hover {
		color: ${colors.white};
	}

	${({ isActive }) =>
		isActive &&
		`
    font-weight: 700;
    color: ${colors.white};
  `}
`;

const ShowerDiv = styled.div`
	width: 100%;
	border-bottom: 0.3px solid ${colors.white};
`;
