import { Grid } from "@mui/material";
import styled from "styled-components";
import colors from "../../colors";
import FilterListIcon from "@mui/icons-material/FilterList";
import oec from "../../../assets/images/oec.png";
const StyledSecurityPolicyDiv = styled.div`
	background-color: ${colors.green};
	margin: 5rem 3rem 0 3rem;
	align-self: center;
	border-radius: 20px;
	padding: 5rem 9.5rem;
	@media (max-width: 1300px) {
		padding: 5rem 5rem;
	}
	@media (max-width: 1000px) {
		margin: 2rem;
		padding: 3rem;
	}
	@media (max-width: 600px) {
		margin: 1rem;
		padding: 3rem;
	}
`;

const StyledwhiteHeadingH2 = styled.h2`
	color: ${colors.white};
	font-size: 3.7rem;
	font-weight: 700;
	max-width: 30rem;
	@media (max-width: 800px) {
		text-align: center;
	}
	@media (max-width: 600px) {
		font-size: 3rem;
	}
	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
`;

const StyledFilterListIcon = styled(FilterListIcon)`
	color: ${colors.green};
	background-color: ${colors.white};
	border-radius: 50%;
	padding: 1rem;
	@media (max-width: 900px) {
		align-self: center;
	}
`;

const StyledCardP = styled.p`
	color: ${colors.white};
	font-size: 1rem;
	align-self: flex-end;
	font-weight: 500;
	line-height: 1.3;
	max-width: 90%;
`;

const StyledHeaderDiv = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;
export const SecurityPolicy: React.FC = () => {
	return (
		<StyledSecurityPolicyDiv>
			<StyledHeaderDiv>
				<StyledwhiteHeadingH2>Políticas de seguridad</StyledwhiteHeadingH2>
				<img src={oec} alt="oec" />
			</StyledHeaderDiv>
			<Grid container sx={{ marginTop: "1px" }} spacing={5}>
				<Grid item sm={6} md={4} lg={4} xl={3}>
					<div>
						<StyledFilterListIcon />
						<StyledCardP>
							Oponernos a todo acto o actividad ilícita que detectemos y que
							pueda afectar a algunos de los actores de la cadena de suministro.
						</StyledCardP>
					</div>
				</Grid>
				<Grid item sm={6} md={4} lg={4} xl={3}>
					<div>
						<StyledFilterListIcon />
						<StyledCardP>
							Estar alertas para evitar participar involuntariamente de manejos
							de mercaderías que atenten contra la seguridad.
						</StyledCardP>
					</div>
				</Grid>
				<Grid item sm={6} md={4} lg={4} xl={3}>
					<div>
						<StyledFilterListIcon />
						<StyledCardP>
							Cumplir con los requisitos legales y con las normativas nacionales
							e internacionales vigentes, que aplican anuestras actividades.
						</StyledCardP>
					</div>
				</Grid>
				<Grid item sm={6} md={4} lg={4} xl={3}>
					<div>
						<StyledFilterListIcon />
						<StyledCardP>
							Colaborar en todo lo que esté a nuestro alcance para alcanzar un
							manejo seguro y legítimo de mercaderías.{" "}
						</StyledCardP>
					</div>
				</Grid>
				<Grid item sm={6} md={4} lg={4} xl={4}>
					<div>
						<StyledFilterListIcon />
						<StyledCardP>
							Sostener y mejorar continuamente nuestro Sistema de Gestión de la
							Seguridad, manteniéndonos actualizados y capacitando a nuestra
							gente como modo de fomentar su participación activa.
						</StyledCardP>
					</div>
				</Grid>
				<Grid item sm={6} md={4} lg={4} xl={4}>
					<div>
						<StyledFilterListIcon />
						<StyledCardP>
							Disponer de los recursos necesarios para cumplir con esta
							Política.
						</StyledCardP>
					</div>
				</Grid>
				<Grid item sm={6} md={4} lg={4} xl={4}>
					<div>
						<StyledFilterListIcon />
						<StyledCardP>
							Las actividades ilícitas asociadas al comercio exterior comprenden
							el narcotráfico, el contrabando, el terrorismo, el tráfico de
							productos falsificados, etc.{" "}
						</StyledCardP>
					</div>
				</Grid>
			</Grid>
		</StyledSecurityPolicyDiv>
	);
};
