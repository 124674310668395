import React from "react";
import styled from "styled-components";
import { colors } from "../../ui/colors";

interface InputProps {
	name: string;
	type: string;
	placeholder: string;
	inputWidth?: string;
	onInputChange: (inputValue: string) => void;
	isRequired: boolean;
	inputHeight?: string;
	errorValue?: boolean;
}
export const InputField: React.FC<InputProps> = ({
	name,
	type,
	placeholder,
	inputWidth,
	onInputChange,
	isRequired = false,
	inputHeight,
	errorValue,
}) => {
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const input = event.target.value;
		onInputChange(input);
	};
	return (
		<InputWrap>
			<InputLabel errorHandle={errorValue}>{name}</InputLabel>
			{!isRequired ? (
				<Input
					placeholder={placeholder}
					name={name}
					type={type}
					onChange={handleInputChange}
					inputWidth={inputWidth}
					inputHeight={inputHeight}
					errorHandle={errorValue}
					autoComplete="off"
				/>
			) : (
				<Input
					placeholder={placeholder}
					name={name}
					type={type}
					onChange={handleInputChange}
					inputWidth={inputWidth}
					inputHeight={inputHeight}
					errorHandle={errorValue}
					autoComplete="off"
					required
				/>
			)}
		</InputWrap>
	);
};

interface inputProp {
	inputWidth?: string;
	inputHeight?: string;
	errorHandle?: boolean;
}

const Input = styled.input<inputProp>`
	position: relative;
	border: 1px solid ${(props) => (props.errorHandle ? "red" : colors.grey)};
	border-radius: 0.375rem;
	box-sizing: border-box;
	width: ${(props) => props.inputWidth || "460px"};
	height: ${(props) => props.inputHeight || "45px"};
	left: 0;
	padding: 0;
	padding-left: 2.5%;
	background-color: ${colors.white};

	font-family: "Montserrat";
	text-align: left;
	color: ${colors.deepGreen};

	::placeholder {
		font-style: normal;
		font-weight: 400;
		font-size: 15.0309px;
		line-height: 18px;

		width: auto;
		height: auto;
		color: ${colors.grey};
	}
`;

const InputLabel = styled.p<inputProp>`
	margin: 0;
	padding: 0;
	padding-bottom: 2.5%;
	width: auto;
	height: auto;
	left: 0;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12.0247px;
	line-height: 15px;

	color: ${(props) => (props.errorHandle ? "red" : colors.primaryGreen)};
`;

const InputWrap = styled.div`
	width: 75%;
	height: 66px;

	${InputLabel} {
		justify-content: flex-start;
		position: relative;
		left: 0;
	}
`;
export default InputField;
