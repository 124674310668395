import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserResponse } from "../models/User";
import { LoginRequest } from "../dto/login-request.dto";

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BACK_URL}/auth`,
	}),
	endpoints: (build) => ({
		login: build.mutation<UserResponse, LoginRequest>({
			query: (loginRequest) => ({
				url: "/login",
				method: "POST",
				body: loginRequest,
			}),
		}),
	}),
});

export const { useLoginMutation } = authApi;
