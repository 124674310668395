import React from "react";
import styled from "styled-components";
import { colors } from "../../ui/colors";

interface InputProps {
	name: string;
	placeholder: string;
	inputWidth?: string;
	onInputChange: (selectedValue: string) => void;
	isRequired: boolean;
	inputHeight?: string;
	userTypes: string[];
	errorValue?: boolean;
}

export const InputSelector: React.FC<InputProps> = ({
	name,
	placeholder,
	inputWidth,
	onInputChange,
	isRequired = false,
	inputHeight,
	userTypes,
	errorValue,
}) => {
	const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
		onInputChange(event.target.value);

	return (
		<InputWrap>
			<InputLabel errorHandle={errorValue}>{name}</InputLabel>
			<Select
				name={name}
				onChange={handleInputChange}
				inputWidth={inputWidth}
				inputHeight={inputHeight}
				required={isRequired}
				errorHandle={errorValue}
			>
				<option value="">{placeholder}</option>
				{userTypes.map((type, index) => (
					<option key={index} value={type}>
						{type}
					</option>
				))}
			</Select>
		</InputWrap>
	);
};

interface SelectProp {
	inputWidth?: string;
	inputHeight?: string;
	errorHandle?: boolean;
}

const Select = styled.select<SelectProp>`
	position: relative;
	border: 1px solid ${(props) => (props.errorHandle ? "red" : colors.grey)};
	border-radius: 0.375rem;
	box-sizing: border-box;
	width: ${(props) => props.inputWidth || "460px"};
	height: ${(props) => props.inputHeight || "45px"};
	left: 0;
	padding: 0;
	padding-left: 2.5%;
	background-color: ${colors.white};

	font-family: "Montserrat";
	text-align: left;
	color: ${colors.grey};

	::placeholder {
		font-style: normal;
		font-weight: 400;
		font-size: 15.0309px;
		line-height: 18px;

		width: auto;
		height: auto;
		color: ${colors.grey};
	}
`;

export const InputLabel = styled.p<SelectProp>`
	margin: 0;
	padding: 0;
	padding-bottom: 2.5%;
	width: auto;
	height: auto;
	left: 0;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12.0247px;
	line-height: 15px;

	color: ${(props) => (props.errorHandle ? "red" : colors.primaryGreen)};
`;

const InputWrap = styled.div`
	width: 75%;
	height: 66px;

	${InputLabel} {
		justify-content: flex-start;
		position: relative;
		left: 0;
	}
`;

export default InputSelector;
