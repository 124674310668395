import { useState } from "react";

export default function usePopup() {
	const [openPopup, setopenPopUp] = useState<boolean>(false);
	const [handdleForm, sethanddleForm] = useState<string>("");

	const AddNewReturn = (AddSelection: string) => {
		sethanddleForm(AddSelection);
		setopenPopUp(true);
	};

	return { openPopup, setopenPopUp, handdleForm, sethanddleForm, AddNewReturn };
}
