import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Grid,
} from "@mui/material/";
import { tableCellClasses } from "@mui/material/TableCell";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import colors from "../../ui/colors";
import IconButton from "../Buttons/IconButton/IconButton";
import Loader from "../Loader/Loader";
import AddSubscriptions from "../Forms/AddSubscriptions/AddSubscriptions";
import { Roles } from "../../enum/roles-Options";
import { UpdateDataCreator } from "../../helpers/DataCreator";

interface AdminPanelProps {
	PopController: (Trigger: boolean, form: string) => void;
	usersDataLoading: boolean;
	UserRole: string;
}
export const AdministradorPanel: React.FC<AdminPanelProps> = ({
	PopController,
	usersDataLoading,
	UserRole,
}) => {
	let UsersInfo;
	const { allUsers: users, refetchAllUsers } = UpdateDataCreator();
	if (UserRole === Roles.MRM) {
		UsersInfo = users
			?.filter(
				(user) =>
					user.role.includes(Roles.MRM) || user.role.includes(Roles.Client)
			)
			?.map((user) => user.username);
	} else {
		UsersInfo = users?.map((user) => user?.username);
	}
	useEffect(() => {
		refetchAllUsers();
	}, [refetchAllUsers]);

	const [open, setOpen] = useState<boolean>(false);
	const addButtonColor = colors.primaryGreen;
	const editButtonColor = colors.white;

	const handleModify = () => {
		setOpen(true);
	};

	const handleEdit = async () => {
		PopController(true, "Edit");
	};

	const handleAddUser = async () => {
		PopController(true, "Add");
	};
	return (
		<>
			{usersDataLoading ? (
				<LoaderWrap>
					<Loader color={colors.deepGreen} />
				</LoaderWrap>
			) : (
				<Grid container spacing={2}>
					<Grid
						item
						lg={12}
						sx={{
							marginTop: "2.5%",
							width: "100%",
							height: "90%",
							marginLeft: "5%",
							marginRight: "5%",
							fontFamily: "Montserrat",
							fontStyle: "normal",
							fontWeight: 800,
							fontSize: "0.875rem",
							lineHeight: "17px",
							letterSpacing: "0.2px",
							color: colors.primaryGreen,
						}}
					>
						USUARIOS DISPONIBLES:
						<TableWrap>
							<Paper elevation={1} style={{ backgroundColor: colors.white }}>
								<StyledTableContainer
									style={{
										marginTop: 5,
										maxHeight: 220,
										width: "100%",
										borderRadius: "8px",
										boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
									}}
								>
									<Table
										sx={{
											background: colors.white,
											height: 100,
										}}
										aria-label="ClientsTable"
									>
										<TableBody>
											{UsersInfo?.map((user, index) => (
												<TableRow
													key={index}
													style={{
														fontFamily: "Poppins",
														fontStyle: "normal",
														fontWeight: 500,
														fontSize: "0.75rem",
														lineHeight: "1rem",
														letterSpacing: "0.198851px",
														color: colors.deepGreen,
													}}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
												>
													<StyledTableCell align="center">
														<SubWrap key={index}>
															<Subscriptor key={index}>
																<AccountCircleOutlinedIcon
																	style={{
																		fontSize: "1.5rem",
																		color: `${colors.green}`,
																		paddingRight: "5%",
																	}}
																/>
																{user}
															</Subscriptor>
														</SubWrap>
													</StyledTableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</StyledTableContainer>
							</Paper>
						</TableWrap>
					</Grid>
					<Grid
						item
						lg={12}
						sx={{
							marginTop: "10.5rem",
							marginLeft: "2.5%",
							marginRight: "2.5%",
						}}
					>
						<Grid
							container
							spacing={1}
							justifyContent="space-around"
							alignItems="center"
						>
							<Grid item m={0} lg={4}>
								<IconButton
									backgroundcolorprop={`${colors.primaryGreen}`}
									buttonLabel={"Subscribir"}
									IconNum={5}
									textcolor={`${colors.white}`}
									OnClick={handleModify}
								/>
							</Grid>
							<Grid item m={0} lg={4}>
								<IconButton
									backgroundcolorprop={editButtonColor}
									IconNum={0}
									buttonLabel="Editar"
									textcolor={colors.primaryGreen}
									hoverbackgroundcolor={colors.primaryGreen}
									hovertextcolor={colors.white}
									hoverbordercolor={colors.primaryGreen}
									OnClick={handleEdit}
								/>
							</Grid>
							<Grid item lg={3} sx={{}}>
								<IconButton
									backgroundcolorprop={addButtonColor}
									IconNum={0}
									buttonLabel="Nuevo"
									textcolor={colors.white}
									hoverbackgroundcolor={colors.white}
									hovertextcolor={colors.primaryGreen}
									hoverbordercolor={colors.primaryGreen}
									OnClick={handleAddUser}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			<AddSubscriptions
				openForm={open}
				setOpenFrom={setOpen}
				UsersInfo={users}
			/>
		</>
	);
};

export default AdministradorPanel;

const LoaderWrap = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Subscriptor = styled.div`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1rem;
	text-align: center;
	letter-spacing: 0.163731px;
	color: ${colors.primaryGreen};

	display: flex;
	justify-content: center;
	align-items: center;
`;

const SubWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

const TableWrap = styled.div`
	width: 100%;
	height: auto;
	max-height: 50px;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.primaryGreen,
		color: colors.white,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198519px",
		textAlign: "center",
	},
	[`&.${tableCellClasses.body}`]: {
		alignItems: "center",
		textAlign: "center",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198851px",
		color: colors.deepGreen,
		borderBottom: `1px solid ${colors.primaryGreen}`,
	},
}));

const StyledTableContainer = styled(TableContainer)`
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		border-radius: 20px;
		background: ${colors.white};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.primaryGreen};
		border-radius: 0px 8px 8px 0px;
		border: 1px solid ${colors.primaryGreen};
	}
`;
