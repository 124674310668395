import styled from "styled-components";
import "./App.css";
import Router from "./routes/Router";
import colors from "../src/ui/colors";

const AppDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100vw;
	background-color: ${colors.primaryGreen};
`;

function App() {
	return (
		<AppDiv>
			<Router />
		</AppDiv>
	);
}

export default App;
