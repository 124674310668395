import * as styles from "../../../styles/stylesLanguagueSector";

const OPTIONS = [
	{ label: "Español", value: "es" },
	{ label: "English", value: "en" },
];

export const LanguageSelector = () => {
	return (
		<styles.LanguageSelector>
			{OPTIONS.map((option, index) => (
				<option key={(option.value, index)} value={option.label}>
					{option.label}
				</option>
			))}
		</styles.LanguageSelector>
	);
};

export default LanguageSelector;
/**{ label: "Français", value: "fr" },
	{ label: "Português", value: "pt" },
	{ label: "Italiano", value: "it" },
	{ label: "Deutsch", value: "de" }, */
