import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Contact } from "../models/Contact";
import { CreateContactRequest } from "../dto/create-contact-request.dto";
import { RootState } from "../app/store";

export const contactsApi = createApi({
	reducerPath: "contactsAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BACK_URL}/contacts`,
		prepareHeaders: (headers, { getState }) => {
			const state = getState() as RootState;
			const token = state.auth.token;
			if (token) {
				headers.set("authentication", `${token}`);
			}
			return headers;
		},
	}),
	endpoints: (build) => ({
		getAllContacts: build.query<Contact[], void>({
			query: () => "/",
		}),
		findContactByEmail: build.query<Contact, string>({
			query: (email) => `/${email}`,
		}),
		createContact: build.mutation<Contact, CreateContactRequest>({
			query: (createContactRequest) => ({
				url: "/",
				method: "POST",
				body: createContactRequest,
			}),
		}),
	}),
});

export const {
	useGetAllContactsQuery,
	useFindContactByEmailQuery,
	useCreateContactMutation,
} = contactsApi;
