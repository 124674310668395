import React, { useState } from "react";
import { useUpdateSelfMutation } from "../../apis/users.api";
import { colors } from "../../ui/colors";
import IconButton from "../Buttons/IconButton/IconButton";
import styled from "styled-components";
import { User } from "../../models/User";
import { CreateUserRequest } from "../../dto/create-user-request.dto";
import { Grid, OutlinedInput } from "@mui/material";

interface UserInformation {
	userInfo: User;
}

const textFieldStyles = {
	margin: 0,
	marginLeft: "2.5%",
	width: "15rem",
	height: "1.5rem",
	fontFamily: "Montserrat",
	fontStyle: "normal",
	fontWeight: 500,
	fontSize: "0.825rem",
	lineHeight: "1rem",
	textAlign: "center",
	letterSpacing: "0.2px",
	color: colors.primaryGreen,
	backgroundColor: colors.white,
	border: `1px solid ${colors.primaryGreen}`,
	borderRadius: "4px",
};

export const UserInformationTable: React.FC<UserInformation> = ({
	userInfo,
}) => {
	const [updateUser] = useUpdateSelfMutation();
	const [isEditing, setIsEditing] = useState(false);
	const [user, setUser] = useState(userInfo.username);
	const [email, setEmail] = useState(userInfo.email);
	const [password, setPassword] = useState("");
	const [position, setPosition] = useState(userInfo.position || "");

	const [role, setRole] = useState<string>(userInfo.role[0]);
	const [company, setCompany] = useState(userInfo.company);

	const editButtonArea = "8 / 2 / 8 / 3";
	const editButtonColor = colors.primaryGreen;
	const editButtonHover = colors.white;
	const editButtonHoverText = colors.primaryGreen;

	const handleEdit = async () => {
		let transformedRole = role.split(",");
		const dataBody: Partial<CreateUserRequest> = {
			username: user,
			email,
			position,
			role: transformedRole,
			company,
			...(password !== "" ? { password } : {}),
		};
		if (isEditing) {
			try {
				await updateUser({
					id: userInfo._id,
					data: dataBody,
				});
			} catch (error) {}
		}
		setIsEditing(!isEditing);
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		setter: React.Dispatch<React.SetStateAction<string>>
	) => {
		setter(e.target.value);
	};

	return (
		<InformationWrap>
			<Information gridArea="1 / 1 / 2 / 3">
				<Grid container spacing={2}>
					<Grid item lg={3.5} xl={4}>
						NOMBRE USUARIO:
					</Grid>
					<Grid item lg={4} xl={4}>
						{isEditing ? (
							<OutlinedInput
								value={user}
								onChange={(e) => handleInputChange(e, setUser)}
								sx={textFieldStyles}
							/>
						) : (
							<Complement>{user}</Complement>
						)}
					</Grid>
				</Grid>
			</Information>
			<Information gridArea="2 / 1 / 3 / 3">
				<Grid container spacing={2}>
					<Grid item lg={3.5} xl={4}>
						EMAIL:
					</Grid>
					<Grid item lg={4} xl={4}>
						{isEditing ? (
							<OutlinedInput
								value={email}
								onChange={(e) => handleInputChange(e, setEmail)}
								sx={textFieldStyles}
							/>
						) : (
							<Complement>{email}</Complement>
						)}
					</Grid>
				</Grid>
			</Information>
			<Information gridArea="3 / 1 / 4 / 3">
				<Grid container spacing={2}>
					<Grid item lg={3.5} xl={4}>
						CONTRASEÑA:
					</Grid>
					<Grid item lg={4} xl={4}>
						{isEditing ? (
							<OutlinedInput
								type="text"
								value={password}
								onChange={(e) => handleInputChange(e, setPassword)}
								sx={textFieldStyles}
							/>
						) : (
							<Complement>{"••••••••"}</Complement>
						)}
					</Grid>
				</Grid>
			</Information>
			<Information gridArea="4 / 1 / 5 / 3">
				<Grid container spacing={2}>
					<Grid item lg={3.5} xl={4}>
						CARGO:
					</Grid>
					<Grid item lg={4} xl={4}>
						{isEditing ? (
							<OutlinedInput
								value={position}
								onChange={(e) => handleInputChange(e, setPosition)}
								sx={textFieldStyles}
							/>
						) : (
							<Complement>{position.toUpperCase()}</Complement>
						)}
					</Grid>
				</Grid>
			</Information>
			<Information gridArea="5 / 1 / 6 / 3">
				<Grid container spacing={2}>
					<Grid item lg={3.5} xl={4}>
						EMPRESA:
					</Grid>
					<Grid item lg={4} xl={4}>
						{isEditing ? (
							<OutlinedInput
								value={company}
								onChange={(e) => handleInputChange(e, setCompany)}
								sx={textFieldStyles}
							/>
						) : (
							<Complement>{company.toUpperCase()}</Complement>
						)}
					</Grid>
				</Grid>
			</Information>
			<Information gridArea="6 / 1 / 7 / 3">
				<Grid container spacing={2}>
					<Grid item lg={3.5} xl={4}>
						ROL:
					</Grid>
					<Grid item lg={4} xl={4}>
						{isEditing && role === "Administrador" ? (
							<OutlinedInput
								value={role}
								onChange={(e) => handleInputChange(e, setRole)}
								sx={textFieldStyles}
							/>
						) : (
							<Complement>{role}</Complement>
						)}
					</Grid>
				</Grid>
			</Information>
			<ButtonWrap gridArea={editButtonArea}>
				{isEditing ? (
					<ButtonsWrap>
						<IconButton
							backgroundcolorprop={colors.red}
							IconNum={4}
							buttonLabel="Cancelar"
							textcolor={colors.white}
							hoverbackgroundcolor={colors.white}
							hovertextcolor={colors.red}
							hoverbordercolor={colors.red}
							OnClick={() => setIsEditing(!isEditing)}
						/>

						<IconButton
							backgroundcolorprop={editButtonColor}
							IconNum={4}
							buttonLabel="Editar"
							textcolor={colors.white}
							hoverbackgroundcolor={editButtonHover}
							hovertextcolor={editButtonHoverText}
							hoverbordercolor={colors.primaryGreen}
							OnClick={handleEdit}
						/>
					</ButtonsWrap>
				) : (
					<IconButton
						backgroundcolorprop={editButtonColor}
						IconNum={4}
						buttonLabel="Editar"
						textcolor={colors.white}
						hoverbackgroundcolor={editButtonHover}
						hovertextcolor={editButtonHoverText}
						hoverbordercolor={colors.primaryGreen}
						OnClick={handleEdit}
					/>
				)}
			</ButtonWrap>
		</InformationWrap>
	);
};

const InformationWrap = styled.div`
	display: grid;
	height: 100%;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(7, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0.5rem;

	justify-items: left;
	align-items: center;
`;

export const Information = styled.div<{ gridArea: string }>`
	width: 100%;
	height: 6%;
	grid-area: ${({ gridArea }) => gridArea};
	display: flex;
	padding-left: 4%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	font-size: 0.875rem;
	line-height: 17px;
	letter-spacing: 0.2px;
	color: ${colors.primaryGreen};
`;

const Complement = styled.h2`
	margin: 0;
	margin-left: 2.5%;
	height: 6%;
	display: inline-block;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.2px;

	color: ${colors.primaryGreen};
`;
export const InputEdit = styled.input`
	margin: 0;
	margin-left: 2.5%;
	width: 100%;
	height: 1.5rem;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 0.825rem;
	line-height: 1rem;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.2px;
	color: ${colors.primaryGreen};
	background-color: ${colors.white};
	border: 1px solid ${colors.primaryGreen};
	border-radius: 4px;
`;
const ButtonWrap = styled.div<{ gridArea: string }>`
	grid-area: ${({ gridArea }) => gridArea};
	padding-left: 2rem;
	padding-bottom: 1.15rem;
`;

const ButtonsWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 0.5rem;
	grid-row-gap: 0px;
	margin-bottom: 1rem;
`;

export default UserInformationTable;
