import { colors } from "../../ui/colors";
import React, { useState } from "react";
import {
	MainForm,
	ButtonsWrap,
	ButtonPop,
	Title,
} from "../Forms/EditUsersForm/EditUsersForm";
import { InputEdit } from "../UserInformation/UserInformation";
import { User } from "../../models/User";
import styled from "styled-components";
import { useUpdateUserMutation } from "../../apis/users.api";
import { CreateUserRequest } from "../../dto/create-user-request.dto";

interface IAdministradorEditorProps {
	setSettingsStatus: React.Dispatch<React.SetStateAction<boolean>>;
	setSettingsIcon: React.Dispatch<React.SetStateAction<number | null>>;
	UserSelected: User;
}
export const AdministradorEditor: React.FC<IAdministradorEditorProps> = ({
	setSettingsIcon,
	setSettingsStatus,
	UserSelected,
}) => {
	const [updateUser] = useUpdateUserMutation();
	const [user, setUser] = useState(UserSelected.username);
	const [email, setEmail] = useState(UserSelected.email);
	const [password, setPassword] = useState("");
	const [position, setPosition] = useState(UserSelected.position || "");
	const [role, setRole] = useState<string>(UserSelected.role[0]);
	const [company, setCompany] = useState(UserSelected.company);
	const [isFocused, setIsFocused] = useState<boolean>(false);

	const handleSaveChanges = async () => {
		let transformedRole = role.split(",");
		const dataBody: Partial<CreateUserRequest> = {
			username: user,
			email,
			position,
			role: transformedRole,
			company,
			...(password !== "" ? { password } : {}),
		};
		try {
			await updateUser({
				id: UserSelected._id,
				data: dataBody,
			});
		} catch (error) {}
		setSettingsStatus((prevState) => !prevState);
		setSettingsIcon(null);
	};
	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		setter: {
			(value: React.SetStateAction<string>): void;
			(value: React.SetStateAction<string>): void;
			(arg0: any): void;
		}
	) => {
		setter(e.target.value);
	};
	return (
		<>
			<MainForm>
				<Title>Editar Usuario</Title>
				<Information>
					USUARIO:
					<InputEdit
						value={user}
						onChange={(e) => handleInputChange(e, setUser)}
					></InputEdit>
				</Information>

				<Information>
					EMAIL:
					<InputEdit
						value={email}
						onChange={(e) => handleInputChange(e, setEmail)}
					></InputEdit>
				</Information>
				<Information>
					CONTRASEÑA:
					<InputEdit
						value={isFocused ? password : "**********"}
						onChange={(e) => handleInputChange(e, setPassword)}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
					/>
				</Information>
				<Information>
					CARGO:
					<InputEdit
						value={position.toUpperCase()}
						onChange={(e) => handleInputChange(e, setPosition)}
					></InputEdit>
				</Information>
				<Information>
					ROL:
					<InputEdit
						value={role}
						onChange={(e) => handleInputChange(e, setRole)}
					></InputEdit>
				</Information>
				<Information>
					COMPAÑÍA:
					<InputEdit
						value={company.toUpperCase()}
						onChange={(e) => handleInputChange(e, setCompany)}
					></InputEdit>
				</Information>
			</MainForm>
			<ButtonsWrap>
				{" "}
				<ButtonPop
					onClick={handleSaveChanges}
					type="submit"
					backgroundColor={`${colors.primaryGreen}`}
					textColor={`${colors.white}`}
				>
					{`GUARDAR CAMBIOS`}
				</ButtonPop>
				<ButtonPop
					onClick={() => {
						setSettingsStatus((prevState) => !prevState);
						setSettingsIcon(null);
					}}
					backgroundColor={`${colors.white}`}
					textColor={`${colors.primaryGreen}`}
				>
					{`CANCELAR`}
				</ButtonPop>
			</ButtonsWrap>
		</>
	);
};

const Information = styled.div`
	width: 80%;
	height: 26%;

	display: flex;
	padding-left: 10%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	font-size: 0.875rem;
	line-height: 17px;
	letter-spacing: 0.2px;
	color: ${colors.primaryGreen};
`;
