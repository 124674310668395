import React, { useState } from "react";
import styled from "styled-components";
import { News } from "../../../models/News";
import colors from "../../../ui/colors";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
	Paper,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	Table,
	TableBody,
	TablePagination,
	tableCellClasses,
	Button,
} from "@mui/material";
import {
	useDeleteNewsMutation,
	useGetAllNewsQuery,
} from "../../../apis/news.api";
import { Toaster, toast } from "sonner";

interface TableProps {
	Data: News[] | undefined;
	Titles: string[];
	UserRole: string;
}
export const NewsTable: React.FC<TableProps> = ({ Data, UserRole }) => {
	const { data: news, refetch: refetcheNews } = useGetAllNewsQuery();
	const [deleteNews] = useDeleteNewsMutation();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const isAdmin = UserRole === "Administrador" || UserRole === "MRM";
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const [deleteIcon, setDeleteIcon] = useState<number | null>(null);

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleDelete = async (index: number) => {
		try {
			if (news && news?.[index] !== undefined) {
				const response = await deleteNews(news[index]._id);
				if ("error" in response) {
					if ("data" in response.error) {
						const data: any = response.error.data;
						if ("message" in data) {
							const errorMessage = JSON.stringify(data.message).replace(
								/[[\]"]/g,
								" "
							);
							toast.error(errorMessage.toUpperCase());
						} else {
							toast.error(`An error occurred: ${response.error}`);
						}
					}
					return;
				}
				toast.success("Novedad borrada con éxito");
				refetcheNews();
			}
			setDeleteIcon((prevIndex) => (prevIndex === index ? null : index));
		} catch (error) {
			console.error("Error handling delete:", error);
		}
	};

	if (!Data || Data === null) {
		return (
			<TableContainer
				component={Paper}
				elevation={0}
				style={{ maxHeight: "100%", border: "none" }}
			>
				<Table sx={{ minWidth: 200 }} aria-label="ClientsTable">
					<TableHead>
						<TableRow>
							<TableCell>No data available</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</TableContainer>
		);
	}
	return (
		<>
			<Toaster richColors />
			<TableWrap>
				<Paper elevation={0} style={{ backgroundColor: colors.white }}>
					<StyledTableContainer
						style={{
							maxHeight: "800px",
							borderRadius: "10px",
							border: `1px solid ${colors.primaryGreen}`,
						}}
					>
						<Table
							sx={{
								minWidth: "200px",
								background: colors.white,
								height: 100,
								borderRadius: "4px",
							}}
							aria-label="ClientsTable"
						>
							<TableHead>
								<TableRow>
									<StyledTableCell>{"compañía".toUpperCase()}</StyledTableCell>
									<StyledTableCell>{"carpeta".toUpperCase()}</StyledTableCell>
									<StyledTableCell>{"fecha".toUpperCase()}</StyledTableCell>
									<StyledTableCell>{"escritor".toUpperCase()}</StyledTableCell>
									<StyledTableCell>
										{"comentario".toUpperCase()}
									</StyledTableCell>
									{isAdmin && (
										<StyledTableCell>{"delete".toUpperCase()}</StyledTableCell>
									)}
								</TableRow>
							</TableHead>
							<TableBody>
								{news
									?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((data, index) => (
										<TableRow
											key={index + data.ClientName}
											style={{
												fontFamily: "Poppins",
												fontStyle: "normal",
												fontWeight: 500,
												fontSize: "0.75rem",
												lineHeight: "1rem",
												letterSpacing: "0.198851px",
												color: colors.deepGreen,
											}}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<StyledTableCell align="center" scope="row">
												{`${data.ClientName}`}
											</StyledTableCell>
											<StyledTableCell align="center" scope="row">
												{`${data.Folder}`}
											</StyledTableCell>
											<StyledTableCell align="center" scope="row">
												{`${data.NewsDate}`}
											</StyledTableCell>
											<StyledTableCell align="center" scope="row">
												{`${data.Writer}`}
											</StyledTableCell>
											<StyledTableCell align="left" scope="row">
												{`${data.Content}`}
											</StyledTableCell>
											{isAdmin && (
												<StyledTableCell align="center" scope="row">
													<Button
														onClick={() => {
															handleDelete(index);
														}}
													>
														{deleteIcon === index ? (
															<IndeterminateCheckBoxIcon
																sx={{ color: colors.primaryGreen }}
															/>
														) : (
															<IndeterminateCheckBoxOutlinedIcon
																sx={{ color: colors.primaryGreen }}
															/>
														)}
													</Button>
												</StyledTableCell>
											)}
										</TableRow>
									))}
							</TableBody>
						</Table>
					</StyledTableContainer>
					<TablePagination
						component="div"
						count={Data.length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						sx={{
							color: colors.primaryGreen,
							backgroundColor: colors.white,
							"& .MuiSelect-select.MuiSelect-select": {
								backgroundColor: colors.grey,
							},
						}}
					/>
				</Paper>
			</TableWrap>
		</>
	);
};

export default NewsTable;

export const TableWrap = styled.div`
	margin-top: 2%;
	width: 96%;
	height: 100%;
	padding-left: 2.5%;
	border-radius: 5px;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.white,
		color: colors.primaryGreen,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198519px",
		textAlign: "center",
		borderBottom: `1px solid ${colors.primaryGreen}`,
	},
	[`&.${tableCellClasses.body}`]: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198851px",
		color: colors.deepGreen,
		borderBottom: `1px solid ${colors.primaryGreen}`,
	},
}));

const StyledTableContainer = styled(TableContainer)`
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		background: ${colors.white};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.primaryGreen};
		border-radius: 20px;
		border: 3px solid ${colors.white};
	}
`;
