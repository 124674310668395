import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../ui/colors";
import {
	Paper,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	tableCellClasses,
	TableBody,
	Table,
	Skeleton,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getDbUpdates } from "../../../apis/data.api";

type UpdateInfo = {
	_id: string;
	date: string[];
	FilesUpdated: string[];
};

export const UpdatesTable: React.FC = () => {
	const token = useSelector((state: RootState) => state.auth.token);
	const [isLoading, setIsLoading] = useState(false);
	const [dbUpdateData, setDbUpdateData] = useState<UpdateInfo>();
	const fetchDbUpdates = async () => {
		try {
			setIsLoading(true);
			const updateData = await getDbUpdates(token as string);
			setDbUpdateData(updateData);
		} catch (error) {
			console.error("Error fetching db updates:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchDbUpdates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let updateStatus = dbUpdateData ? true : undefined;
	const date = dbUpdateData?.date[0]
		? new Date(dbUpdateData.date[0])
		: new Date();

	const dateUpdated = `${date.getDate().toString().padStart(2, "0")}-${(
		date.getMonth() + 1
	)
		.toString()
		.padStart(2, "0")}-${date.getFullYear()}`;

	let filesUpdates: string[] = [];

	if (
		dbUpdateData &&
		dbUpdateData.FilesUpdated &&
		dbUpdateData.FilesUpdated.length > 0
	) {
		filesUpdates = dbUpdateData.FilesUpdated;
		filesUpdates = filesUpdates.includes("Empty")
			? ["Sin archivos por actualizar"]
			: filesUpdates;
	} else {
		filesUpdates = ["Sin archivos por actualizar"];
	}

	return isLoading ? (
		<TableWrap>
			<TitleWrap>
				<Title>Actualización Base de Data</Title>
			</TitleWrap>
			<Paper elevation={0} style={{ backgroundColor: colors.white }}>
				<StyledTableContainer
					style={{
						maxHeight: "400px",
						borderRadius: "8px",
						boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
					}}
				>
					<Table
						sx={{
							minWidth: "900px",
							background: colors.white,
							height: 100,
							borderRadius: "4px",
						}}
						aria-label="ClientsTable"
					>
						<TableHead>
							<TableRow>
								<StyledTableCell>
									<Skeleton variant="text" width="70%" />
								</StyledTableCell>
								<StyledTableCell>
									<Skeleton variant="text" width="90%" />
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array(3)
								.fill(0)
								.map((_, index) => (
									<TableRow key={index}>
										<StyledTableCell>
											<Skeleton variant="text" width="80%" />
										</StyledTableCell>
										<StyledTableCell>
											<Skeleton variant="text" width="90%" />
										</StyledTableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</StyledTableContainer>
			</Paper>
		</TableWrap>
	) : (
		<TableWrap>
			<TitleWrap>
				<Title>Actualización Base de Data</Title>
			</TitleWrap>
			<Paper elevation={0} style={{ backgroundColor: colors.white }}>
				<StyledTableContainer
					style={{
						maxHeight: "400px",
						borderRadius: "8px",
						boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
					}}
				>
					<Table
						sx={{
							minWidth: "900px",
							background: colors.white,
							height: 100,
							borderRadius: "4px",
						}}
						aria-label="ClientsTable"
					>
						<TableHead>
							<TableRow>
								<StyledTableCell>Fecha</StyledTableCell>
								<StyledTableCell>
									Archivos extraidos en actualización
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{updateStatus ? (
								filesUpdates.map((filename: string, index: number) => (
									<TableRow
										key={index}
										style={{
											fontFamily: "Poppins",
											fontStyle: "normal",
											fontWeight: 500,
											fontSize: "0.75rem",
											lineHeight: "1rem",
											letterSpacing: "0.198851px",
											color: colors.deepGreen,
										}}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<StyledTableCell align="center" scope="row">
											{dateUpdated}
										</StyledTableCell>
										<StyledTableCell align="center" scope="row">
											{filename}
										</StyledTableCell>
									</TableRow>
								))
							) : (
								<div>No updates available</div>
							)}
						</TableBody>
					</Table>
				</StyledTableContainer>
			</Paper>
		</TableWrap>
	);
};

export default UpdatesTable;
const Title = styled.h1`
	width: 100%;
	height: 100%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.25rem;

	display: flex;
	align-items: center;

	color: ${colors.green};
`;

const TitleWrap = styled.div`
	display: flex;
	width: 100%;
	height: 10%;
	align-items: center;
	justify-content: space-around;

	margin-bottom: 2.5%;
`;
const TableWrap = styled.div`
	width: 99%;
	height: auto;
	max-height: 50px;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.primaryGreen,
		color: colors.white,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198519px",
		textAlign: "center",
	},
	[`&.${tableCellClasses.body}`]: {
		alignItems: "center",
		textAlign: "center",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198851px",
		color: colors.deepGreen,
		borderBottom: `1px solid ${colors.primaryGreen}`,
	},
}));

const StyledTableContainer = styled(TableContainer)`
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		border-radius: 20px;
		background: ${colors.white};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.primaryGreen};
		border-radius: 0px 8px 8px 0px;
		border: 1px solid ${colors.primaryGreen};
	}
`;
