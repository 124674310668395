import React from "react";
import MRMLogo from "../../../assets/images/LogoMRM-HD.png";
import colors from "../../colors";
import styled from "styled-components";
import PhoneIcon from "@mui/icons-material/Phone";
import { Button } from "../../../template/Buttons/ButtonHome";
import { Grid } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

export const StyledWrapperFooterDiv = styled.div`
  height: 15rem;
  max-width: 100vw;
  background-color: ${colors.deepGreen};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  @media (max-width: 900px) {
    height: fit-content;
    padding: 4rem;
  }
`;

export const StyledNameImg = styled.img`
  padding: 0 2rem;
  width: 14rem;
  height: 2rem;
`;

export const StyledLogoDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLogoImg = styled.img`
  transform: scale(0.5);
  @media (max-width: 900px) {
    transform: scale(0.4);
  }
`;

export const StyledOutlinedButton = styled(Button)`
  border: 1.5px solid ${colors.green} !important;
  padding: 0.5rem 1rem !important;
  margin: 0 1rem 0 0 !important;
  color: ${colors.deepGreen} !important;
`;

export const StyledFilledButton = styled(Button)`
  border: 1.5px solid ${colors.green} !important;
  padding: 0.5 1rem !important;
  background-color: ${colors.deepGreen} !important;
  color: ${colors.white} !important;
`;

const StyledFooterDiv = styled.div`
  color: ${colors.white};

  height: 4rem;
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    justify-content: center;
    display: flex;
  }
`;

export const StyledWrapperNavDiv = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 2rem;
  align-items: center;
  justify-content: space-between;
`;
const StyledFooterP = styled.p`
  padding-left: 1rem;
`;

const StyledAddressP = styled.p`
  max-width: 12rem;
  padding-left: 1rem;
`;

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 900px) {
    row-gap: 2rem;
  }
`;

export const Footer: React.FC = () => {
  return (
    <footer>
      <StyledWrapperFooterDiv>
        <StyledGrid container rowSpacing={2} spacing={1}>
          <Grid item xs={12} md={3} lg={3}>
            <StyledFooterDiv>
              <StyledLogoImg src={MRMLogo} />
            </StyledFooterDiv>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <StyledFooterDiv>
              <FmdGoodIcon />
              <StyledAddressP>
                Dir. Misiones 1537 Piso 5 Montevideo - Uruguay
              </StyledAddressP>
            </StyledFooterDiv>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <StyledFooterDiv>
              <PhoneIcon />
              <StyledFooterP>Tel. (+598) 2 916 3171</StyledFooterP>
            </StyledFooterDiv>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <StyledFooterDiv>
              <MailOutlineIcon />
              <StyledFooterP> mrm@mrm.com.uy</StyledFooterP>
            </StyledFooterDiv>
          </Grid>
        </StyledGrid>
      </StyledWrapperFooterDiv>
    </footer>
  );
};

export default Footer;
