import React, { useEffect, useState } from "react";
import InputField from "../../Input/InputField";
import styled from "styled-components";
import colors from "../../../ui/colors";
import { FormikErrors } from "formik";
import { REQUIRED } from "../../../schemas/YupValidations";

type FormContact = {
	title: string;
	content: string;
};
interface ContactProps {
	onChange: (values: Record<string, string>) => void;
	errors: FormikErrors<FormContact>;
}
const InputContainer = styled.div`
	display: grid;
	grid-template-columns: 1 1fr;
	grid-template-rows: 2 auto;
	grid-row-gap: 20px;
`;
export const NewsForm: React.FC<ContactProps> = ({ onChange, errors }) => {
	const [values, setValues] = useState<Record<string, string>>({});
	const [errorStatus, setErrorStatus] = useState({
		title: false,
		content: false,
	});

	useEffect(() => {
		setErrorStatus({
			title: errors.title === REQUIRED,
			content: errors.content === REQUIRED,
		});
	}, [errors]);

	const handleInputChange = (name: string, value: string) => {
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
		onChange(values);
	};

	return (
		<>
			<Title>Novedad</Title>
			<InputContainer>
				<InputField
					name={"Compañía"}
					type={"text"}
					placeholder={"Nombre la compañía con novedad"}
					onInputChange={(value) => handleInputChange("title", value)}
					isRequired={true}
					errorValue={errorStatus.title}
				/>
				<InputField
					name={"Contenido"}
					type={"text"}
					placeholder={"Insertar contenido de la novedad"}
					inputHeight="225px"
					onInputChange={(value) => handleInputChange("content", value)}
					isRequired={true}
					errorValue={errorStatus.content}
				/>
			</InputContainer>
		</>
	);
};

export default NewsForm;

const Title = styled.h1`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 20.5423px;
	line-height: 25px;

	color: ${colors.deepGreen};
`;
