import React, { useEffect, useState } from "react";

import styled from "styled-components";
import colors from "../../../ui/colors";
import { User } from "../../../models/User";
import UsersSettings from "../UsersSettings/UserSettings";
import { AdministradorEditor } from "../../AdministradorEditor/AdministradorEditor";
import { AdministradorDelete } from "../../AdministratorDelete/AdministradorDelete";
import { UpdateDataCreator } from "../../../helpers/DataCreator";

interface EditUser {
	setTrigger: (arg0: boolean) => void;
	userCreating: string;
	isLoading: boolean;
	UserRole: string;
}
export const EditUsersForm: React.FC<EditUser> = ({ setTrigger, UserRole }) => {
	const [SettingsIcon, setSettingsIcon] = useState<number | null>(null);
	const [deleteIcon, setDeleteIcon] = useState<number | null>(null);
	const [settingsStatus, setSettingsStatus] = useState<boolean>(true);
	const [deleteStatus, setDeleteStatus] = useState<boolean>(false);
	const [userSelected, setUserSelected] = useState<User>();
	const { allUsers: users, refetchAllUsers } = UpdateDataCreator();
	useEffect(() => {
		refetchAllUsers();
	}, [refetchAllUsers]);
	let UsersInfo: User[] | undefined;
	let UsersNames;
	if (UserRole === "MRM") {
		UsersInfo = users?.filter(
			(user) => user.role.includes("MRM") || user.role.includes("Cliente")
		);
		UsersNames = users
			?.filter(
				(user) => user.role.includes("MRM") || user.role.includes("Cliente")
			)
			?.map((user) => user.username);
	} else {
		UsersInfo = users;
		UsersNames = users?.map((user) => user?.username);
	}

	const handleSettings = async (index: number) => {
		try {
			if (UsersInfo && UsersInfo?.[index] !== undefined) {
				setUserSelected(UsersInfo[index]);
			}
			setSettingsIcon((prevIndex) => (prevIndex === index ? null : index));
			setSettingsStatus((prevState) => !prevState);
			setDeleteIcon(null);
		} catch (error) {
			console.error("Error handling settings:", error);
		}
	};

	const handleDelete = async (index: number) => {
		try {
			if (users && users?.[index] !== undefined) {
				setUserSelected(users[index]);
			}
			setSettingsIcon(null);
			setDeleteIcon((prevIndex) => (prevIndex === index ? null : index));
			setDeleteStatus(true);
		} catch (error) {
			console.error("Error handling delete:", error);
		}
	};

	return (
		<>
			<PopupDiv>
				<FromWrap>
					{settingsStatus ? (
						!deleteStatus ? (
							<UsersSettings
								UsersInfo={UsersNames}
								colors={colors}
								handleSettings={handleSettings}
								handleDelete={handleDelete}
								SettingsIcon={SettingsIcon}
								deleteIcon={deleteIcon}
								setTrigger={setTrigger}
							/>
						) : (
							<AdministradorDelete
								setDeleteStatus={setDeleteStatus}
								setDeleteIcon={setDeleteIcon}
								UserSelected={userSelected as User}
							/>
						)
					) : (
						<AdministradorEditor
							setSettingsStatus={setSettingsStatus}
							setSettingsIcon={setSettingsIcon}
							UserSelected={userSelected as User}
						/>
					)}
				</FromWrap>
			</PopupDiv>
		</>
	);
};

export default EditUsersForm;

export const ButtonIcons = styled.button`
	background-color: ${colors.white};
	border: none;
`;
export const PopupDiv = styled.div`
	position: absolute;
	max-width: 520px;
	max-height: 720px;
	width: 520px;
	height: 558px;
	border-radius: 10px;
	background: ${colors.white};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid ${colors.primaryGreen};
	z-index: 1;
`;

export const Title = styled.h1`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 20.5423px;
	line-height: 25px;

	color: ${colors.deepGreen};
`;
export const MainForm = styled.div`
	margin-top: 5%;
	grid-area: 1 / 1 / 4 / 2;
`;

export const FromWrap = styled.div`
	width: 88%;
	height: 100%;
	padding-left: 30px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(4, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
`;

export const ButtonsWrap = styled.div`
	margin-top: 35%;
	display: grid;
	padding-left: 15%;
	grid-template-columns: 1fr;
	grid-auto-rows: 65px;
	margin-bottom: 5%;
`;
export const ButtonPop = styled.button<{
	backgroundColor: string;
	textColor: string;
	boderColor?: string;
}>`
	width: 341.2px;
	height: 46.64px;
	background: ${({ backgroundColor }) => backgroundColor};
	color: ${({ textColor }) => textColor};
	border: 2px solid ${colors.primaryGreen};
	border-radius: 6px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 0.825rem;
	line-height: 18px;

	:hover {
		color: ${({ textColor }) =>
			textColor === colors.white ? colors.primaryGreen : colors.white};
		background: ${({ backgroundColor }) =>
			backgroundColor === colors.primaryGreen
				? colors.white
				: colors.primaryGreen};
		border: 2px solid
			${({ backgroundColor }) =>
				backgroundColor === colors.primaryGreen
					? colors.primaryGreen
					: colors.white};
	}
`;
export const Subscriptor = styled.div<{ gridArea: string }>`
	grid-area: ${({ gridArea }) => gridArea};
	display: flex;
	justify-content: left;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 3% 0;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1rem;
	text-align: center;
	letter-spacing: 0.163731px;
	color: ${colors.primaryGreen};
`;

export const SubWrap = styled.div`
	display: flex;
	padding-left: 1.5%;
	flex-direction: row;
	align-items: center;
`;

export const UsersList = styled.div`
	grid-row: 2;
	grid-column: 1 / span 5;
	overflow-y: auto;
	min-height: 115px;
	max-height: 200px;

	/* Hide the default scrollbar */
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Customize the scrollbar track color */
	&::-webkit-scrollbar-track {
		background-color: ${colors.white};
	}

	/* Customize the scrollbar thumb color */
	&::-webkit-scrollbar-thumb {
		background-color: ${colors.green};
	}

	/* Hide scrollbar buttons (up/down arrows) */
	&::-webkit-scrollbar-button {
		display: none;
	}
`;
