import React from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import AddNewSelector from "../AddNewSelector/AddNewSelector";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import colors from "../../colors";

interface AddNewElementProps {
  onChange: (selectedOption: string) => void;
}

export const AddNewElement: React.FC<AddNewElementProps> = ({ onChange }) => {
  const handleAddSelector = (AddSelection: string) => onChange(AddSelection);
  return (
    <AddNewElementDiv container direction="row" columns={2}>
      <Grid item>
        <AddBoxOutlinedIcon
          sx={{ color: colors.white, fontSize: "1.5rem", marginTop: "0.2rem" }}
        />
      </Grid>
      <Grid item>
        <AddNewSelectorWrapper>
          <AddNewSelector onChange={handleAddSelector} />
        </AddNewSelectorWrapper>
      </Grid>
    </AddNewElementDiv>
  );
};

export default AddNewElement;

const AddNewElementDiv = styled(Grid)`
  max-width: 8rem;
  padding: 0.2rem 0.5rem;
  margin-right: 2rem;
  background-color: ${colors.primaryGreen};
  border: 1px solid ${colors.primaryGreen};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddNewSelectorWrapper = styled.div`
  grid-column: span 2;
  width: 100%;
`;
