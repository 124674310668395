import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../ui/colors";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Toaster, toast } from "sonner";
import { DatosDocumento } from "../../../dto/data-clients.request.dto";
import { DownloadButton } from "../InvoiceComertial/InvoiceComertial";
import { useGetInvoiceTiffQuery } from "../../../apis/data.api";
import { FolderResumeProps } from "../FolderResume/FolderResume";
import { Box } from "@mui/material";

export const NoComertial: React.FC<FolderResumeProps> = ({ FolderData }) => {
	const InvoiceNoComertialData = FolderData.Carpetas
		.DocumentosNoComerciales as DatosDocumento[];

	const [nroInternoRade, setNroInternoRade] = useState<string>("");
	const [loadingNroInternoRade, setLoadingNroInternoRade] = useState<
		string | null
	>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [shouldFetch, setShouldFetch] = useState<boolean>(false);
	const {
		data,
		isError,
		error: queryError,
	} = useGetInvoiceTiffQuery(
		{ nrointerno: nroInternoRade },
		{ skip: !shouldFetch }
	);
	useEffect(() => {
		if (isError && queryError && "status" in queryError) {
			const errorResponse = queryError as {
				status: number;
				data: { message: string };
			};
			if (
				errorResponse.status === 400 &&
				errorResponse.data.message === "File does not exist on the server"
			) {
				toast.error(`File is not available, contact your MRM Admin`);
				setIsLoading(false);
				setLoadingNroInternoRade(null);
			}
		} else if (data) {
			window.open(data.fileUrl, "_blank");
			setLoadingNroInternoRade(null);
			setIsLoading(false);
		}
	}, [data, isError, queryError]);
	const EmptyMessage = "Sin datos asignados";

	useEffect(() => {
		if (nroInternoRade) {
			setShouldFetch(true);
			setLoadingNroInternoRade(nroInternoRade);
			setIsLoading(true);
		}
	}, [nroInternoRade]);
	const handleDownload = async (InternoRade: string) => {
		setNroInternoRade(InternoRade);
	};

	useEffect(() => {
		if (data) {
			window.open(data.fileUrl, "_blank");
			setLoadingNroInternoRade(null);
			setIsLoading(false);
		}
	}, [data]);

	return (
		<Box sx={{ width: "100%" }}>
			<Toaster richColors />
			<TitleWrap>
				<Title>Documentos del D.U.A</Title>
			</TitleWrap>
			<Paper elevation={0} style={{ backgroundColor: colors.white }}>
				<StyledTableContainer
					style={{
						maxHeight: "175px",
						borderRadius: "8px",
						boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
					}}
				>
					<Table
						sx={{
							minWidth: "25rem",
							background: colors.white,
							height: 100,
							borderRadius: "4px",
						}}
						aria-label="ClientsTable"
					>
						<TableHead>
							<TableRow>
								<StyledTableCell>Número</StyledTableCell>
								<StyledTableCell>Descargar Factura</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(InvoiceNoComertialData) ? (
								InvoiceNoComertialData.map((item, index) => (
									<TableRow
										key={index}
										style={{
											fontFamily: "Poppins",
											fontStyle: "normal",
											fontWeight: 500,
											fontSize: "0.75rem",
											lineHeight: "1rem",
											letterSpacing: "0.198851px",
											color: colors.deepGreen,
										}}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<StyledTableCell align="center" scope="row">
											{`${item.TipoDocumento + "-" + item.NroDocumento}`}
										</StyledTableCell>
										<StyledTableCell align="center">
											<DownloadButton
												onClick={() => {
													handleDownload(item.NroInternoRade);
												}}
											>
												{isLoading &&
												loadingNroInternoRade === item.NroInternoRade ? (
													<CircularProgress color="success" size={20} />
												) : (
													<FileDownloadOutlinedIcon
														sx={{ color: colors.green }}
													/>
												)}
											</DownloadButton>
										</StyledTableCell>
									</TableRow>
								))
							) : (
								<TableRow
									style={{
										fontFamily: "Poppins",
										fontStyle: "normal",
										fontWeight: 500,
										fontSize: "0.75rem",
										lineHeight: "1rem",
										letterSpacing: "0.198851px",
										color: colors.deepGreen,
									}}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<StyledTableCell colSpan={5} align="center">
										{EmptyMessage.toUpperCase()}
									</StyledTableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</StyledTableContainer>
			</Paper>
		</Box>
	);
};

export default NoComertial;
const Title = styled.h1`
	width: 100%;
	height: 100%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.25rem;

	display: flex;
	align-items: center;
	color: ${colors.green};
`;

const TitleWrap = styled.div`
	display: flex;
	width: 100%;
	height: 10%;
	align-items: center;
	justify-content: space-around;
	border-bottom: 1px solid ${colors.primaryGreen};
	margin-bottom: 3%;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.primaryGreen,
		color: colors.white,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198519px",
		textAlign: "center",
	},
	[`&.${tableCellClasses.body}`]: {
		alignItems: "center",
		textAlign: "center",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198851px",
		color: colors.deepGreen,
		borderBottom: `1px solid ${colors.primaryGreen}`,
	},
}));

const StyledTableContainer = styled(TableContainer)`
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.primaryGreen};
		border-radius: 0px 8px 8px 0px;
		border: 1px solid ${colors.primaryGreen};
	}
`;
