import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SortOptions, SortOptionsReturn } from "../../enum/sort-Option";
import { SelectChangeEvent } from "@mui/material/Select";
import colors from "../../ui/colors";

interface SelectorProps {
	Options: string[];
	onChange: (selectedOption: string) => void;
}
const Selector: React.FC<SelectorProps> = ({ Options, onChange }) => {
	const [selectedOption, setSelectedOption] = useState<string>(Options[0]);

	const handleOptionChange = (event: SelectChangeEvent<string>) => {
		let option = event.target.value;
		if (option === SortOptions["DatePlus"]) {
			onChange(SortOptionsReturn["Fecha +"]);
		} else if (option === SortOptions["DateMinus"]) {
			onChange(SortOptionsReturn["Fecha -"]);
		} else if (option === SortOptions["Name"]) {
			onChange(SortOptionsReturn["Nombre"]);
		} else if (option === SortOptions["Folder"]) {
			onChange(SortOptionsReturn["Carpeta"]);
		} else {
			onChange(option);
		}
		setSelectedOption(option);
	};

	return (
		<FormControl sx={{ m: 1, minWidth: 100 }}>
			<InputLabel id="demo-simple-select-helper-label"></InputLabel>
			<Select
				sx={{
					minWidth: "100px",
					maxWidth: "12rem",
					maxHeight: "2.5rem",
					minHeight: "2.375rem",
					color: `${colors.primaryGreen}`,
					borderColor: `${colors.primaryGreen}`,
					"&:focus": {
						borderColor: `${colors.primaryGreen}`,
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: `${colors.primaryGreen}`,
					},
				}}
				labelId="demo-simple-select-helper-label"
				id="demo-simple-select-helper"
				value={selectedOption}
				onChange={handleOptionChange}
			>
				{Options.map((option, index) => (
					<MenuItem key={option + index} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default Selector;
