import React from "react";
import { MenuItem, Autocomplete, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { CreationDataClients, DataClients } from "../../models/Data";
import colors from "../../ui/colors";
import Paper from "@mui/material/Paper";

interface MultiSubscriptorProp {
	companiesData: CreationDataClients[] | DataClients[];
	onSelectionChange: (selectedIds: string[]) => void;
}
export const MultiSubscriptor: React.FC<MultiSubscriptorProp> = ({
	companiesData,
	onSelectionChange,
}) => {
	return (
		<Autocomplete
			PaperComponent={(props) => (
				<Paper {...props} style={{ maxHeight: 100 }} />
			)}
			sx={{
				position: "relative",
				width: "460px",
				height: 70,
				left: "0",
				backgroundColor: "white",
				fontFamily: "Montserrat",
				textAlign: "left",
				color: "grey",
				border: "none",
			}}
			multiple
			id="tags-standard"
			options={companiesData}
			limitTags={2}
			onChange={(event, newValue) => {
				const selectedIds = newValue.map((option) => option._id);
				onSelectionChange(selectedIds);
			}}
			getOptionLabel={(option: DataClients | CreationDataClients) =>
				option.NombreCliente[0]
			}
			disableCloseOnSelect
			renderOption={(
				props,
				option: DataClients | CreationDataClients,
				{ selected }
			) => (
				<MenuItem
					key={option._id}
					value={option._id}
					sx={{
						justifyContent: "space-between",
						border: "none",
						backgroundColor: colors.white,
						color: colors.green,
					}}
					{...props}
				>
					{option.NombreCliente}
					{selected ? <CheckIcon key={option._id} color="info" /> : null}
				</MenuItem>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					label=""
					placeholder="Compañias"
					sx={{
						fontStyle: "normal",
						fontWeight: 400,
						fontSize: "1rem",
						lineHeight: "1rem",
						maxHeight: "20",
						color: "grey",
						background: colors.white,
					}}
				/>
			)}
		/>
	);
};

export default MultiSubscriptor;
