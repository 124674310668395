import React from "react";
import styled from "styled-components";
import colors from "../../../ui/colors";
import CommentBox from "../../CommentBox/CommentBox";
import { Box } from "@mui/material";
export interface FolderCommentProp {
	FolderNro: string;
	CompanyName: string;
	Writer: string;
}
export const FolderComment: React.FC<FolderCommentProp> = ({
	FolderNro,
	CompanyName,
	Writer,
}) => {
	return (
		<Box sx={{ width: "100%" }}>
			<TitleWrap>
				<Title>Agregar Comentario</Title>
			</TitleWrap>
			<AreaWrap>
				<CommentBox
					FolderNro={FolderNro}
					CompanyName={CompanyName}
					Writer={Writer}
				/>
			</AreaWrap>
		</Box>
	);
};

export default FolderComment;

const AreaWrap = styled.div`
	width: 100%;
`;

const Title = styled.h1`
	width: 100%;
	height: 100%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 22px;

	display: flex;
	align-items: center;

	color: ${colors.green};
`;

const TitleWrap = styled.div`
	display: flex;
	width: 100%;
	height: 8%;
	margin-bottom: 1.5%;
	align-items: center;
	justify-content: space-around;
	border-bottom: 1px solid ${colors.primaryGreen};
`;
