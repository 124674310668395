import React from "react";
import styled from "styled-components";
import colors from "../../colors";
import { AddUserForm } from "../../../template/Forms/AddUserForm/AddUserForm";
import { useCreateUserMutation } from "../../../apis/users.api";
import EditUsersForm from "../../../template/Forms/EditUsersForm/EditUsersForm";
import { Toaster, toast } from "sonner";

interface FormProps {
	trigger: boolean;
	userCreating: string;
	setTrigger: (arg0: boolean) => void;
	formSelection: string;
	UserRole: string;
}
export const UsersPop: React.FC<FormProps> = ({
	trigger,
	setTrigger,
	userCreating,
	formSelection,
	UserRole,
}) => {
	const [createUser, { isLoading: createUserLoader }] = useCreateUserMutation();

	const handleUserCreation = async (
		username: string,
		password: string,
		email: string,
		roleString: string,
		position: string,
		company: string,
		createdBy: string,
		subscriptions: string[]
	) => {
		let role = roleString.split(",");
		const SubscriptorsCreated = createSubscriptions(subscriptions);
		try {
			const response = await createUser({
				username,
				password,
				email,
				role,
				position,
				company,
				subscriptions: SubscriptorsCreated,
				createdBy,
			});
			if ("error" in response) {
				if ("data" in response.error) {
					const data: any = response.error.data;
					if ("message" in data) {
						const errorMessage = JSON.stringify(data.message).replace(
							/[[\]"]/g,
							" "
						);
						toast.error(errorMessage.toUpperCase());
					} else {
						toast.error(`An error occurred: ${response.error}`);
					}
				}
				setTrigger(!createUserLoader);
				return;
			}
			toast.success("Usuario creado con éxito");
			setTimeout(() => {
				setTrigger(createUserLoader);
			}, 1000);

			return;
		} catch (error) {
			console.error("Error creating contact:", error);
		}
	};
	return trigger ? (
		<>
			<Toaster richColors />
			{formSelection === "Add" ? (
				<PopupDiv>
					<FromWrap>
						<MainForm>
							<AddUserForm
								CreateHandler={handleUserCreation}
								setTrigger={setTrigger}
								userCreating={userCreating}
								isLoading={createUserLoader}
								UserRole={UserRole}
							/>
						</MainForm>
					</FromWrap>
				</PopupDiv>
			) : (
				<EditUsersForm
					setTrigger={setTrigger}
					userCreating={""}
					isLoading={false}
					UserRole={UserRole}
				/>
			)}
		</>
	) : (
		<React.Fragment />
	);
};

export default UsersPop;
function createSubscriptions(companyIds: string[]) {
	return companyIds.map((companyId) => ({
		companyid: companyId,
		folders: [""],
	}));
}
const MainForm = styled.div`
	margin-top: 5%;
	grid-area: 1 / 1 / 2 / 2;
`;

const FromWrap = styled.div`
	width: 80%;
	height: 100%;
	padding-left: 30px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
`;

const PopupDiv = styled.div`
	position: absolute;
	max-width: 520px;
	max-height: 720px;
	width: 520px;
	height: 720px;
	border-radius: 10px;
	background-color: ${colors.white};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 4px solid ${colors.primaryGreen};
	z-index: 1;
`;
