import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface AuthState {
	token?: string;
}

const slice = createSlice({
	name: "auth",
	initialState: {} as AuthState,
	reducers: {
		setAuthState: (state, { payload: { token } }: PayloadAction<AuthState>) => {
			state.token = token;
		},
	},
});

export const { setAuthState } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.token;
