import React from "react";
import styled from "styled-components";
import colors from "../../../ui/colors";
import { FolderResumeProps } from "../FolderResume/FolderResume";
import { Grid } from "@mui/material";

export const FolderProperties: React.FC<FolderResumeProps> = ({
	FolderData,
}) => {
	const folder = FolderData;
	return (
		<Grid
			container
			sx={{
				textAlign: "left",
				display: "grid",
				gridTemplateColumns: "repeat(2, 1fr)",
				gridTemplateRows: "repeat(4, 0.15fr)",
				border: `1px solid ${colors.primaryGreen}`,
				boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
				borderRadius: "8px",
				height: "100%",
			}}
		>
			<PropertiesTitle>Propiedades</PropertiesTitle>
			<Information>
				Id único:{" "}
				<Complement>{folder.Carpetas.Identificacion.Correlativo}</Complement>
			</Information>
			<Information>
				Carpeta:{" "}
				<Complement>{folder.Carpetas.Identificacion.Carpeta}</Complement>
			</Information>
			<Information>
				Referencia de Cliente:{"  "}
				{folder.Carpetas.Identificacion.RefCliente ? (
					<Complement>{` ${folder.Carpetas.Identificacion.RefCliente} `}</Complement>
				) : (
					" - - - "
				)}
			</Information>
		</Grid>
	);
};

export default FolderProperties;

const PropertiesTitle = styled.div`
	color: ${colors.white};
	background-color: ${colors.primaryGreen};
	border-radius: 2px 2px 0px 0px;
	padding-left: 2.5%;
	min-height: 45px;
	max-height: 55px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.2px;

	grid-area: 1 / 1 / 2 / 3;
`;

const Information = styled.div`
	padding-left: 5%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.2px;
	color: ${colors.primaryGreen};
`;

const Complement = styled.h2`
	display: inline-block;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.2px;

	color: ${colors.primaryGreen};
`;
