import React from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	OutlinedInput,
	MenuItem,
	FormControl,
	Select,
	Grid,
} from "@mui/material/";
import { SelectChangeEvent } from "@mui/material/Select";
import colors from "../../ui/colors";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useState } from "react";

interface RangeProps {
	onDateFilter: (fromDate: string, toDate: string) => void;
}
export const DateRangeSelector: React.FC<RangeProps> = ({ onDateFilter }) => {
	const [open, setOpen] = useState(false);
	const [fromYear, setFromYear] = useState<string>("None");
	const [toYear, setToYear] = useState<string>("None");

	const handleFromYear = (event: SelectChangeEvent<string>) => {
		setFromYear(event.target.value);
	};
	const handleToYear = (event: SelectChangeEvent<string>) => {
		setToYear(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (
		event: React.SyntheticEvent<unknown>,
		reason?: string
	) => {
		setFromYear("None");
		setToYear("None");
		onDateFilter("None", "None");

		if (reason !== "backdropClick") {
			setOpen(false);
		}
	};

	const handleFilter = (
		event: React.SyntheticEvent<unknown>,
		reason?: string
	) => {
		onDateFilter(fromYear, toYear);

		if (reason !== "backdropClick") {
			setOpen(false);
		}
	};

	return (
		<Box>
			<Button
				onClick={handleClickOpen}
				style={{
					color: `${colors.white}`,
					backgroundColor: `${colors.primaryGreen}`,
				}}
				sx={{ height: "2.5rem" }}
			>
				<FilterAltOutlinedIcon />
				Filtrar
			</Button>

			<Dialog
				disableEscapeKeyDown
				open={open}
				onClose={handleClose}
				scroll="paper"
				PaperProps={{
					sx: {
						background: `${colors.primaryGreen}`,
						minWidth: "305px",
						minHeight: "180px",
						m: 0,
					},
				}}
				BackdropProps={{
					style: {
						backgroundColor: colors.greenTransparent,
					},
				}}
			>
				<DialogTitle
					style={{
						color: `${colors.white}`,
						fontFamily: "Montserrat",
						fontSize: "0.75rem",
						fontWeight: "700",
						lineHeight: "1rem",
						textAlign: "left",
						maxHeight: "8px",
					}}
				>
					Año
				</DialogTitle>
				<DialogContent>
					<Box
						component="form"
						sx={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-evenly",
						}}
						p={1}
					>
						<Grid container spacing={2} sx={{ marginTop: 1 }}>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, minWidth: "150" }}>
									<InputLabel
										id="demo-dialog-native"
										sx={{ color: `${colors.white}` }}
									>
										Desde
									</InputLabel>
									<Select
										value={fromYear}
										onChange={handleFromYear}
										input={<OutlinedInput id="demo-dialog-native" />}
										sx={{
											minWidth: "135px",
											minHeight: "37px",
											color: `${colors.white}`,
											borderColor: `${colors.white}`,
										}}
									>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value="None"
										>
											<em>None</em>
										</MenuItem>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value={"2020"}
										>
											2020
										</MenuItem>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value={"2021"}
										>
											2021
										</MenuItem>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value={"2022"}
										>
											2022
										</MenuItem>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value={"2023"}
										>
											2023
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl sx={{ m: 1, minWidth: 120 }}>
									<InputLabel
										id="demo-dialog-select-label"
										sx={{ color: `${colors.white}` }}
									>
										Hasta
									</InputLabel>
									<Select
										value={toYear}
										onChange={handleToYear}
										input={<OutlinedInput label="ToYear" />}
										sx={{
											minWidth: "135px",
											minHeight: "37px",
											color: `${colors.white}`,
											borderColor: `${colors.white}`,
										}}
									>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value="None"
										>
											<em>None</em>
										</MenuItem>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value={"2020"}
										>
											2020
										</MenuItem>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value={"2021"}
										>
											2021
										</MenuItem>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value={"2022"}
										>
											2022
										</MenuItem>
										<MenuItem
											sx={{
												color: `${colors.green}`,
											}}
											value={"2023"}
										>
											2023
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Button
						onClick={handleFilter}
						sx={{
							backgroundColor: `${colors.white}`,
							color: `${colors.primaryGreen}`,
							minWidth: "135px",
							minHeight: "30px",
							borderRadius: "4px",
							border: `1px solid ${colors.deepGreen}`,
						}}
					>
						Ver Resultados
					</Button>
					<Button
						onClick={(
							event: React.SyntheticEvent<unknown>,
							reason?: string
						) => {
							handleClose(event, reason);
						}}
						sx={{
							backgroundColor: `${colors.primaryGreen}`,
							color: `${colors.white}`,
							minWidth: "135px",
							minHeight: "30px",
							borderRadius: "4px",
							border: `1px solid ${colors.white}`,
						}}
					>
						Limpiar
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DateRangeSelector;
