import React from "react";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import {
	MainForm,
	Title,
	UsersList,
	SubWrap,
	Subscriptor,
	ButtonIcons,
	ButtonsWrap,
	ButtonPop,
} from "../../Forms/EditUsersForm/EditUsersForm";

interface UsersSettingsProps {
	UsersInfo: string[] | undefined;
	colors: Record<string, string>;
	handleSettings: (index: number) => void;
	handleDelete: (index: number) => void;
	SettingsIcon: number | null;
	deleteIcon: number | null;
	setTrigger: (value: boolean) => void;
}

const UsersSettings: React.FC<UsersSettingsProps> = ({
	UsersInfo,
	colors,
	handleSettings,
	handleDelete,
	SettingsIcon,
	deleteIcon,
	setTrigger,
}) => {
	return (
		<>
			<MainForm>
				<Title>Usuarios Disponibles</Title>
				<UsersList>
					{UsersInfo?.map((user, index) => (
						<SubWrap key={index}>
							<AccountCircleOutlinedIcon
								style={{
									fontSize: "1rem",
									color: `${colors.green}`,
									paddingRight: "1%",
								}}
							/>
							<Subscriptor gridArea={`${index + 1} / 1 / 1 / 2`} key={index}>
								{user}
							</Subscriptor>
							<ButtonIcons onClick={() => handleSettings(index)}>
								{SettingsIcon === index ? (
									<SettingsApplicationsRoundedIcon
										sx={{ color: colors.primaryGreen }}
									/>
								) : (
									<SettingsApplicationsOutlinedIcon
										sx={{ color: colors.primaryGreen }}
									/>
								)}
							</ButtonIcons>
							<ButtonIcons onClick={() => handleDelete(index)}>
								{deleteIcon === index ? (
									<IndeterminateCheckBoxIcon
										sx={{ color: colors.primaryGreen }}
									/>
								) : (
									<IndeterminateCheckBoxOutlinedIcon
										sx={{ color: colors.primaryGreen }}
									/>
								)}
							</ButtonIcons>
						</SubWrap>
					))}
				</UsersList>
			</MainForm>
			<ButtonsWrap>
				<ButtonPop
					onClick={() => setTrigger(false)}
					backgroundColor={`${colors.primaryGreen}`}
					textColor={`${colors.white}`}
				>
					{`GUARDAR CAMBIOS`}
				</ButtonPop>
				<ButtonPop
					onClick={() => setTrigger(false)}
					backgroundColor={`${colors.white}`}
					textColor={`${colors.primaryGreen}`}
				>
					{`CANCELAR`}
				</ButtonPop>
			</ButtonsWrap>
		</>
	);
};

export default UsersSettings;
