import React, { useState } from "react";
import InputField from "../../Input/InputField";
import styled from "styled-components";
import colors from "../../../ui/colors";

interface AddressProps {
	onChange: (address: Record<string, string>) => void;
}

const InputContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 2 auto;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
`;

export const AddressForm: React.FC<AddressProps> = ({ onChange }) => {
	const [address, setAddress] = useState<Record<string, string>>({
		city: "",
		country: "",
		department: "",
		name: "",
		state: "",
		zip: "",
	});

	const handleInputChange = (name: string, value: string) => {
		setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
		onChange(address);
	};

	return (
		<>
			<Title>Dirección</Title>
			<InputContainer>
				<InputField
					name={"Departamento"}
					type={"text"}
					placeholder={"Ingresar departamento"}
					inputWidth="217.5px"
					onInputChange={(value) => handleInputChange("department", value)}
					isRequired={false}
				/>
				<InputField
					name={"Ciudad"}
					type={"text"}
					placeholder={"Ingresar ciudad"}
					inputWidth="217.5px"
					onInputChange={(value) => handleInputChange("city", value)}
					isRequired={false}
				/>
				<InputField
					name={"Estado"}
					type={"text"}
					placeholder={"Ingresar estado"}
					inputWidth="217.5px"
					onInputChange={(value) => handleInputChange("state", value)}
					isRequired={false}
				/>
				<InputField
					name={"Código Postal"}
					type={"text"}
					placeholder={"Ingresar código postal"}
					inputWidth="217.5px"
					onInputChange={(value) => handleInputChange("zip", value)}
					isRequired={false}
				/>
			</InputContainer>
			<br />
			<InputField
				name={"Pais"}
				type={"text"}
				placeholder={"Ingresar país"}
				onInputChange={(value) => handleInputChange("country", value)}
				isRequired={false}
			/>
		</>
	);
};

export default AddressForm;

const Title = styled.h1`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 20.5423px;
	line-height: 25px;

	color: ${colors.deepGreen};
`;
