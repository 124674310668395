import React from "react";
import styled from "styled-components";
import UsersPop from "../AddUserpop/AddUserpop";

interface PopupTriggerInterface {
	openPopup: boolean;
	setopenPopUp: React.Dispatch<React.SetStateAction<boolean>>;
	userValidated: string;
	formSelection: string;
	UserRole: string;
}
export const PopupTriggerUser: React.FC<PopupTriggerInterface> = ({
	openPopup,
	setopenPopUp,
	userValidated,
	formSelection,
	UserRole,
}) => {
	return (
		<>
			{openPopup && (
				<Popup>
					<UsersPop
						trigger={openPopup}
						setTrigger={setopenPopUp}
						userCreating={userValidated}
						formSelection={formSelection}
						UserRole={UserRole}
					/>
				</Popup>
			)}
		</>
	);
};

export default PopupTriggerUser;

export const Popup = styled.div`
	background-color: rgba(240, 240, 240, 0.4);
	z-index: 2;
`;
