import React from "react";
import { FolderTable } from "../FolderTable/FolderTable";
import { ClientsTableMui } from "../ClientsTableMui/ClientsTableMui";
import { SingleClientTable } from "../SingleClientTable/SingleClientTable";

interface TablesRender {
	FolderStatus: boolean;
	UserStatus: boolean;
	UserRegister: string;
	UserSelected: (UserName: string, FolderQuest: string) => void;
	SelectedCompany: string;
	SelectedFolder: string;
	DateRange: string[];
	Filter: string;
	searchMain: string | undefined;
	searchClient: string | undefined;
}

const ClientsTableRender: React.FC<TablesRender> = ({
	FolderStatus,
	UserStatus,
	UserRegister,
	UserSelected,
	SelectedCompany = "",
	SelectedFolder = "",
	DateRange,
	Filter,
	searchMain,
	searchClient,
}) => {
	let TableComponent;
	const handleClientSelection = (_id: string, folder: string) => {
		UserSelected(_id, folder);
	};
	if (FolderStatus && !UserStatus) {
		TableComponent = (
			<FolderTable
				UserRegister={UserRegister}
				SelectedCompany={SelectedCompany}
				SelectedFolder={SelectedFolder}
			/>
		);
	} else if (!FolderStatus && UserStatus) {
		TableComponent = (
			<SingleClientTable
				CompanySelectedId={SelectedCompany}
				FolderSelected={handleClientSelection}
				searchTable={searchClient}
				Filter={Filter}
				DateRange={""}
			/>
		);
	} else {
		TableComponent = (
			<ClientsTableMui
				CompanySelected={handleClientSelection}
				DateRange={DateRange}
				Filter={Filter}
				searchTable={searchMain}
			/>
		);
	}
	return <>{TableComponent}</>;
};

export default ClientsTableRender;
