import React, { useCallback, useEffect, useState } from "react";
import * as style from "../../../styles/stylesClientsBar";
import { IconButton } from "../../../template/Buttons/IconButton/IconButton";
import colors from "../../colors";
import { ClientShowerBar } from "../ClientShowerBar/ClientShowerBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	dataClientsApi,
	getCompaniesData,
	updateDB,
} from "../../../apis/data.api";
import { newsApi } from "../../../apis/news.api";
import { contactsApi } from "../../../apis/contact.api";
import {
	Avatar,
	Box,
	Grid,
	Skeleton,
	Table,
	TableRow,
	TextField,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Toaster, toast } from "sonner";
import { RootState } from "../../../app/store";
import { StyledTableCell } from "../../../template/Tables/ClientsTableMui/ClientsTableMui";

interface ClientProps {
	CompanySelections: (clientsName: string, folder: string) => void;
	UserSelection: React.Dispatch<React.SetStateAction<boolean>>;
}
type CompanyItem = {
	_id: string;
	NombreCliente: string[];
	Carpetas: string[];
};
type CompaniesBrief = CompanyItem[];
const stringAvatar = (name: string) => {
	let initials = name[0] ? name[0].toUpperCase() : "";
	initials += name[1] ? name[1].toUpperCase() : "";

	return {
		sx: {
			bgcolor: "#d9d9d9",
		},
		children: initials,
	};
};
export const ClientsBar: React.FC<ClientProps> = ({
	CompanySelections,
	UserSelection,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const userInfo = location.state?.userInfo;

	const token = useSelector((state: RootState) => state.auth.token);
	const [activeClient, setActiveClient] = useState<string | null>(null);
	const [userStatus, setUserStatus] = useState<boolean>(true);

	const isAdmin = userInfo?.role[0].toUpperCase() === "ADMINISTRADOR";
	const [filter, setFilter] = useState<string>("");

	const [companiesData, setCompaniesData] = useState<CompaniesBrief>([]);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const getCompaniesInfo = useCallback(async () => {
		try {
			setIsLoading(true);
			const response = await getCompaniesData(token as string);
			const updatedResponse = response.map((company: any) => {
				return {
					...company,
					Carpetas: Array.from(new Set(company.Carpetas)),
				};
			});
			setCompaniesData(updatedResponse);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	}, [token]);

	useEffect(() => {
		getCompaniesInfo();
	}, [getCompaniesInfo]);

	const handleUpdate = async () => {
		try {
			await updateDB(token as string);
			dispatch(dataClientsApi.util.resetApiState());
			dispatch(newsApi.util.resetApiState());
			dispatch(contactsApi.util.resetApiState());
			toast.success("BASE DE DATOS ACTUALIZADA");
		} catch (error) {
			toast.error("ERROR AL ACTUALIZAR BASE DE DATOS");
		}
	};

	const handleClientSelection = async (clientName: string, folder: string) => {
		setActiveClient(clientName);
		CompanySelections(clientName, folder);
		if (activeClient === clientName) {
			setActiveClient(null);
		} else {
			setActiveClient(clientName);
		}
	};

	const handleUser = () => {
		const newUserStatus = !userStatus;
		setUserStatus(newUserStatus);
		UserSelection((prevState: boolean) => !prevState);
	};
	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(event.target.value);
	};

	const handleSelectionChange = (newActiveClient: string) => {
		if (activeClient !== newActiveClient) {
			setActiveClient(newActiveClient);
		}
	};
	const handleLogout = () => {
		dispatch(dataClientsApi.util.resetApiState());
		dispatch(newsApi.util.resetApiState());
		dispatch(contactsApi.util.resetApiState());
		navigate("/");
	};
	type FilteredCompany = {
		_id: string;
		NombreCliente: string[];
		Carpetas: string[];
	};

	let filteredData: (CompanyItem | FilteredCompany)[] = [];

	if (filter) {
		filteredData = (companiesData || []).flatMap((item) => {
			const filterLower = filter.toLowerCase();

			if (item._id.toLowerCase().includes(filterLower)) {
				return [item];
			}

			const nameMatch = item.NombreCliente.some((name) =>
				name.toLowerCase().includes(filterLower)
			);
			if (nameMatch) {
				return [item];
			}

			const matchingFolders = item.Carpetas.filter((Carpeta) =>
				Carpeta.toLowerCase().includes(filterLower)
			);

			if (matchingFolders.length > 0) {
				return [
					{
						_id: item._id,
						NombreCliente: item.NombreCliente,
						Carpetas: matchingFolders,
					},
				];
			} else {
				return [];
			}
		});
	} else {
		filteredData = companiesData;
	}

	const isFocused = filter !== "";

	return (
		<>
			<Toaster position="bottom-right" richColors />
			<Box
				sx={{
					width: "100%",
					height: "100%",
					backgroundColor: colors.primaryGreen,
				}}
			>
				<Grid
					container
					sx={{
						backgroundColor: colors.primaryGreen,
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							margin: "1rem",
						}}
					>
						{userInfo.username ? (
							<Avatar
								{...stringAvatar(userInfo?.username ?? "")}
								sx={{ width: 60, height: 60 }}
							/>
						) : (
							<Skeleton variant="circular" height="2.5rem" width="2.5rem" />
						)}
					</Grid>
					<Grid item xs={12} sx={{ margin: "1rem 1.5rem", height: "2rem" }}>
						<IconButton
							backgroundcolorprop={colors.white}
							IconNum={0}
							buttonLabel={`${userInfo?.role[0].toUpperCase()}`}
							textcolor={colors.primaryGreen}
							hoverbackgroundcolor={colors.green}
							hovertextcolor={colors.white}
							hoverbordercolor={colors.green}
							OnClick={() => handleUser()}
							fontSize="1rem"
						/>
					</Grid>
					<Grid item xs={12} sx={{ margin: "1rem 1.5rem", height: "1.5rem" }}>
						<TextField
							size="small"
							label="Filtrar por Nombre o Carpeta"
							value={filter}
							onChange={handleFilterChange}
							InputProps={{
								endAdornment: <FilterAltIcon sx={{ color: colors.white }} />,
							}}
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								width: "100%",

								"& .MuiInputBase-input": {
									color: "white",
									height: "0.5rem",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								},
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderWidth: "1.7px",
										borderColor: colors.white,
										borderStyle: "solid",
									},
									"&:hover fieldset": {
										borderWidth: "2px",
										borderColor: colors.green,
										borderStyle: "solid",
									},
									"&.Mui-focused fieldset": {
										borderColor: colors.green,
									},
								},
								"& label": {
									color: isFocused ? "white" : "white",
									fontWeight: "medium",
								},
								"& label.MuiInputLabel-shrink": {
									color: colors.green,
								},
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							marginTop: "1.5rem",
							marginLeft: "1.5rem",
							marginRight: "1.5rem",
							padding: "0",
							width: "100%",
							height: "auto",
							maxHeight: "42rem",
							minWidth: "18rem",
						}}
					>
						<Box sx={{ height: "100%" }}>
							<style.Title>Empresas</style.Title>
							<style.ClientsMapScroll>
								{!companiesData || isLoading ? (
									<Table>
										<tbody>
											{Array(12)
												.fill(0)
												.map((_, index) => (
													<TableRow key={index}>
														<StyledTableCell>
															<Skeleton
																variant="text"
																sx={{ color: colors.white }}
															/>
														</StyledTableCell>
													</TableRow>
												))}
										</tbody>
									</Table>
								) : (
									filteredData.map((Company, index) => (
										<ClientShowerBar
											key={Company._id + index}
											ClientName={Company.NombreCliente[0]}
											Folders={(Company as CompanyItem).Carpetas || []}
											onClientNameClick={handleClientSelection}
											onSelectionChange={() =>
												handleSelectionChange(Company.NombreCliente[0])
											}
											isActive={Company._id === activeClient}
											index={index}
											Company_id={Company._id}
										/>
									))
								)}
							</style.ClientsMapScroll>
						</Box>
					</Grid>
					{isAdmin && (
						<Grid
							item
							xs={12}
							sx={{ margin: "-3.5rem 1.5rem", height: "1rem" }}
						>
							<IconButton
								backgroundcolorprop={colors.white}
								IconNum={2}
								buttonLabel="Actualizar"
								textcolor={colors.primaryGreen}
								hoverbackgroundcolor={colors.green}
								hovertextcolor={colors.white}
								hoverbordercolor={colors.green}
								OnClick={handleUpdate}
								fontSize="0.75rem"
							/>
						</Grid>
					)}
					<Grid item xs={12} sx={{ margin: "-1rem 1.5rem" }}>
						<IconButton
							backgroundcolorprop={colors.white}
							IconNum={6}
							buttonLabel="Logout"
							textcolor={colors.primaryGreen}
							hoverbackgroundcolor={colors.green}
							hovertextcolor={colors.white}
							hoverbordercolor={colors.green}
							OnClick={handleLogout}
							fontSize="0.75rem"
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default ClientsBar;
