import * as React from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Textarea from "@mui/joy/Textarea";
import colors from "../../ui/colors";
import { FolderCommentProp } from "../Tables/FolderComment/FolderComment";
import { Toaster, toast } from "sonner";
import { useCreateNewsMutation } from "../../apis/news.api";

export const CommentBox: React.FC<FolderCommentProp> = ({
	FolderNro,
	CompanyName,
	Writer,
}) => {
	const [comment, setComment] = React.useState("");
	const [createNews] = useCreateNewsMutation();
	const handleInputChange = (e: {
		target: { value: React.SetStateAction<string> };
	}) => {
		setComment(e.target.value);
	};

	const handleSubmit = async () => {
		let dateObj: Date = new Date();
		let DateComment: string = `${dateObj.getDate()}/${
			dateObj.getMonth() + 1
		}/${dateObj.getFullYear()}`;
		try {
			const response = await createNews({
				ClientName: CompanyName,
				Folder: FolderNro,
				Content: comment,
				NewsDate: DateComment,
				Writer: Writer,
			});
			if ("error" in response) {
				if ("data" in response.error) {
					const data: any = response.error.data;
					if ("message" in data) {
						const errorMessage = JSON.stringify(data.message).replace(
							/[[\]"]/g,
							" "
						);
						toast.error(errorMessage.toUpperCase());
					} else {
						toast.error(`An error occurred: ${response.error}`);
					}
				}
				return;
			}
			toast.success("COMENTARIO AGREGADO");
		} catch (error) {
			console.error("Error creating new:", error);
		}
		setComment("");
	};

	return (
		<FormControl>
			<Toaster />
			<Textarea
				placeholder="Escribir comentario"
				minRows={2}
				value={comment}
				onChange={handleInputChange}
				endDecorator={
					<Button
						onClick={handleSubmit}
						sx={{
							ml: "auto",
							backgroundColor: `${colors.primaryGreen}`,
							color: "white",
							fontStyle: "Montserrat",
							fontSize: "10px",

							"&:hover": {
								backgroundColor: `${colors.white}`,
								border: `1px solid ${colors.primaryGreen}`,
								borderColor: `${colors.primaryGreen}`,
								color: `${colors.primaryGreen}`,
							},
						}}
					>
						Agregar comentario
					</Button>
				}
				sx={{
					minWidth: "600px",
					fontWeight: "normal",
					fontStyle: "Montserrat",
					borderColor: `${colors.primaryGreen}`,
					backgroundColor: `${colors.white}`,
					borderRadius: "8px",
					boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
				}}
			/>
		</FormControl>
	);
};

export default CommentBox;
