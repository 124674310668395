import styled from "styled-components";
import colors from "../ui/colors";

export const LanguageSelectorWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	background-color: ${colors.white};
`;

export const LanguageSelector = styled.select`
	width: 100%;
	height: 100%;
	background-color: ${colors.white};
	box-sizing: border-box;
	border: 1px solid ${colors.grey};
	border-radius: 0.375rem;
	padding-left: 4.4%;
	text-align: left;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 0.825rem;
	line-height: 0.69rem;

	color: ${colors.deepGreen};
`;
