import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "styled-components";
import colors from "../../colors";
import { AddBusiness, Newspaper } from "@mui/icons-material";

enum MenuItems {
	EMPRESA = "Empresa",
	NOVEDAD = "Novedad",
}

const menuItems = [
	{ name: MenuItems.EMPRESA, icon: <AddBusiness /> },
	{ name: MenuItems.NOVEDAD, icon: <Newspaper /> },
];
interface SelectorProps {
	onChange: (selectedOption: string) => void;
}
const AddNewSelector: React.FC<SelectorProps> = ({ onChange }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const handleAddNew = (option: string) => onChange(option);
	return (
		<Label>
			<Button onClick={() => setIsOpen(!isOpen)}>
				{"NUEVO"}
				<ArrowDropDownIcon sx={{ fontSize: "1rem" }} />
			</Button>
			<Input type="checkbox" />
			<Menu isOpen={isOpen}>
				{menuItems.map((item, index) => (
					<MenuItem
						key={item.name + index}
						onClick={() => handleAddNew(item.name)}
					>
						{item.icon} {item.name}
					</MenuItem>
				))}
			</Menu>
		</Label>
	);
};

export default AddNewSelector;

type MenuProps = {
	isOpen: boolean;
};

export const Label = styled.label`
	width: 100%;
	height: 100%;
	padding: 0;
	left: 0;
	top: 0;
`;

export const Button = styled.div`
	height: 100%;
	text-align: center;
	background-color: ${colors.primaryGreen};
	cursor: pointer;
	white-space: nowrap;
	padding: 0;
	margin: 0;
	color: ${colors.white};
	font-family: "Montserrat";
	padding-left: 7%;
	font-style: normal;
	font-weight: 500;
	font-size: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const Input = styled.input`
	display: none;
`;

export const MenuItem = styled.li`
	display: flex;
	align-items: center;
	padding: 10px 20px;
	cursor: pointer;
	white-space: nowrap;
	text-align: left;
	border-bottom: 0.7px solid ${colors.primaryGreen};
	color: ${colors.primaryGreen};

	&:hover {
		background-color: ${colors.grey};
	}

	svg {
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
	}
`;

export const Menu = styled.ul<MenuProps>`
	position: absolute;
	top: 75%;
	border: 1px solid ${colors.grey};
	border-radius: 4px;
	padding: 0;
	margin: 2px 0 0 0;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
	background-color: ${colors.white};
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	display: ${(props) => (props.isOpen ? "block" : "none")};
	z-index: 2;
`;

export const AddDropdownDiv = styled.div``;
