import React from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from "@mui/icons-material/Edit";
import colors from "../../../ui/colors";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

interface ButtonProps {
	backgroundcolorprop: string;
	IconNum?: number;
	buttonLabel?: string;
	textcolor?: string;
	OnClick?: () => void;
	alignment?: "left" | "center" | "right";
	hoverbackgroundcolor?: string;
	hovertextcolor?: string;
	hoverbordercolor?: string;
	fontSize?: string;
}

enum Icon {
	user = 0,
	filter = 1,
	update = 2,
	edit = 4,
	add = 5,
	logout = 6,
}

const StyledButton = styled(Button)<ButtonProps>((props) => ({
	"&.MuiButton-root": {
		display: "flex",
		justifyContent: props.alignment || "center",
		color: props.textcolor || colors.primaryGreen,
		backgroundColor: props.backgroundcolorprop || colors.white,
		borderColor: colors.primaryGreen,
		fontFamily: "Montserrat",
		fontWeight: 500,
		fontSize: props.fontSize || "0.6rem",
		width: "100%",
		minHeight: "2rem",
		"&:hover": {
			backgroundColor: props.hoverbackgroundcolor || colors.primaryGreen,
			color: props.hovertextcolor || colors.white,
			borderColor: props.hoverbordercolor || colors.primaryGreen,
		},
	},
}));

export const IconButton: React.FC<ButtonProps> = ({
	backgroundcolorprop,
	buttonLabel,
	IconNum,
	textcolor,
	OnClick,
	alignment,
	hoverbackgroundcolor,
	hovertextcolor,
	hoverbordercolor,
	fontSize,
}) => {
	const iconKey = Icon[IconNum as number];
	const IconComponent = IconComponents[iconKey] || null;

	return (
		<StyledButton
			variant="outlined"
			startIcon={IconComponent && <IconComponent />}
			onClick={OnClick}
			backgroundcolorprop={backgroundcolorprop}
			textcolor={textcolor}
			alignment={alignment}
			hoverbackgroundcolor={hoverbackgroundcolor}
			hovertextcolor={hovertextcolor}
			hoverbordercolor={hoverbordercolor}
			fontSize={fontSize}
		>
			{buttonLabel}
		</StyledButton>
	);
};

export default IconButton;

const IconComponents: Record<string, React.ElementType> = {
	user: AccountCircleOutlinedIcon,
	filter: FilterAltOutlinedIcon,
	update: UpdateIcon,
	edit: EditIcon,
	add: AddBoxOutlinedIcon,
	logout: LogoutIcon,
};
