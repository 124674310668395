import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../ui/colors";
import {
	Paper,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	tableCellClasses,
	TableBody,
	Table,
	Box,
	CircularProgress,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useGetInvoicePDFQuery } from "../../../apis/data.api";
import { FolderResumeProps } from "../FolderResume/FolderResume";
import { transformDateString } from "../../../helpers/DateFormat";
import { Toaster, toast } from "sonner";
export const InvoiceDataTable: React.FC<FolderResumeProps> = ({
	FolderData,
}) => {
	const invoiceData = FolderData.Carpetas.Facturas;
	const folderNumber = FolderData.Carpetas.Identificacion.Carpeta;
	const EmptyMessage = "Sin datos asignados";
	const isEmpty = invoiceData && invoiceData.length > 0;
	const [InvoiceNro, setInvoiceNro] = useState<string>("");
	const [InvoiceSerie, setInvoiceSerie] = useState<string>("");
	const [InvoiceType, setInvoiceType] = useState<string>("");

	const [shouldFetch, setShouldFetch] = useState<boolean>(false);
	const [loadingNroInternoRade, setLoadingNroInternoRade] = useState<
		string | null
	>(null);
	const [isLoading, setIsLoading] = useState(false);
	const {
		data,
		isError,
		error: queryError,
	} = useGetInvoicePDFQuery(
		{
			FolderNro: folderNumber,
			InvoiceNro: InvoiceNro,
			InvoiceSerie: InvoiceSerie,
			InvoiceType: InvoiceType,
		},
		{ skip: !shouldFetch }
	);

	useEffect(() => {
		if (isError && queryError && "status" in queryError) {
			const errorResponse = queryError as {
				status: number;
				data: { message: string };
			};
			if (
				errorResponse.status === 400 &&
				errorResponse.data.message === "File does not exist on the server"
			) {
				toast.error(`File is not available, contact your MRM Admin`);
				setIsLoading(false);
				setLoadingNroInternoRade(null);
			}
		} else if (data) {
			window.open(data.fileUrl, "_blank");
			setLoadingNroInternoRade(null);
			setIsLoading(false);
		}
	}, [data, isError, queryError]);
	useEffect(() => {
		if (InvoiceNro && InvoiceSerie && InvoiceType) {
			setShouldFetch(true);
			setLoadingNroInternoRade(InvoiceNro);
			setIsLoading(true);
		}
	}, [InvoiceNro, InvoiceSerie, InvoiceType]);

	const handleDownload = async (
		invoiceNro: string,
		invoiceSerie: string,
		invoiceType: string
	) => {
		setInvoiceNro(invoiceNro);
		setInvoiceSerie(invoiceSerie);
		setInvoiceType(invoiceType);
	};
	useEffect(() => {
		if (data) {
			window.open(data.fileUrl, "_blank");
			setLoadingNroInternoRade(null);
			setIsLoading(false);
		}
	}, [data]);
	return (
		<Box sx={{ width: "100%" }}>
			<Toaster richColors />
			<TitleWrap>
				<Title>Datos De Facturación</Title>
			</TitleWrap>
			<Paper elevation={0} style={{ backgroundColor: colors.white }}>
				<StyledTableContainer
					style={{
						maxHeight: "175px",
						borderRadius: "8px",
						boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
					}}
				>
					<Table
						sx={{
							minWidth: "400px",
							width: "100%",
							background: colors.white,
							height: 100,
						}}
						aria-label="ClientsTable"
					>
						<TableHead>
							<TableRow>
								<StyledTableCell>Tipo</StyledTableCell>
								<StyledTableCell>Número</StyledTableCell>
								<StyledTableCell>Fecha</StyledTableCell>
								<StyledTableCell>Total</StyledTableCell>
								<StyledTableCell>Moneda</StyledTableCell>
								<StyledTableCell>Descargar Factura</StyledTableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{isEmpty ? (
								invoiceData?.map((factura, index) => {
									const displayTipoFactura =
										factura.TipoFactura === "111"
											? "Factura"
											: factura.TipoFactura;

									return (
										<TableRow
											key={index + factura.NroFactura}
											style={{
												fontFamily: "Poppins",
												fontStyle: "normal",
												fontWeight: 500,
												fontSize: "0.75rem",
												lineHeight: "1rem",
												letterSpacing: "0.198851px",
												color: colors.deepGreen,
											}}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<StyledTableCell align="center">
												{displayTipoFactura}
											</StyledTableCell>
											<StyledTableCell align="center">
												{factura.NroFactura}
											</StyledTableCell>
											<StyledTableCell align="center">
												{transformDateString(factura.FechaFactura)}
											</StyledTableCell>
											<StyledTableCell align="center">
												{`$ ${parseInt(factura.TotalDocumento)}`}
											</StyledTableCell>
											<StyledTableCell align="center">
												{factura.Moneda === "1"
													? "PESOS"
													: factura.Moneda === "2"
													? "USD"
													: ""}
											</StyledTableCell>
											<StyledTableCell align="center">
												<DownloadButton
													onClick={() => {
														handleDownload(
															factura.NroFactura,
															factura.SerieFactura,
															factura.TipoFactura
														);
													}}
												>
													{isLoading &&
													loadingNroInternoRade === factura.NroFactura ? (
														<CircularProgress color="success" size={20} />
													) : (
														<FileDownloadOutlinedIcon
															sx={{ color: colors.green }}
														/>
													)}
												</DownloadButton>
											</StyledTableCell>
										</TableRow>
									);
								})
							) : (
								<TableRow>
									<StyledTableCell colSpan={6} align="center">
										{EmptyMessage.toUpperCase()}
									</StyledTableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</StyledTableContainer>
			</Paper>
		</Box>
	);
};

export default InvoiceDataTable;
export const DownloadButton = styled.button`
	cursor: pointer;
	background-color: ${colors.white};
	border: none;
	&:hover {
		transform: scale(1.1);
	}
`;

const Title = styled.h1`
	width: 100%;
	height: 100%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.25rem;

	display: flex;
	align-items: center;

	color: ${colors.green};
`;

const TitleWrap = styled.div`
	display: flex;
	width: 100%;
	height: 10%;
	align-items: center;
	justify-content: space-around;
	border-bottom: 1px solid ${colors.primaryGreen};
	margin-bottom: 1.5%;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.primaryGreen,
		color: colors.white,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198519px",
		textAlign: "center",
	},
	[`&.${tableCellClasses.body}`]: {
		alignItems: "center",
		textAlign: "center",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "0.75rem",
		lineHeight: "1rem",
		letterSpacing: "0.198851px",
		color: colors.deepGreen,
		borderBottom: `1px solid ${colors.primaryGreen}`,
	},
}));

const StyledTableContainer = styled(TableContainer)`
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		border-radius: 20px;
		background: ${colors.white};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.primaryGreen};
		border-radius: 0px 8px 8px 0px;
		border: 1px solid ${colors.primaryGreen};
	}
`;
