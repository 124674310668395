import { useGetAllUsersQuery } from "../apis/users.api";
export const UpdateDataCreator = () => {
	const {
		data: allUsers,
		isLoading: allUsersDataLoading,
		refetch: refetchAllUsers,
	} = useGetAllUsersQuery();

	return {
		allUsers,
		allUsersDataLoading,
		refetchAllUsers,
	};
};
