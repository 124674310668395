import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreatePDFRequest } from "../dto/pdf-request.dto";
import { DataClients } from "../models/Data";
import { DataClientsRequest } from "../dto/data-clients.request.dto";
import { RootState } from "../app/store";
import axios from "axios";

export const dataClientsApi = createApi({
	reducerPath: "dataClientsAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BACK_URL}/data-clients`,
		prepareHeaders: (headers, { getState }) => {
			const state = getState() as RootState;
			const token = state.auth.token;
			if (token) {
				headers.set("authentication", `${token}`);
			}
			return headers;
		},
	}),
	endpoints: (build) => ({
		createDataClient: build.mutation<DataClients, DataClientsRequest>({
			query: (createDataRequest) => ({
				url: "/",
				method: "POST",
				body: createDataRequest,
			}),
		}),
		getInvoiceTiff: build.query<any, { nrointerno: string }>({
			query: ({ nrointerno }) => ({
				url: `tiff/${nrointerno}`,
				method: "GET",
			}),
		}),
		getInvoicePDF: build.query<any, CreatePDFRequest>({
			query: (pdfInvoice) => ({
				url: "/pdf",
				method: "POST",
				body: pdfInvoice,
			}),
		}),
		getDBUpdates: build.query<any, void>({
			query: () => "dbupdates",
		}),
	}),
});

export const {
	useGetInvoicePDFQuery,
	useGetInvoiceTiffQuery,
	useGetDBUpdatesQuery,
} = dataClientsApi;

export const updateDB = async (token: string) => {
	const url = `${process.env.REACT_APP_BACK_URL}/data-clients/update`;
	try {
		const response = await axios({
			method: "GET",
			url: url,
			headers: { Authentication: token },
		});
		return response.data;
	} catch (error) {
		return error;
	}
};

interface GetAllDataParams {
	page: number;
	sortField?: "LatestDate" | "NombreCliente" | "CarpetasLength";
	sortOrder?: "asc" | "desc";
	dateRange?: string;
	term?: string;
}
const BASE_URL = `${process.env.REACT_APP_BACK_URL}/data-clients`;

export const getData = async (
	token: string,
	params: GetAllDataParams
): Promise<any> => {
	const {
		page = 1,
		sortField = "LatestDate",
		sortOrder = "desc",
		dateRange,
		term,
	} = params;

	const response = await axios.get(BASE_URL, {
		headers: { Authentication: token },
		params: {
			page,
			sortField,
			sortOrder,
			...(dateRange ? { dateRange } : {}),
			...(term ? { term } : {}),
		},
	});

	return response.data;
};

export const getCompaniesData = async (token: string): Promise<any> => {
	const response = await axios.get(`${BASE_URL}/companies`, {
		headers: { Authentication: token },
	});
	return response.data;
};

export const getClientData = async (
	_Id: string,
	token: string,
	term?: string,
	sortField?: string,
	sortOrder?: string,
	dateRange?: string
): Promise<any> => {
	let url = `${BASE_URL}/client?_Id=${_Id}`;

	if (term) {
		url += `&term=${encodeURIComponent(term)}`;
	}

	if (sortField) {
		url += `&sortField=${encodeURIComponent(sortField)}`;
	}

	if (sortOrder) {
		url += `&sortOrder=${encodeURIComponent(sortOrder)}`;
	}

	if (dateRange) {
		url += `&dateRange=${encodeURIComponent(dateRange)}`;
	}

	const response = await axios.get(url, {
		headers: { Authentication: token },
	});

	return response.data;
};
export const getCarpetaData = async (
	CompanyId: string,
	Nro_Carpeta: string,
	token: string
): Promise<any> => {
	const response = await axios.get(
		`${BASE_URL}/carpeta?CompanyId=${CompanyId}&Nro_Carpeta=${Nro_Carpeta}`,
		{
			headers: { Authentication: token },
		}
	);
	return response.data;
};

export const getAllSubscribeData = async (token: string): Promise<any> => {
	const response = await axios.get(`${BASE_URL}/subscribe`, {
		headers: { Authentication: token },
	});
	return response.data;
};

export const getDbUpdates = async (token: string): Promise<any> => {
	const response = await axios.get(`${BASE_URL}/dbupdates`, {
		headers: { Authentication: token },
	});
	return response.data;
};
