import { FC } from "react";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import picture1 from "../../../assets/images/handSigning.png";
import picture2 from "../../../assets/images/logoCarousel.png";
import picture3 from "../../../assets/images/office.png";
import styled from "styled-components";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
	{
		label: "Hand signing",
		imgPath: picture1,
	},
	{
		label: "Logo of MRM",
		imgPath: picture2,
	},
	{
		label: "Office of MRM",
		imgPath: picture3,
	},
];

const StyledImg = styled.img`
	border-radius: 20px;
	height: 100%;
	max-width: auto;
	width: 100%;
`;

export const Carousel: FC = () => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);

	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<AutoPlaySwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
			>
				{images.map((step, index) => (
					<div key={step.label + index}>
						{Math.abs(activeStep - index) <= 2 ? (
							<StyledImg src={step.imgPath} alt={step.label} />
						) : null}
					</div>
				))}
			</AutoPlaySwipeableViews>
		</Box>
	);
};
