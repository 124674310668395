import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateUserRequest } from "../dto/create-user-request.dto";
import { User } from "../models/User";
import { RootState } from "../app/store";

export const userApi = createApi({
	reducerPath: "usersAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BACK_URL}/users`,
		prepareHeaders: (headers, { getState }) => {
			const state = getState() as RootState;
			const token = state.auth.token;
			if (token) {
				headers.set("authentication", `${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		createUser: builder.mutation<User, CreateUserRequest>({
			query: (createUserRequest) => ({
				url: "/",
				method: "POST",
				body: createUserRequest,
			}),
		}),
		updateUser: builder.mutation<
			User,
			{ id: string; data: Partial<CreateUserRequest> }
		>({
			query: ({ id, data }) => ({
				url: `/${id}`,
				method: "PATCH",
				body: data,
			}),
		}),
		updateSubscribers: builder.mutation<
			User,
			{ id: string; data: Partial<CreateUserRequest> }
		>({
			query: ({ id, data }) => ({
				url: `/subs/${id}`,
				method: "PATCH",
				body: data,
			}),
		}),
		getUserByUsername: builder.query<User, string>({
			query: (username) => `/${username}`,
		}),
		getAllUsers: builder.query<Array<User>, void>({
			query: () => "/",
		}),
		deleteUser: builder.mutation<void, string>({
			query: (id) => ({
				url: `/${id}`,
				method: "DELETE",
			}),
		}),
		updateSelf: builder.mutation<
			User,
			{ id: string; data: Partial<CreateUserRequest> }
		>({
			query: ({ id, data }) => ({
				url: `self/${id}`,
				method: "PATCH",
				body: data,
			}),
		}),
	}),
});

export const {
	useCreateUserMutation,
	useUpdateUserMutation,
	useGetUserByUsernameQuery,
	useGetAllUsersQuery,
	useDeleteUserMutation,
	useUpdateSelfMutation,
	useUpdateSubscribersMutation,
} = userApi;
