import { useFormik, FormikValues } from "formik";

const useCustomForm = <T extends FormikValues>(
	initialValues: T,
	validationSchema: any,
	onSubmit: (event?: React.MouseEvent<HTMLButtonElement>) => void
) => {
	const form = useFormik({
		initialValues,
		validationSchema,
		onSubmit: () => {
			onSubmit();
		},
	});

	return form;
};

export default useCustomForm;
