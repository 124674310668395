export const colors = {
	white: "#fcfcfc",
	grey: "#c3c3c3",
	primaryGreen: "#1f6b45",
	secundaryGree: "#568e72",
	green: "#83ac98",
	deepGreen: "#14452c",
	red: "#ed555c",
	yellow: "#FFFF00",
	orange: "#FFA500",
	greenTransparent: "rgba(131, 172, 152, 0.5)",
};

export default colors;
