import React, { useEffect, useState } from "react";
import { FolderResume } from "../FolderResume/FolderResume";
import FolderProperties from "../FolderProperties/FolderProperties";
import InvoiceDataTable from "../InvoiceData/InvoiceDataTable";
import InvoiceComertial from "../InvoiceComertial/InvoiceComertial";
import NoComertial from "../NoComertialTable/NoComertialTable";
import colors from "../../../ui/colors";
import LiquidationTable from "../Liquidation/LiquidationTable";
import FolderComment from "../../../template/Tables/FolderComment/FolderComment";
import { ClientsTableProps } from "../../../interfaces/clientTableInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getCarpetaData } from "../../../apis/data.api";
import { Carpetas } from "../../../dto/data-clients.request.dto";
import { Grid } from "@mui/material";
import { LoaderDiv } from "../../../styles/stylesLandingPage";
import Loader from "../../Loader/Loader";

export const FolderTable: React.FC<ClientsTableProps> = ({
	UserRegister,
	SelectedCompany,
	SelectedFolder,
}) => {
	const token = useSelector((state: RootState) => state.auth.token);
	const [carpetaData, setCarpetaData] = useState<Carpetas>();
	const [isLoading, setIsLoading] = useState(false);
	const fetchCarpetaData = async () => {
		const CompanyId = String(SelectedCompany);
		const Nro_Carpeta = String(SelectedFolder);

		try {
			setIsLoading(true);
			const dataArray = await getCarpetaData(
				CompanyId,
				Nro_Carpeta,
				token as string
			);
			const actualData = dataArray[0];
			setCarpetaData(actualData);
		} catch (error) {
			console.error("Error fetching carpeta data:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchCarpetaData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) {
		return (
			<LoaderDiv>
				<Loader color={colors.primaryGreen} />
			</LoaderDiv>
		);
	}

	return carpetaData ? (
		<>
			<Grid
				container
				spacing={2}
				sx={{
					width: "96%",
					margin: "auto",
					height: "calc(100vh - 20vh)",
					overflowY: "auto",
					backgroundColor: colors.white,
					"&::-webkit-scrollbar": {
						display: "none",
					},
					msOverflowStyle: "none",
					scrollbarWidth: "none",
				}}
			>
				<Grid item xs={6}>
					<FolderResume FolderData={carpetaData} />
				</Grid>
				<Grid item xs={6}>
					<FolderProperties FolderData={carpetaData} />
				</Grid>
				<Grid item xs={12}>
					<InvoiceDataTable FolderData={carpetaData} />
				</Grid>
				<Grid item xs={12}>
					<InvoiceComertial FolderData={carpetaData} />
				</Grid>
				<Grid item xs={8}>
					<NoComertial FolderData={carpetaData} />
				</Grid>
				<Grid item xs={8}>
					<LiquidationTable FolderData={carpetaData} />
				</Grid>
				<Grid item xs={8}>
					<FolderComment
						FolderNro={carpetaData.Carpetas.Identificacion.Carpeta}
						CompanyName={carpetaData.NombreCliente[0]}
						Writer={UserRegister}
					/>
				</Grid>
			</Grid>
		</>
	) : null;
};
