import { SetStateAction, useState } from "react";
import { useGetAllNewsQuery } from "../apis/news.api";
import { News } from "../models/News";
import { Contact } from "../models/Contact";

export const useButtonHandle = (initialActiveButton = "Empresas") => {
	const [activeButton, setActiveButton] = useState(initialActiveButton);
	const { data: news } = useGetAllNewsQuery();
	const [currentData, setCurrentData] = useState<Contact[] | News[]>([]);

	const handleButton =
		(buttonType: SetStateAction<string>) => async (): Promise<void> => {
			setActiveButton(buttonType);
			let data: Contact[] | News[] = [];
			if (buttonType === "Novedades") {
				if (news) {
					data = news;
				}
			}

			setCurrentData(data);
		};

	return {
		activeButton,
		currentData,
		handleCompany: handleButton("Empresas"),
		handleNews: handleButton("Novedades"),
	};
};

export default useButtonHandle;
