import React, { useState } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import colors from "../../colors";

const StyledFormControl = styled("form")({
	margin: "0",
});

const CustomTextField = styled(TextField)({
	"& .MuiInputBase-root": {
		fontSize: "0.825rem",
	},
});

interface SearchVariables {
	onSearch: (searchTerm: string) => void;
}

export const SearchBar: React.FC<SearchVariables> = ({ onSearch }) => {
	const [search, setSearch] = useState("");

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setSearch(event.target.value);
	};

	const handleKeyPress = (event: React.KeyboardEvent): void => {
		if (event.key === "Enter") {
			event.preventDefault();
			onSearch(search);
		}
	};

	const handleClear = (): void => {
		onSearch("");
		setSearch("");
	};

	return (
		<StyledFormControl onSubmit={(e) => e.preventDefault()}>
			<CustomTextField
				size="small"
				variant="outlined"
				placeholder="Buscar Nombre, Ref.Cliente, Carpeta o DUA"
				name="searcher"
				type="text"
				value={search}
				onChange={handleInputChange}
				onKeyPress={handleKeyPress}
				sx={{ width: "22rem" }}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon sx={{ color: colors.green }} />
						</InputAdornment>
					),
					endAdornment: search && (
						<InputAdornment position="end" onClick={handleClear}>
							<ClearIcon />
						</InputAdornment>
					),
				}}
			/>
		</StyledFormControl>
	);
};

export default SearchBar;
