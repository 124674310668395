import React, { useEffect, useState } from "react";
import * as styles from "../../../styles/stylesLogin";
import MRMLogo from "../../../assets/images/LogoMRM-HD.png";
import MRMname from "../../../assets/images/murillo-HD.png";
import LanguageSelector from "../../../ui/components/LanguageSelector/LanguageSelector";
import Button from "@mui/material/Button";
import Loader from "../../Loader/Loader";
import colors from "../../../ui/colors";

interface LoginSummit {
	Loginhandler: (username: string, password: string) => void;
	errorUser: boolean;
	isLoading: boolean;
	errorPassword: boolean;
	authError: string;
}
export const LoginForm: React.FC<LoginSummit> = ({
	Loginhandler,
	isLoading,
	errorUser,
	errorPassword,
	authError,
}) => {
	const [remember, setRemeber] = useState<boolean>(false);
	const [username, setUserName] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const handleLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const usernameReturn = username;
		const passwordReturn = password;

		Loginhandler(usernameReturn, passwordReturn);
	};

	useEffect(() => {
		if (remember) {
			localStorage.setItem("user", username);
			localStorage.setItem("password", password);
		} else {
			localStorage.removeItem("user");
			localStorage.removeItem("password");
		}
	}, [username, password, remember]);
	return (
		<styles.LoginCard errorBorderColor={authError}>
			<styles.H6>Login</styles.H6>
			<styles.ImageGroup>
				<styles.LogoImg src={MRMLogo} />
				<styles.NameImg src={MRMname} />
			</styles.ImageGroup>

			<styles.LodingCardDiv>
				<styles.InputLabelUser error={errorUser}>
					Nombre de usuario
				</styles.InputLabelUser>
				<styles.TextInputUser
					placeholder="Ingresar usuario"
					name="user"
					type="text"
					value={username}
					onChange={(event) => setUserName(event.target.value)}
					error={errorUser}
				/>

				<styles.InputLabelPassword error={errorPassword}>
					Contraseña
				</styles.InputLabelPassword>
				<styles.TextInputPassword
					placeholder="Ingresar contraseña"
					name="password"
					type="password"
					value={password}
					onChange={(event) => setPassword(event.target.value)}
					error={errorPassword}
				/>
				<styles.LoginButtonDiv>
					{isLoading ? (
						<Loader color={colors.white} />
					) : (
						<Button
							onClick={handleLogin}
							sx={{
								width: "100%",
								height: "100%",
								fontFamily: "Montserrat",
								fontStyle: "normal",
								fontWeight: 500,
								fontSize: "1rem",
								lineHeight: "0.75rem",
								color: colors.white,
								"&:hover": {
									backgroundColor: colors.white,
									border: "0.125rem solid",
									borderColor: colors.primaryGreen,
									color: colors.primaryGreen,
								},
							}}
						>
							Login
						</Button>
					)}
				</styles.LoginButtonDiv>

				<styles.InputLabelCheckbox>Recordarme</styles.InputLabelCheckbox>
				<styles.Checkbox
					type="checkbox"
					checked={remember}
					onChange={(event) => setRemeber(event.target.checked)}
				/>

				<styles.InputLabelLanguage>Idioma</styles.InputLabelLanguage>
				<styles.LanguageSelectorDiv>
					<LanguageSelector />
				</styles.LanguageSelectorDiv>
			</styles.LodingCardDiv>
		</styles.LoginCard>
	);
};

export default LoginForm;
