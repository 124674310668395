import React from "react";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import LandingPage from "../views/Landing.page";
import LoginPage from "../views/Login.page";
import { ProtectedRoute } from "./protected-route.component";
import HomePage from "../views/home.page";
import { LinkPage } from "../views/links.page";
import { CreatingPage } from "../views/Creator.page";

export const Routes: React.FC = () => {
	return (
		<ReactRouterRoutes>
			<Route
				path={"/home"}
				element={
					<ProtectedRoute>
						<LandingPage />
					</ProtectedRoute>
				}
			/>
			<Route
				path={"/create"}
				element={
					<ProtectedRoute>
						<CreatingPage />
					</ProtectedRoute>
				}
			/>

			<Route path={"/"} element={<HomePage />} />
			<Route path={"/links"} element={<LinkPage />} />

			<Route path="/login" element={<LoginPage />} />
		</ReactRouterRoutes>
	);
};

export default Routes;
