/* eslint-disable no-lone-blocks */
import styled from "styled-components";
import colors from "../ui/colors";
interface WrapProps {
	gridArea?: string;
}

export const GridLanding = styled.div`
	display: grid;
	grid-template-columns: 0.7fr repeat(3, 1fr);
	grid-template-rows: 5rem repeat(3, 1fr);
	height: 100%;
`;
export const HeaderGrid = styled.div`
	grid-area: 1 / 1 / 2 / 5;
`;

export const ClientsBarGrid = styled.div`
	grid-area: 2 / 1 / 5 / 2;
	height: 100%;
`;

export const MainAreaGrid = styled.div<WrapProps>`
	grid-area: ${(props) => props.gridArea || "2 / 2 / 5 / 5"};

	background-color: ${colors.white};
`;
export const LoaderDiv = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ClientsTableDiv = styled.div`
	position: relative;
	width: 100%;
	height: 20%;

	@media (max-width: 1100px) {
		margin-top: 2%;
	}
`;
export const P = styled.div`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 0.75rem;
	line-height: 1.375rem;
	grid-area: 1 / 1 / 2 / 2;
	text-align: center;
	color: ${colors.primaryGreen};
	display: flex;
	align-items: center;
`;
export const SelectorDiv = styled.div`
	grid-area: 1 / 2 / 2 / 3;
`;
export const sortByDiv = styled.div`
	grid-template-columns: 0.5fr 0.25fr;
	grid-template-rows: 0.5fr;
	grid-column-gap: 5px;
	grid-row-gap: 0px;
	display: flex;
	align-items: center;
`;

export const HeaderMainArea = styled.div`
	width: 100%;
	height: 9.1%;
	display: flex;
	align-items: center;
	${sortByDiv} {
		position: absolute;
		right: 3%;
		${SelectorDiv} {
			top: 0%;
			left: 47%;
		}
	}
	button:nth-child(1) {
		position: relative;
		left: 2.7%;
	}
	button:nth-child(2) {
		position: relative;
		left: 3.7%;
	}
	button:nth-child(3) {
		position: relative;
		left: 4.7%;
	}

	@media (max-width: 1100px) {
		button:nth-child(3) {
			position: relative;
			top: 60%;
			left: -39%;
		}
	}
`;
export const LandingWrap = styled.div`
	background-color: ${colors.white};
	position: relative;
	height: 100vh;
	width: 100%;
	min-height: 860px;
	min-width: 890px;
	margin: 0;
	padding: 0;
`;

export const Popup = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(240, 240, 240, 0.4);
	z-index: 2;
`;
