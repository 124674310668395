import { Drawer, List, ListItem } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import React, { FC } from "react";

import MRMLogo from "../../../assets/images/LogoMRM-HD.png";

import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../../template/Buttons/ButtonHome";
import colors from "../../colors";
import { StyledLogoImg } from "../AppBar/AppBar";

const StyledMenuTopTextDiv = styled.div`
  display: flex;
  margin: 1.5rem 0 1rem;
  width: 100%;
  justify-content: space-around;
`;

const StyledLine = styled.hr`
  border-top: 3px solid ${colors.deepGreen};
  width: 10rem;
`;

const StyledBurgerMenuButton = styled(Button)`
  width: 4rem !important;

  display: flex !important;
  justify-content: flex-end !important;
`;

const StyledCloseBurgerMenuIcon = styled(MenuIcon)`
  transform: scale(1.8);
  align-self: center;
  justify-self: flex-end;
  font-size: 5rem;
  color: ${colors.deepGreen};
`;

const StyledNav = styled.nav`
  border-left: 1px solid ${colors.deepGreen};
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 2rem 1rem;
`;

const StyledButtonLink = styled(Link)`
  color: ${colors.deepGreen} !important;
  background-color: ${colors.white} !important;
  font-weight: 600;
  padding: 1rem 1rem;
  border-radius: 10px;
  width: 13rem;
  border: 2px solid ${colors.deepGreen};
  font-size: 0.9rem;
  text-decoration: none;
`;

const StyledButtonGreenLink = styled(Link)`
  color: ${colors.white} !important;
  background-color: ${colors.deepGreen} !important;
  font-weight: 600;
  padding: 1rem 1rem;
  border-radius: 10px;
  width: 13rem;
  border: 2px solid ${colors.deepGreen};
  font-size: 0.9rem;
  text-decoration: none;
`;

const StyledList = styled(List)`
  height: 100%;
`;

interface LinksProps {
  onClickSecurityPolicy?: (e: any) => void | undefined;
  onClickQualityPolicy?: (e: any) => void | undefined;
  onClickServiceCompromise?: (e: any) => void | undefined;
}

export const SideNav: FC<LinksProps> = ({
  onClickSecurityPolicy,
  onClickQualityPolicy,
  onClickServiceCompromise,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setMenuOpen(open);
    };

  const getMenuItems = () => (
    <StyledNav onClick={toggleDrawer(false)}>
      <StyledMenuTopTextDiv>
        <StyledLogoImg src={MRMLogo} />
      </StyledMenuTopTextDiv>
      <StyledLine />

      <StyledList>
        <ListItem>
          <StyledButtonLink to="/" onClick={onClickSecurityPolicy}>
            Política de Seguridad
          </StyledButtonLink>
        </ListItem>
        <ListItem>
          <StyledButtonLink to="/" onClick={onClickQualityPolicy}>
            Política de Calidad
          </StyledButtonLink>
        </ListItem>
        <ListItem>
          <StyledButtonLink to="/" onClick={onClickServiceCompromise}>
            Compromiso de Servicio
          </StyledButtonLink>
        </ListItem>
        <ListItem>
          <StyledButtonLink to="/links">Links</StyledButtonLink>
        </ListItem>
      </StyledList>
      <ListItem>
        <StyledButtonGreenLink to="/">Contactanos</StyledButtonGreenLink>
      </ListItem>
    </StyledNav>
  );

  return (
    <div>
      <React.Fragment>
        <StyledBurgerMenuButton onClick={toggleDrawer(true)}>
          <StyledCloseBurgerMenuIcon />
        </StyledBurgerMenuButton>

        <Drawer anchor="right" open={menuOpen} onClose={toggleDrawer(false)}>
          {getMenuItems()}
        </Drawer>
      </React.Fragment>
    </div>
  );
};
