import { Divider, List, ListItem } from "@mui/material";
import styled from "styled-components";
import LinkIcon from "@mui/icons-material/Link";
import colors from "../ui/colors";
import AppBar from "../ui/components/AppBar/AppBar";

const StyledLinksWrapDiv = styled.div`
  width: 100vw;
  max-width: 100vw;

  background-color: ${colors.white};
  font-family: "Montserrat";
  margin: 7.5rem 0 0 0;
  padding: 2rem 4rem;
  @media (max-width: 1000px) {
    padding: 4rem 2rem;
  }
`;

const StyledListItemDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const StyledLinkDiv = styled.div`
  display: flex;
  color: ${colors.white};
  background-color: ${colors.deepGreen};
  padding: 0.5rem 1rem;
  border-radius: 8px;
  align-items: center;
  text-decoration: none;
`;

const StyledLinkP = styled.p`
  color: ${colors.deepGreen};
  text-decoration: none;
`;

const StyledH1 = styled.h1`
  color: ${colors.deepGreen};
  font-size: 3.5rem;
`;

export const LinkPage: React.FC = () => {
  return (
    <>
      <AppBar />

      <StyledLinksWrapDiv>
        <StyledH1>Links de interés</StyledH1>
        <List component="nav" aria-label="mailbox folders">
          <ListItem button>
            <StyledListItemDiv>
              <StyledLinkP>Administración Nacional de Puertos</StyledLinkP>
              <a
                href="https://www.anp.com.uy/"
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkDiv>
                  <LinkIcon /> Link
                </StyledLinkDiv>
              </a>
            </StyledListItemDiv>
          </ListItem>
          <Divider />
          <ListItem divider button>
            <StyledListItemDiv>
              <StyledLinkP>
                TARIFAS Administración Nacional de Puertos
              </StyledLinkP>
              <a
                href="https://www.anp.com.uy/inicio/puertos/montevideo/tarifas"
                rel="noreferrer"
                target="_blank"
              >
                <StyledLinkDiv>
                  <LinkIcon /> Link
                </StyledLinkDiv>
              </a>
            </StyledListItemDiv>
          </ListItem>

          <ListItem divider button>
            <StyledListItemDiv>
              <StyledLinkP>Aeropuerto de Carrasco</StyledLinkP>
              <a
                href="https://aeropuertodecarrasco.com.uy/"
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkDiv>
                  <LinkIcon /> Link
                </StyledLinkDiv>
              </a>
            </StyledListItemDiv>
          </ListItem>
          <ListItem divider button>
            <StyledListItemDiv>
              <StyledLinkP>
                Asociación de Despachantes de Aduana del Uruguay
              </StyledLinkP>
              <a
                href="http://www.adau.com.uy/"
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkDiv>
                  <LinkIcon /> Link
                </StyledLinkDiv>
              </a>
            </StyledListItemDiv>
          </ListItem>
          <ListItem divider button>
            <StyledListItemDiv>
              <StyledLinkP>Dirección Nacional de Aduanas</StyledLinkP>
              <a
                href="https://www.aduanas.gub.uy/"
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkDiv>
                  <LinkIcon /> Link
                </StyledLinkDiv>
              </a>
            </StyledListItemDiv>
          </ListItem>
          <ListItem divider button>
            <StyledListItemDiv>
              <StyledLinkP>
                Ministerio de Ganaderia, Agricultura y Pesca
              </StyledLinkP>
              <a
                href="http://www.chasque.net/dgsa/nuevo/index.htm"
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkDiv>
                  <LinkIcon /> Link
                </StyledLinkDiv>
              </a>
            </StyledListItemDiv>
          </ListItem>
          <ListItem divider button>
            <StyledListItemDiv>
              <StyledLinkP>Laboratorio Tecnologico del Uruguay</StyledLinkP>
              <a
                href="https://www.latu.org.uy/"
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkDiv>
                  <LinkIcon /> Link
                </StyledLinkDiv>
              </a>
            </StyledListItemDiv>
          </ListItem>
          <ListItem divider button>
            <StyledListItemDiv>
              <StyledLinkP>Presidencia de la República</StyledLinkP>
              <a
                href="https://www.gub.uy/presidencia/"
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkDiv>
                  <LinkIcon /> Link
                </StyledLinkDiv>
              </a>
            </StyledListItemDiv>
          </ListItem>
          <Divider light />
        </List>
      </StyledLinksWrapDiv>
    </>
  );
};
