export type Color = {
	dark: string;
	main: string;
	light: string;
	invert: string;
};

export type Palette = {
	primary: Color;
	secondary: Color;
	error: Color;
	warning: Color;
	info: Color;
	success: Color;
	grey: Color;
	blue: Color;
	white: Color;
};

export type Theme = {
	palette: Palette;
	baseSize: number;
	fontFamily: string;
	components: {
		icon: {
			size: {
				small: number;
				medium: number;
				large: number;
			};
		};
		avatar: {
			size: {
				small: number;
				medium: number;
				large: number;
			};
		};
	};
	background: {
		back: string;
		middle: string;
		front: string;
	};
	borderRadius: {
		small: number;
		medium: number;
		large: number;
	};
	breakpoints: {
		sm: number;
		md: number;
		lg: number;
		xl: number;
		xxl: number;
		heightSm: number;
	};
};

export function mapColorToMaterial(color: Color) {
	return {
		dark: color.dark,
		light: color.light,
		main: color.main,
		contrastText: color.invert,
	};
}

export function mapThemeToMaterial(theme: Theme) {
	return {
		palette: {
			//mode: theme.mode,
			primary: mapColorToMaterial(theme.palette.primary),
			secondary: mapColorToMaterial(theme.palette.secondary),
			error: mapColorToMaterial(theme.palette.error),
			warning: mapColorToMaterial(theme.palette.warning),
			info: mapColorToMaterial(theme.palette.info),
			success: mapColorToMaterial(theme.palette.success),
		},
		typography: {
			fontFamily: theme.fontFamily,
		},
	};
}
