import styled from "styled-components";
import colors from "../ui/colors";

export const LoginBackgroundDiv = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${colors.deepGreen};
`;
interface InputUserProps {
	error: boolean;
}
export const TextInputUser = styled.input<InputUserProps>`
	border: ${({ error }) =>
		error ? `1px solid red` : `1px solid ${colors.grey}`};
	border-radius: 0.375rem;
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 6.2%;
	left: 0;
	top: 36.5%;
	padding: 0;
	padding-left: 2.5%;
	background-color: ${colors.white};

	font-family: "Montserrat";
	text-align: left;
	color: ${colors.deepGreen};

	::placeholder {
		background-color: ${colors.white};
		font-style: normal;
		font-weight: 400;
		font-size: 0.75rem;
		line-height: 0.75rem;

		width: 45%;
		height: 55%;
		color: ${colors.grey};
	}
`;
export const TextInputPassword = styled.input<InputUserProps>`
	border: ${({ error }) =>
		error ? `1px solid red` : `1px solid ${colors.grey}`};
	border-radius: 0.375rem;
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 6.2%;
	left: 0;
	top: 49%;
	padding: 0;
	padding-left: 2.5%;
	background-color: ${colors.white};

	font-family: "Montserrat";
	text-align: left;
	color: ${colors.deepGreen};

	::placeholder {
		background-color: ${colors.white};
		font-style: normal;
		font-weight: 400;
		font-size: 0.75rem;
		line-height: 0.75rem;

		width: 60%;
		height: 55%;
		color: ${colors.grey};
	}
`;
export const InputLabelUser = styled.p<InputUserProps>`
	margin: 0;
	padding: 0;
	width: 45%%;
	height: 2.1%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 0.825rem;
	line-height: 0.625rem;
	color: ${({ error }) => (error ? `red` : `${colors.deepGreen}`)};
`;
export const InputLabelPassword = styled.p<InputUserProps>`
	margin: 0;
	width: 20.3%;
	height: 2.1%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 0.825rem;
	line-height: 0.625rem;
	color: ${({ error }) => (error ? `red` : `${colors.deepGreen}`)};
`;
export const InputLabelCheckbox = styled.p`
	width: 19.4%;
	height: 2%;

	font-family: "Montserrat";
	font-style: normal;
	margin-left: 0.25rem;
	font-weight: 400;
	font-size: 0.825rem;
	line-height: 0.57rem;
	color: ${colors.deepGreen};
`;
export const Checkbox = styled.input`
	background-color: ${colors.white};
	box-sizing: border-box;
	border: 1px solid ${colors.grey};
	border-radius: 0.125rem;
	width: 5.3%;
	height: 2.5%;
	margin: 0.25rem 0 0 0;
	padding: 0;
`;

export const InputLabelLanguage = styled.p`
	color: ${colors.deepGreen};
	margin: 0;
	padding: 0;
	width: 12.8%;
	height: 2%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 0.825rem;
	line-height: 0.625rem;
`;
export const LanguageSelectorDiv = styled.div`
	width: 100%;
	height: 6.2%;
	margin: 0;
	padding: 0;
`;
export const LoginButtonDiv = styled.div`
	width: 100%;
	height: 6.4%;
	background-color: ${colors.primaryGreen};
	border-radius: 0.375rem;
	text-align: center;
	margin: 0;
	padding: 0;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 0.75rem;
	color: ${colors.white};

	/* center the text horizontally */
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LodingCardDiv = styled.div`
	position: absolute;
	width: 64%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	${LoginButtonDiv} {
		position: absolute;
		left: 0;
		top: 78%;
	}
	${InputLabelUser} {
		position: absolute;
		left: 0;
		top: 33.61%;
	}
	${InputLabelPassword} {
		position: absolute;
		top: 46%;
	}
	${InputLabelCheckbox} {
		position: absolute;
		text-align: center;
		left: 6.5%;
		top: 56.8%;
	}
	${Checkbox} {
		position: absolute;
		left: 0;
		top: 57.32%;
	}
	${LanguageSelectorDiv} {
		position: absolute;
		margin: 0;
		padding: 0;
		top: 65%;
	}
	${InputLabelLanguage} {
		position: absolute;
		left: 0;
		top: 62%;
	}
`;
export const LogoImg = styled.img`
	width: 42.4%;
	height: 80%;
`;
export const NameImg = styled.img`
	width: 100%;
	height: 14.6%;
`;
export const ImageGroup = styled.div`
	position: absolute;
	width: 40%;
	height: 19.8%;
	align-element: center;
	left: 30%;
	top: 4.12%;

	${LogoImg} {
		position: absolute;
		left: 28.24%;
	}

	${NameImg} {
		position: absolute;
		top: 85.49%;
		left: 0%;
	}
`;

export const H6 = styled.h6`
	position: absolute;
	margin: 0;
	width: 11.5%;
	height: 3.5%;
	left: 44%;
	top: 26.8%;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.05rem;
	color: ${colors.primaryGreen};
`;

export const LoginCard = styled.div<{ errorBorderColor?: string }>`
	position: absolute;
	width: 28rem;
	height: 45rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${colors.white};
	border: 1px solid
		${(props) =>
			props.errorBorderColor ? props.errorBorderColor : colors.white};
	box-shadow: 0.21% 0.21% 0.52% rgba(20, 69, 44, 0.15);
	border-radius: 0.29% 0.29% 0.29% 0.29%;

	@media (min-width: 1700px) {
		width: 35rem;
		height: 55rem;
	}
`;
