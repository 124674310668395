import React from "react";
import styled from "styled-components";
import colors from "../../colors";

import AddressForm from "../../../template/Forms/Address/AddressFrom";
import CompanyForm from "../../../template/Forms/AddCompany/CompanyForm";
import { addOption } from "../../../enum/add-Options";
import NewsForm from "../../../template/Forms/AddNews/AddNews";
import {
	AddCompanySchema,
	AddressSchema,
	AddressInitial,
	NewsInitial,
	AddNewSchema,
} from "../../../schemas/YupValidations";
import useCustomForm from "../../../hooks/useForm";
import { useCreateCompanyMutation } from "../../../apis/company.api";
import { useCreateNewsMutation } from "../../../apis/news.api";
import Loader from "../../../template/Loader/Loader";
import { Toaster, toast } from "sonner";

interface FormProps {
	trigger: boolean;
	setTrigger: (arg0: boolean) => void;
	form: string;
	formType: string;
	Username: string;
}

export const PopupForm: React.FC<FormProps> = ({
	trigger,
	setTrigger,
	form,
	formType,
	Username,
}) => {
	const [createCompany, { isLoading: CompanyLoading }] =
		useCreateCompanyMutation();
	const [createNews, { isLoading: NewsLoading }] = useCreateNewsMutation();

	const Loading = CompanyLoading || NewsLoading;

	const handleNewsChange = (news: Record<string, string>) => {
		Object.entries(news).forEach(([key, value]) => {
			News.setFieldValue(key, value);
		});
	};

	const handleCompanyChange = (newCompany: Record<string, string>) => {
		Object.entries(newCompany).forEach(([key, value]) => {
			Company.setFieldValue(key, value);
		});
	};
	const handleAddressChance = (newAddress: Record<string, string>) => {
		Object.entries(newAddress).forEach(([key, value]) => {
			Address.setFieldValue(key, value);
		});
	};

	const submitCompany = async () => {
		const { values } = Company;
		try {
			const response = await createCompany({
				name: values.nameCompany,
				department: Address.values.department,
				city: Address.values.city,
				state: Address.values.state,
				zip: Address.values.zip,
				country: Address.values.country,
			});
			if ("error" in response) {
				if ("data" in response.error) {
					const data: any = response.error.data;
					if ("message" in data) {
						const errorMessage = JSON.stringify(data.message).replace(
							/[[\]"]/g,
							" "
						);
						toast.error(errorMessage.toUpperCase());
					} else {
						toast.error(`An error occurred: ${response.error}`);
					}
				}
				setTrigger(!CompanyLoading);
				return;
			}
			toast.success("COMPAÑÍA AGREGADA CON ÉXITO");
			setTimeout(() => {
				setTrigger(CompanyLoading);
			}, 1000);
		} catch (error) {
			console.error("Error creating contact:", error);
		}
	};
	const submitNews = async () => {
		let dateObj: Date = new Date();
		let DateComment: string = `${dateObj.getDate()}/${
			dateObj.getMonth() + 1
		}/${dateObj.getFullYear()}`;
		const { values } = News;
		try {
			const response = await createNews({
				ClientName: values.title,
				Content: values.content,
				NewsDate: DateComment,
				Writer: Username,
				Folder: "General",
			});
			if ("error" in response) {
				if ("data" in response.error) {
					const data: any = response.error.data;
					if ("message" in data) {
						const errorMessage = JSON.stringify(data.message).replace(
							/[[\]"]/g,
							" "
						);
						toast.error(errorMessage.toUpperCase());
					} else {
						toast.error(`An error occurred: ${response.error}`);
					}
				}
				setTrigger(!NewsLoading);
				return;
			}
			toast.success("NOVEDAD AGREGADA CON ÉXITO");
			setTimeout(() => {
				setTrigger(NewsLoading);
			}, 1000);
		} catch (error) {
			console.error("Error creating new:", error);
		}
	};
	const submitAddress = () => {};

	const Company = useCustomForm(
		{ nameCompany: "" },
		AddCompanySchema,
		submitCompany
	);
	const Address = useCustomForm(AddressInitial, AddressSchema, submitAddress);
	const News = useCustomForm(NewsInitial, AddNewSchema, submitNews);

	let isNew;
	if (formType === addOption.New) {
		isNew = true;
	}

	const handleSumbit = (e: React.FormEvent) => {
		e.preventDefault();
		if (formType === addOption.Company) {
			submitAddress();
			submitCompany();
		} else if (formType === addOption.New) {
			submitNews();
		}
	};
	return trigger ? (
		<>
			<Toaster position="top-center" richColors />
			<PopupDiv onSubmit={(e) => handleSumbit(e)}>
				<FromWrap>
					<MainForm>
						{formType === addOption.Company && (
							<CompanyForm
								onChange={handleCompanyChange}
								errors={Company.errors}
							/>
						)}
						{formType === addOption.New && (
							<NewsForm onChange={handleNewsChange} errors={News.errors} />
						)}
					</MainForm>

					<AddressWrap>
						{!isNew && <AddressForm onChange={handleAddressChance} />}
					</AddressWrap>
					<br />

					<ButtonsWrap>
						{Loading ? (
							<CenteredDiv>
								<Loader color={colors.deepGreen} />
							</CenteredDiv>
						) : (
							<ButtonPop
								onClick={(e) => {
									handleSumbit(e);
								}}
								type="submit"
								backgroundColor={`${colors.primaryGreen}`}
								textColor={`${colors.white}`}
							>
								{`Agregar ${form}`}
							</ButtonPop>
						)}

						<ButtonPop
							onClick={() => setTrigger(false)}
							backgroundColor={`${colors.white}`}
							textColor={`${colors.primaryGreen}`}
						>
							{`Cancelar`}
						</ButtonPop>
					</ButtonsWrap>
				</FromWrap>
			</PopupDiv>
		</>
	) : (
		<React.Fragment />
	);
};

export default PopupForm;

const CenteredDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 341.2px;
	height: 46.64px;
`;

const ButtonsWrap = styled.div`
	display: grid;
	padding-left: 15%;
	grid-template-columns: 1fr;
	grid-auto-rows: 65px;
	margin-bottom: 5%;
`;

const MainForm = styled.div`
	margin-top: 5%;
	grid-area: 1 / 1 / 2 / 2;
`;

const AddressWrap = styled.div`
	grid-area: 2 / 1 / 3 / 2;
`;

const ButtonPop = styled.button<{
	backgroundColor: string;
	textColor: string;
	boderColor?: string;
}>`
	width: 341.2px;
	height: 46.64px;
	background: ${({ backgroundColor }) => backgroundColor};
	color: ${({ textColor }) => textColor};
	border: 2px solid ${colors.primaryGreen};
	border-radius: 6px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 0.825rem;
	line-height: 18px;

	:hover {
		color: ${({ textColor }) =>
			textColor === colors.white ? colors.primaryGreen : colors.white};
		background: ${({ backgroundColor }) =>
			backgroundColor === colors.primaryGreen
				? colors.white
				: colors.primaryGreen};
		border: 2px solid
			${({ backgroundColor }) =>
				backgroundColor === colors.primaryGreen
					? colors.primaryGreen
					: colors.white};
	}
`;
const FromWrap = styled.div`
	width: 80%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
`;

const PopupDiv = styled.form`
	position: absolute;
	max-width: 520px;
	max-height: 720px;
	min-width: 600px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 37%;
	height: 70%;
	border-radius: 10px;
	background: ${colors.white};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 4px solid ${colors.primaryGreen};
	z-index: 1;
	padding: 2rem;
`;
// eslint-disable-next-line no-lone-blocks
{
	/** 
const handleContactChange = (newContact: Record<string, string>) => {
	Object.entries(newContact).forEach(([key, value]) => {
		Contact.setFieldValue(key, value);
	});
};


	const submitContact = async () => {
		const { values } = Contact;
		try {
			const response = await createContact({
				name: values.name,
				lastname: values.lastName,
				email: values.email,
				department: Address.values.department,
				city: Address.values.city,
				state: Address.values.state,
				zip: Address.values.zip,
				country: Address.values.country,
			});
			if ("error" in response) {
				if ("data" in response.error) {
					const data: any = response.error.data;
					if ("message" in data) {
						const errorMessage = JSON.stringify(data.message).replace(
							/[[\]"]/g,
							" "
						);
						toast.error(errorMessage.toUpperCase());
					} else {
						toast.error(`An error occurred: ${response.error}`);
					}
				}
				setTrigger(!ContactLoading);
				return;
			}
			toast.success("C AGREGADA CON ÉXITO");
			setTrigger(ContactLoading);
		} catch (error) {
			console.error("Error creating contact:", error);
		}
	};

		const Contact = useCustomForm(
		ContactInitial,
		AddContactSchema,
		submitContact
	);
	submitContact();
*/
}
