import styled from "styled-components";
import colors from "../ui/colors";

export const Logoutbutton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	border: none;
	border-radius: 4px;
	text-align: center;
`;

export const InputDiv = styled.div`
	position: absolute;
	width: 100%;
	height: 4.3%;
`;
export const ClientsDiv = styled.div`
	width: 100%;
	height: 70%;
`;
export const LoaderDiv = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Title = styled.h1`
	margin: 0;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	font-size: 1rem;
	line-height: 1.375rem;
	color: ${colors.green};
`;
export const ClientsBarDiv = styled.div`
	position: relative;
	width: 80.5%;
	height: 82.24%;
	top: 3.2%;
	left: 9.7%;
	button:nth-child(1) {
		position: absolute;
		top: 0%;
	}
	button:nth-child(3) {
		position: absolute;
		top: 90%;
	}
	button:nth-child(4) {
		position: absolute;
		top: 96%;
	}

	${InputDiv} {
		position: absolute;
		top: 6%;
	}

	${ClientsDiv} {
		position: absolute;
		top: 15.24%;
		left: 0;
	}

	${Title} {
		position: relative;
		top: 0%;
		display: flex;
		align-items: center;
	}
`;
export const ClientsBarWrapper = styled.div`
	width: 100%;
	height: 100%;
	max-width: 20rem;
	min-width: 15rem;
	background-color: ${colors.primaryGreen};
`;
export const LogoutButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ClientsMapScroll = styled.div`
	overflow-y: auto;
	min-height: 10rem;
	max-height: 80%;

	/* Hide the default scrollbar */
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Customize the scrollbar track color */
	&::-webkit-scrollbar-track {
		background-color: ${colors.primaryGreen};
	}

	/* Customize the scrollbar thumb color */
	&::-webkit-scrollbar-thumb {
		background-color: ${colors.white};
	}

	/* Hide scrollbar buttons (up/down arrows) */
	&::-webkit-scrollbar-button {
		display: none;
	}
`;
