import { useState } from "react";
import { Carpetas } from "../dto/data-clients.request.dto";
type UseSortData = {
	SortFolder: Carpetas[];
	userName: string;
	isUserName: boolean;
	setIsUserName: React.Dispatch<React.SetStateAction<boolean>>;
	isFolder: boolean;
	setisFolder: React.Dispatch<React.SetStateAction<boolean>>;
	folderQuest: string;
	selectorStatus: string;
	setSortFolder: React.Dispatch<React.SetStateAction<Carpetas[]>>;
	setSelectorStatus: React.Dispatch<React.SetStateAction<string>>;
	setuserName: React.Dispatch<React.SetStateAction<string>>;
	setFolderQuest: React.Dispatch<React.SetStateAction<string>>;
};
export const useSortData = (): UseSortData => {
	const [selectorStatus, setSelectorStatus] = useState<string>("");
	const [SortFolder, setSortFolder] = useState<Carpetas[]>([]);
	const [userName, setuserName] = useState<string>("");
	const [isUserName, setIsUserName] = useState<boolean>(false);
	const [isFolder, setisFolder] = useState<boolean>(false);
	const [folderQuest, setFolderQuest] = useState<string>("");

	return {
		SortFolder,
		userName,
		isUserName,
		isFolder,
		folderQuest,
		setuserName,
		setIsUserName,
		selectorStatus,
		setSelectorStatus,
		setisFolder,
		setFolderQuest,
		setSortFolder,
	};
};
