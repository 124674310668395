import { Grid } from "@mui/material";
import styled from "styled-components";
import colors from "../../colors";

const StyledTermOfUseDiv = styled.div`
	margin: 3rem 3rem 0 3rem;
	align-self: center;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const StyledConditionsH3 = styled.h3`
	align-self: center;
	font-size: 1.7rem;
	font-weight: 700;
	color: ${colors.deepGreen};
	max-width: 40rem;
	text-align: center;
	margin: 4rem;
`;

const StyledGreenBackgroundCardDiv = styled.div`
	background-color: ${colors.deepGreen};
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 1rem 1.5rem;
	border-radius: 8px;
`;

const StyledCardP = styled.p`
	color: ${colors.white};
	font-size: 1rem;
	align-self: flex-end;
	font-weight: 500;
`;

export const TermsOfUse: React.FC = () => {
	return (
		<StyledTermOfUseDiv>
			<StyledConditionsH3>
				Nuestras condiciones de servicios son las siguientes
			</StyledConditionsH3>
			<Grid container spacing={3}>
				<Grid
					sx={{
						display: "flex",
						alignItems: "flex-end",
					}}
					item
					lg={3}
				>
					<StyledGreenBackgroundCardDiv>
						<StyledCardP>
							Realizamos los trámites y gestiones de acuerdo a lo establecido
							por la normativa vigente.
						</StyledCardP>
					</StyledGreenBackgroundCardDiv>
				</Grid>
				<Grid
					sx={{
						display: "flex",
						alignItems: "flex-end",
					}}
					item
					lg={3}
				>
					<StyledGreenBackgroundCardDiv>
						<StyledCardP>
							Mantenemos a nuestros Clientes permanentemente informados acerca
							del avance de sus trámites y les advertimos de cualquier
							circunstancia que los pudiera afectar.
						</StyledCardP>
					</StyledGreenBackgroundCardDiv>
				</Grid>
				<Grid
					sx={{
						display: "flex",
						alignItems: "flex-end",
					}}
					item
					lg={3}
				>
					<StyledGreenBackgroundCardDiv>
						<StyledCardP>
							Preservamos la integridad, seguridad y confidencialidad de la
							documentación entregada por nuestros Clientes.
						</StyledCardP>
					</StyledGreenBackgroundCardDiv>
				</Grid>
				<Grid
					sx={{
						display: "flex",
						alignItems: "flex-end",
					}}
					item
					lg={3}
				>
					<StyledGreenBackgroundCardDiv>
						<StyledCardP>
							Atendemos a los Clientes de lunes a viernes de 09.00 a 19.00
							Horas, no obstante lo cual nos mantenemos disponibles a través del
							teléfono 098.000551.{" "}
						</StyledCardP>
					</StyledGreenBackgroundCardDiv>
				</Grid>
				<Grid item lg={3}>
					<StyledGreenBackgroundCardDiv>
						<StyledCardP>
							Mantenemos informados a nuestros Clientes sobre novedades en
							materia de normativas aduaneras y de Comercio Exterior.{" "}
						</StyledCardP>
					</StyledGreenBackgroundCardDiv>
				</Grid>
				<Grid item lg={3}>
					<StyledGreenBackgroundCardDiv>
						<StyledCardP>
							No nos responsabilizamos por atrasos imputables a la DNA u otros
							organismos involucrados, así como tampoco por inconvenientes
							atribuibles a la documentación presentada por nuestros Clientes.{" "}
						</StyledCardP>
					</StyledGreenBackgroundCardDiv>
				</Grid>
				<Grid item lg={3}>
					<StyledGreenBackgroundCardDiv>
						<StyledCardP>
							Entregamos a nuestros Clientes las previsiones de costos que nos
							son solicitadas en un plazo no superior a 24 horas. Aseguramos que
							dichas previsiones no sufrirán modificaciones salvo por razones no
							imputables a MRM.{" "}
						</StyledCardP>
					</StyledGreenBackgroundCardDiv>
				</Grid>
				<Grid item lg={3}>
					<StyledGreenBackgroundCardDiv>
						<StyledCardP>
							Brindamos a todos nuestros Clientes que así lo requieran,
							asesoramiento integral en Comercio Internacional y normativas
							aduaneras.{" "}
						</StyledCardP>
					</StyledGreenBackgroundCardDiv>
				</Grid>
			</Grid>
		</StyledTermOfUseDiv>
	);
};
