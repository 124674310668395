import React from "react";
import * as style from "../../../styles/stylesLandingHeader";
import MRMLogo from "../../../assets/images/LogoMRM-HD.png";
import MRMname from "../../../assets/images/murillo-HD.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import AddNewElement from "../NewElement/AddNewElement";
import SearchBar from "../SearchBar/SearchBar";
import colors from "../../colors";

interface HeaderProps {
	OnClick?: () => void;
	PopAdd: (AddSelection: string) => void;
	onSearch: (searchTerm: string) => void;
}
export const Header: React.FC<HeaderProps> = ({
	OnClick,
	PopAdd,
	onSearch,
}) => {
	const handleAddSelector = (AddSelection: string) => {
		PopAdd(AddSelection);
	};
	const handleSearch = (searchValue: string) => {
		onSearch(searchValue);
	};
	return (
		<style.LandingHeaderDiv>
			<style.LogoHeadingDiv>
				<style.LogoImg src={MRMLogo} />
				<style.NameImg src={MRMname} />
			</style.LogoHeadingDiv>

			<style.MenuButton onClick={OnClick}>
				<MenuRoundedIcon sx={{ color: colors.deepGreen, fontSize: "2.5rem" }} />
			</style.MenuButton>
			<style.WrapperDiv>
				<AddNewElement onChange={handleAddSelector} />
				<SearchBar
					onSearch={(searchTerm) => {
						handleSearch(searchTerm);
					}}
				/>
			</style.WrapperDiv>
		</style.LandingHeaderDiv>
	);
};

export default Header;
