import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Company } from "../models/Company";
import { CreateCompanyRequest } from "../dto/create-company-request.dto";

export const companiesApi = createApi({
	reducerPath: "companiesAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: `/companies`,
	}),
	endpoints: (build) => ({
		getAllCompanies: build.query<Company[], void>({
			query: () => "/",
		}),
		createCompany: build.mutation<Company, CreateCompanyRequest>({
			query: (createCompanyRequest) => ({
				url: "/",
				method: "POST",
				body: createCompanyRequest,
			}),
		}),
	}),
});

export const { useGetAllCompaniesQuery, useCreateCompanyMutation } =
	companiesApi;
