import React, { useRef } from "react";
import AppBar from "../ui/components/AppBar/AppBar";
import styled from "styled-components";
import colors from "../ui/colors";
import { Button } from "../template/Buttons/ButtonHome";
import { Link } from "react-router-dom";
import TeamMembers from "../assets/images/team.png";
import Office2 from "../assets/images/office2.png";
import Office from "../assets/images/office.png";
import { Grid } from "@mui/material";
import Footer from "../ui/components/Footer/Footer";
import { LandingLogos } from "../ui/Logos/LandingLogos";
import { TermsOfUse } from "../ui/components/termsOfUse/TermsOfUse";
import { SecurityPolicy } from "../ui/components/securityPolicy/SecurityPolicy";
import unit from "../assets/images/unit.png";
import { Carousel } from "../ui/components/carousel/Carousel";

const StyledWrapperDiv = styled.div`
	display: flex;
	width: calc(100% -3rem);
	margin: 7.5rem 3rem 0 3rem;
	align-items: center;
	height: calc(100vh - 7rem);
	background-color: ${colors.deepGreen};
	padding: 0 9.5rem;
	justify-content: space-between;
	border-radius: 15px;
	@media (max-width: 1400px) {
		padding: 2rem 4rem;
	}
	@media (max-width: 1200px) {
		padding: 2rem;
	}
	@media (max-width: 1000px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 7.5rem 2rem 0 2rem;
		padding: 1rem 1rem 4rem;
	}
	@media (max-width: 600px) {
		height: fit-content;
		margin: 7.5rem 1rem 3rem 1rem;
	}
`;

const StyledHeaderDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media (max-width: 1400px) {
		justify-content: center;

		height: 100%;
	}
	@media (max-width: 1200px) {
		padding: 0;
	}
	@media (max-width: 1000px) {
		align-items: center;
		width: 100%;
		padding: 1rem;
	}
	@media (max-width: 600px) {
		align-items: center;
		width: 100%;
		padding: 0;
	}
`;

const StyledwhiteHeadingH1 = styled.h1`
	color: ${colors.white};
	font-size: 3.3rem;
	padding: 0 2rem 0 0;
	font-weight: 700;

	@media (max-width: 1400px) {
		font-size: 3rem;
	}
	@media (max-width: 1000px) {
		text-align: center;
		padding: 2rem 0 0 0;
	}
	@media (max-width: 600px) {
		font-size: 2rem;
		text-align: center;
	}
`;

const StyledwhiteHeadingH2 = styled.h2`
	color: ${colors.white};
	font-size: 3.7rem;
	font-weight: 700;
	max-width: 30rem;
	@media (max-width: 800px) {
		text-align: center;
	}
	@media (max-width: 600px) {
		font-size: 3rem;
	}
	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
`;

const StyledButton = styled(Button)`
	color: ${colors.deepGreen} !important;
	background-color: ${colors.white} !important;
	font-weight: 600 !important;
	padding: 1rem 3rem !important;
	font-size: 1rem !important;
	@media (max-width: 600px) {
		padding: 1rem !important;
	}
`;

const StyledButtonLink = styled(Link)`
	color: ${colors.deepGreen} !important;
	background-color: ${colors.white} !important;
	font-weight: 600;
	padding: 1rem 2.5rem;
	margin: 1rem;
	border-radius: 6px;
	font-size: 1rem;
	text-decoration: none;
`;

const StyledWrapperLinksDiv = styled.div`
	padding: 1.5rem;
	background-color: ${colors.green};
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 25rem;
	border-radius: 15px;
	margin: -10rem 0 0 10rem;
	@media (max-width: 1400px) {
		margin: -5rem 0px 0 5rem;
	}
	@media (max-width: 1200px) {
		margin: -12rem 0px 8rem 5rem;
	}
	@media (max-width: 1000px) {
		margin: -2rem 0 0 3rem;
	}
	@media (max-width: 600px) {
		padding: 1rem;
		max-width: 20rem;
		margin: -3rem 0 0 3rem;
		align-self: center;
	}
	@media (max-width: 450px) {
		max-width: 15rem;
		margin: -5rem 0 0 2rem;
	}
`;

const StyledLandingWrapDiv = styled.div`
	width: 100vw;
	max-width: 100vw;
	overflow-x: hidden;
	background-color: white;
	font-family: "Montserrat";
`;

const StyledBorderGreenDiv = styled.div`
	width: 70rem;
	height: 26rem;
	margin: -10rem 0 0 47rem;
	border: 2px solid ${colors.green};
	overflow: hidden;
	border-radius: 15px;
	z-index: 10;
	@media (max-width: 1400px) {
		margin: -5rem 0px 0 5rem;
	}
	@media (max-width: 1200px) {
		margin: -18rem 0px 0px 10rem;
	}
`;

const StyledTeamImg = styled.img`
	border-radius: 30px;

	height: 100%;

	@media (max-width: 600px) {
		height: 20rem;
	}
`;

const StyledH2 = styled.h2`
	color: ${colors.deepGreen};
	font-size: 3.3rem;
	font-weight: 700;
	margin: 0;
	@media (max-width: 1400px) {
		font-size: 3.2rem;
		max-width: 100%;
	}
	@media (max-width: 1000px) {
		font-size: 3rem;
		align-self: center;
	}
	@media (max-width: 800px) {
		text-align: center;
		max-width: 100%;
	}
	@media (max-width: 450px) {
		font-size: 1.8rem;
	}
`;

const StyledServiceP = styled.p`
	color: ${colors.deepGreen};
	font-size: 1.1rem;
	@media (max-width: 1400px) {
		font-size: 1rem;
		max-width: 100%;
	}
	@media (max-width: 1000px) {
		font-size: 1.1rem;
	}
`;
const StyledP = styled.p`
	color: ${colors.deepGreen};
	font-size: 1.1rem;
	max-width: 80%;
	line-height: 1.2;
	@media (max-width: 1200px) {
		max-width: 100%;
	}
	@media (max-width: 800px) {
		font-size: 1rem;
		text-align: center;
	}
	@media (max-width: 450px) {
		font-size: 0.8rem;
	}
`;
const StyledCardP = styled.p`
	color: ${colors.white};
	font-size: 1rem;
	align-self: flex-end;
	font-weight: 500;
	line-height: 1.4;
	max-width: 70%;
	@media (max-width: 1200px) {
		max-width: 100%;
		line-height: 1.2;
	}
`;

const StyledTeamDiv = styled.div`
	margin: -10rem 3rem 0 3rem;
	display: flex;
	@media (max-width: 1000px) {
		margin: -2rem 2rem 0 2rem;
	}
	@media (max-width: 800px) {
		flex-direction: column;
		align-items: center;
		height: fit-content;
	}
	@media (max-width: 600px) {
		margin: 0 1rem 0 1rem;
	}
`;

const StyledTeamTextDiv = styled.div`
	width: fit-content;
	flex-direction: column;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	margin-bottom: 1rem;
	height: 100%;

	@media (max-width: 800px) {
		align-items: center;
	}
`;

const StyledQualityDiv = styled.div`
	background-color: ${colors.green};
	padding: 2.5rem;
	border-radius: 30px;
	border-top: 3.3rem solid white;
	height: calc(100% - 5rem);
	margin-left: 4rem;
	margin-top: -3rem;
	@media (max-width: 1400px) {
		margin-left: 2rem;
		height: calc(100% - 2rem);
		padding: 1rem 1.5rem;
	}
	@media (max-width: 1000px) {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 2rem;
		padding: 1rem 1.5rem;
	}
	@media (max-width: 600px) {
		margin: 1.5rem 0;
		border-top: 10px solid ${colors.deepGreen};
	}
`;

const StyledServiceDiv = styled.div`
	border-radius: 30px;
	max-height: 100%;
	align-self: flex-end;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;
	width: 100%;
	@media (max-width: 1000px) {
		margin: 2rem 0;
	}
`;

const StyledQualityWrapper1Div = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin: 0 3rem;
	margin-bottom: 2rem;
	max-height: 60rem;
	@media (max-width: 1000px) {
		flex-direction: column;
		align-items: center;
	}
	@media (max-width: 600px) {
		margin: 1rem 1rem;
		max-height: fit-content;
	}
`;

const StyledContainerImg = styled.img`
	padding-top: 1rem;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	@media (max-width: 500px) {
		padding-top: 0;
	}
`;

const StyledContainer2Img = styled.img`
	height: 100%;
	width: 100%;
	border-radius: 20px;
	align-self: flex-end;
`;

const StyledTeamImgDiv = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
	border-radius: 30px;
	max-height: 100%;
	@media (max-width: 800px) {
		max-width: 100%;
		overflow: hidden;
		max-height: fit-content;
	}
`;

const StyledContainerImgDiv = styled.div`
	border-radius: 35px;
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	@media (max-width: 1400px) {
		height: 100%;
		padding: 0;
	}
`;

const StyledContainer2ImgDiv = styled.div`
	border-radius: 25px;
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	@media (max-width: 1400px) {
		height: 100%;
	}
	@media (max-width: 1000px) {
		width: 100%;
	}
`;

const StyledGrid = styled(Grid)`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
`;

const StyledWrapperCardDiv = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	@media (max-width: 450px) {
		flex-direction: column;
		align-items: center;
	}
`;

export const HomePage: React.FC = () => {
	const sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {
		serviceCompromise: useRef<HTMLDivElement>(null),
		securityPolicy: useRef<HTMLDivElement>(null),
		qualityPolicy: useRef<HTMLDivElement>(null),
	};
	const handleScrollToSection = (section: string) => {
		const targetRef = sectionRefs[section];

		if (targetRef.current) {
			window.scrollTo({
				top: targetRef.current.offsetTop,
				behavior: "smooth",
			});
		}
	};

	const handleLinkClick = (
		section: string,
		event: React.MouseEvent<HTMLAnchorElement>
	) => {
		event.preventDefault();
		handleScrollToSection(section);
	};
	return (
		<StyledLandingWrapDiv>
			<AppBar
				onClickSecurityPolicy={(e: any) => handleLinkClick("securityPolicy", e)}
				onClickQualityPolicy={(e: any) => handleLinkClick("qualityPolicy", e)}
				onClickServiceCompromise={(e: any) =>
					handleLinkClick("serviceCompromise", e)
				}
			/>
			<StyledWrapperDiv>
				<Grid container spacing={2}>
					<Grid item sm={12} md={6} lg={5} xl={5}>
						<StyledHeaderDiv>
							<StyledwhiteHeadingH1>
								60 Años de ininterrumpida trayectoria.
							</StyledwhiteHeadingH1>
							<StyledButton variant="filled">Ver más</StyledButton>
						</StyledHeaderDiv>
					</Grid>
					<Grid item sm={12} md={6} lg={7} xl={7}>
						<Carousel />
					</Grid>
				</Grid>
			</StyledWrapperDiv>
			<StyledWrapperLinksDiv>
				<StyledButtonLink
					to=""
					onClick={(e) => handleLinkClick("securityPolicy", e)}
				>
					Política de Seguridad
				</StyledButtonLink>
				<StyledButtonLink
					to=""
					onClick={(e) => handleLinkClick("qualityPolicy", e)}
				>
					Política de Calidad
				</StyledButtonLink>
				<StyledButtonLink
					to=""
					onClick={(e) => handleLinkClick("serviceCompromise", e)}
				>
					Compromiso de Servicio
				</StyledButtonLink>
				{/**Need to change this, I guess */}
				<StyledButtonLink to="/links">Links</StyledButtonLink>{" "}
			</StyledWrapperLinksDiv>
			<StyledBorderGreenDiv></StyledBorderGreenDiv>
			<StyledTeamDiv>
				<Grid container spacing={3}>
					<Grid sx={{ maxWidth: "100%" }} item sm={12} md={6} lg={7}>
						<StyledTeamImgDiv>
							<StyledTeamImg src={TeamMembers} />
						</StyledTeamImgDiv>
					</Grid>
					<Grid item sm={12} md={6} lg={5}>
						<StyledTeamTextDiv>
							<StyledH2>Quienes Somos</StyledH2>
							<StyledP>
								Somos una empresa especializada en desaduanamiento de mercadería
								y logística desde 1963. Brindamos servicios profesionales en
								operaciones aduaneras y portuarias, como importación,
								exportación, tránsito y zonas francas. Mantenemos a nuestros
								clientes informados y protegemos la integridad de su
								documentación.
							</StyledP>
						</StyledTeamTextDiv>
					</Grid>
				</Grid>
			</StyledTeamDiv>
			<div ref={sectionRefs.securityPolicy}>
				<SecurityPolicy />
			</div>
			<StyledQualityWrapper1Div ref={sectionRefs.qualityPolicy}>
				<Grid container spacing={6}>
					<Grid item md={6} xl={6}>
						<StyledQualityDiv>
							<StyledwhiteHeadingH2>Políticas de calidad</StyledwhiteHeadingH2>
							<StyledWrapperCardDiv>
								<StyledCardP>
									Buscamos brindar a nuestros clientes un servicio de calidad,
									oportuno y adaptado a sus necesidades. Nos esforzamos por
									mejorar continuamente para asegurar su satisfacción. Contamos
									con un equipo profesional y responsable que utiliza procesos
									automatizados y toma decisiones informadas. Nos enfocamos en
									crecer constantemente, optimizando procesos, aplicando
									tecnología y capacitando a nuestro personal. Mantenemos
									actualizados en las normativas de comercio exterior para
									ofrecer un servicio confiable y cumplir con las regulaciones
									legales.
								</StyledCardP>
								<img src={unit} alt="unit" />
							</StyledWrapperCardDiv>
						</StyledQualityDiv>
					</Grid>
					<Grid item sm={12} md={6} xl={6}>
						<StyledContainerImgDiv>
							<StyledContainerImg src={Office} />
						</StyledContainerImgDiv>
					</Grid>
				</Grid>
			</StyledQualityWrapper1Div>
			<StyledQualityWrapper1Div>
				<Grid container spacing={6}>
					<Grid item sm={12} md={6} xl={6}>
						<StyledContainer2ImgDiv>
							<StyledContainer2Img src={Office2} />
						</StyledContainer2ImgDiv>
					</Grid>

					<StyledGrid ref={sectionRefs.serviceCompromise} item md={6} xl={6}>
						<StyledServiceDiv ref={sectionRefs.serviceCompromise}>
							<StyledH2>Compromiso de servicio</StyledH2>
							<StyledServiceP>
								<small>
									Murillo Ruffinelli Moreira SRL es una empresa especializada en
									operaciones de importación, exportación y tránsito en el
									ámbito del Comercio Exterior. También ofrecemos asesoramiento
									integral en temas de Comercio Internacional, Tratados
									Internacionales y Normas de Origen.
								</small>
							</StyledServiceP>
							<StyledServiceP>
								<small>
									Como empresa familiar dirigida por la familia Moreira,
									heredamos de nuestro padre Hugo Moreira, uno de los
									fundadores, el compromiso con la profesión, la dedicación al
									trabajo y el respeto hacia nuestros clientes. Tratamos todos
									los negocios como importantes, sin importar su categoría, y
									buscamos la excelencia para satisfacer sus necesidades.
								</small>
							</StyledServiceP>
							<StyledServiceP>
								<small>
									En línea con nuestra Política de Calidad y nuestro Sistema de
									Gestión de Calidad basado en la Norma UNIT-ISO 9001:2008,
									queremos informar a nuestros clientes sobre las condiciones
									que rigen nuestros servicios en MRM.
								</small>
							</StyledServiceP>
						</StyledServiceDiv>
					</StyledGrid>
				</Grid>
			</StyledQualityWrapper1Div>
			<TermsOfUse />
			<LandingLogos />
			<Footer />
		</StyledLandingWrapDiv>
	);
};

export default HomePage;
