import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import * as style from "../styles/stylesLandingPage";
import Header from "../ui/components/LandingHeader/LandingHeader";
import PopupTrigger from "../ui/components/PopupTrigger/popupTrigger";
import useHeader from "../hooks/useHeader";
import usePopup from "../hooks/usePopup";
import CreatorMain from "../ui/components/CreatorMain/CreatorMain";

export const CreatingPage: React.FC = () => {
	const [search, setSearch] = useState("");

	const { openPopup, setopenPopUp, handdleForm, sethanddleForm, AddNewReturn } =
		usePopup();
	const { isHeaderActivated, handleHeader } = useHeader();

	const location = useLocation();
	const userInfo = location.state?.userInfo;

	return (
		<style.LandingWrap>
			<style.GridLanding>
				<PopupTrigger
					openPopup={openPopup}
					setopenPopUp={setopenPopUp}
					handdleForm={handdleForm}
					sethanddleForm={sethanddleForm}
					Username={userInfo.username}
				/>
				<style.HeaderGrid>
					<Header
						OnClick={handleHeader}
						PopAdd={AddNewReturn}
						onSearch={(searchTerm) => {
							setSearch(searchTerm);
						}}
					/>
				</style.HeaderGrid>

				<style.MainAreaGrid
					gridArea={!isHeaderActivated ? "2 / 2 / 5 / 5" : "2 / 1 / 5 / 5"}
				>
					<CreatorMain />
				</style.MainAreaGrid>
			</style.GridLanding>
		</style.LandingWrap>
	);
};

export default CreatingPage;
