import React from "react";
import { ButtonComponent } from "../../../template/Buttons/SimpleButton/Button";
import { UserOptions } from "../../../enum/user-Option";
import colors from "../../colors";
import SelectorMui from "../../../template/SelectorMui/SelectorMui";
import DateRangeSelector from "../../../template/DateRangeSelector/DateRangeSelector";
import { Grid, Box } from "@mui/material";

interface HeaderMainAreaProps {
	Role: string;
	activeButton: string;
	handleCompany: () => void;
	handleNews: () => void;
	selectorStatus: string;
	handleSortSelector: (value: string) => void;
	onDateFilter: (fromDate: string, toDate: string) => void;
	isFolder: boolean;
	singleTable: boolean;
}

const HeaderMainAreaComponent: React.FC<HeaderMainAreaProps> = ({
	activeButton,
	handleCompany,
	handleSortSelector,
	handleNews,
	onDateFilter,
	isFolder,
	Role,
	singleTable,
}) => {
	const roleClient = Role === "Cliente";
	return (
		<Box sx={{ flexGrow: 1, margin: 2.5 }}>
			<Grid container spacing={2}>
				<Grid item xs={5} md={3} lg={2.25} xl={1.5}>
					<ButtonComponent
						backgroundColor={
							activeButton === UserOptions.Company
								? colors.primaryGreen
								: colors.white
						}
						textColor={
							activeButton === UserOptions.Company
								? colors.white
								: colors.primaryGreen
						}
						buttonLabel={UserOptions.Company}
						OnClick={handleCompany}
					/>
				</Grid>
				<Grid item xs={12} md={10.25} lg={6} xl={8}>
					{!roleClient && (
						<ButtonComponent
							backgroundColor={
								activeButton === UserOptions.News
									? colors.primaryGreen
									: colors.white
							}
							textColor={
								activeButton === UserOptions.News
									? colors.white
									: colors.primaryGreen
							}
							buttonLabel={UserOptions.News}
							OnClick={handleNews}
						/>
					)}
				</Grid>

				{singleTable ? (
					<>
						<Grid item xs={1} md={1}>
							<SelectorMui
								Options={[
									"Fecha Numerado +",
									"Fecha Numerado -",
									"Ref Cliente",
									"Carpetas",
								]}
								onChange={handleSortSelector}
							/>
						</Grid>
					</>
				) : (
					!isFolder && (
						<>
							<Grid item xs={2.5} md={2.25} lg={1.5} xl={1.05}>
								<DateRangeSelector onDateFilter={onDateFilter} />
							</Grid>
							<Grid item xs={1} md={1} lg={1} xl={1}>
								<SelectorMui
									Options={["Fecha +", "Fecha -", "Nombre", "Carpeta"]}
									onChange={handleSortSelector}
								/>
							</Grid>
						</>
					)
				)}
			</Grid>
		</Box>
	);
};

export default HeaderMainAreaComponent;
