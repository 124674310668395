export enum Aduana {
	MONTEVIDEO = 1,
	CARRASCO = 2,
	CHUY = 3,
	RIO_BRANCO = 4,
	RIVERA = 5,
	FRAY_BENTOS = 6,
	COLONIA = 7,
	PUNTA_DEL_ESTE = 8,
	ACEGUA = 9,
	ARTIGAS = 10,
	BELLA_UNION = 11,
	SALTO = 12,
	PAYSANDU = 13,
	NUEVA_PALMIRA = 14,
	NO_USAR_Z_FRANCA_MONTEVIDEO = 22,
	PUERTO_JUAN_LACAZE = 23,
	M_BOPICUA = 24,
	CARMELO = 25,
	ROCHA = 30,
}
