import { createTheme, ThemeProvider } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { FC, useMemo } from "react";
import {
	InputVariantProp,
	mapVariantToMaterialButton,
} from "./buttons.helpers";
import { Color, mapColorToMaterial } from "../../helpers/theme.helpers";
import { isColor } from "../../helpers/type.helpers";

export type ColorProp =
	| Color
	| "primary"
	| "secondary"
	| "success"
	| "error"
	| "info"
	| "warning";

export type ButtonProps = Omit<
	React.HTMLProps<HTMLButtonElement>,
	"type" | "color"
> & {
	variant?: InputVariantProp;
	color?: ColorProp;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	className?: string;
	fullWidth?: boolean;
	disabled?: boolean;
	component?: string;
	type?: "button" | "submit" | "reset";
};

const MuiColorButton: FC<Omit<ButtonProps, "color"> & { color: Color }> = ({
	children,
	onClick,
	className,
	variant,
	color,
	fullWidth,
	disabled,
	type,
}) => {
	const theme = useMemo(() => {
		return createTheme({
			palette: {
				primary: mapColorToMaterial(color),
			},
		});
	}, [color]);

	return (
		<ThemeProvider theme={theme}>
			<MuiButton
				variant={mapVariantToMaterialButton(variant)}
				onClick={onClick}
				className={className}
				fullWidth={fullWidth}
				disabled={disabled}
				color="primary"
				type={type}
			>
				{children}
			</MuiButton>
		</ThemeProvider>
	);
};

export const Button: FC<ButtonProps> = ({
	children,
	onClick,
	className,
	variant = "default",
	color,
	fullWidth = false,
	disabled = false,
	component,
	type,
}) => {
	if (isColor(color)) {
		return (
			<MuiColorButton
				variant={variant}
				onClick={onClick}
				className={className}
				fullWidth={fullWidth}
				disabled={disabled}
				type={type}
				color={color}
				component={component}
			>
				{children}
			</MuiColorButton>
		);
	}

	return (
		<MuiButton
			variant={mapVariantToMaterialButton(variant)}
			onClick={onClick}
			className={className}
			fullWidth={fullWidth}
			disabled={disabled}
			color={color}
			type={type}
		>
			{children}
		</MuiButton>
	);
};
