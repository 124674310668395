import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import colors from "../../../ui/colors";
import styled from "styled-components";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	Box,
} from "@mui/material";
import { useUpdateSubscribersMutation } from "../../../apis/users.api";
import { CreationDataClients } from "../../../models/Data";
import { User } from "../../../models/User";
import { useSelector } from "react-redux";
import { getAllSubscribeData } from "../../../apis/data.api";
import { RootState } from "../../../app/store";

interface SubscriptionsProps {
	openForm: boolean;
	setOpenFrom: React.Dispatch<React.SetStateAction<boolean>>;
	UsersInfo: Array<User> | undefined;
}

export const AddSubscriptions: React.FC<SubscriptionsProps> = ({
	openForm,
	setOpenFrom,
	UsersInfo,
}) => {
	const token = useSelector((state: RootState) => state.auth.token);
	const [updateSubscribers] = useUpdateSubscribersMutation();
	const [companyToSubscribe, setCompanyToSubscribe] = useState<string>("");
	const [subscriptor, setSubscriptor] = useState<string>("");

	const [subscribeData, setSubscribeData] = useState<CreationDataClients[]>([]);

	const fetchSubscribedData = async () => {
		try {
			const data = await getAllSubscribeData(token as string);
			setSubscribeData(data);
		} catch (error) {
			console.error(error);
		} finally {
		}
	};

	useEffect(() => {
		fetchSubscribedData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubscriptor = async () => {
		const SubscriptorCreation = {
			subscriptions: [
				{
					companyid: companyToSubscribe,
					folders: [""],
				},
			],
		};

		const selectedUser = UsersInfo?.find(
			(user) => user.username === subscriptor
		);

		if (selectedUser) {
			try {
				await updateSubscribers({
					id: selectedUser._id,
					data: SubscriptorCreation,
				});
			} catch (error) {
				console.error(error);
			}
		}
		setOpenFrom(false);
	};
	const handleChange = (event: SelectChangeEvent) => {
		const selectedUsername = event.target.value as string;
		setSubscriptor(selectedUsername);
	};
	const handleChangeCompany = (event: SelectChangeEvent) => {
		const selectedCompany = event.target.value as string;
		setCompanyToSubscribe(selectedCompany);
	};
	return subscribeData ? (
		<>
			<Dialog
				open={openForm}
				onClose={handleSubscriptor}
				PaperProps={{
					style: {
						borderColor: colors.primaryGreen,
						borderWidth: 4,
						borderStyle: "solid",
						width: 400,
						height: 400,
					},
				}}
			>
				<Title>Agregar Subscriptor</Title>
				<DialogContent>
					<DialogContentText sx={{ paddingBottom: 2 }}>
						Selecciona usuario a subscribir. El usuario selecionado podrá
						accerder a la toda la información del cliente.
					</DialogContentText>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">Usuario</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={subscriptor}
							label="subscriptor"
							onChange={handleChange}
						>
							{UsersInfo?.filter((user) => user.role.includes("Cliente")).map(
								(user, index) => (
									<MenuItem value={user.username} key={index}>
										{user.username}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
					<Box sx={{ marginTop: 2.5 }}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Subscriptor</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={companyToSubscribe}
								label="Company"
								onChange={handleChangeCompany}
							>
								{subscribeData.map((Client, index) => (
									<MenuItem value={Client._id} key={index}>
										{Client.NombreCliente[0]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleSubscriptor}
						disableRipple
						sx={{
							fontFamily: "Montserrat",
							fontStyle: "normal",
							fontWeight: 500,
							fontSize: "0.75rem",
							lineHeight: "0.75rem",
							color: colors.white,
							backgroundColor: colors.primaryGreen,
							border: "0.125rem solid",
							"&:hover": {
								backgroundColor: colors.white,
								border: "0.125rem solid",
								borderColor: colors.primaryGreen,
								color: colors.primaryGreen,
							},
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={handleSubscriptor}
						disableRipple
						sx={{
							fontFamily: "Montserrat",
							fontStyle: "normal",
							fontWeight: 500,
							fontSize: "0.75rem",
							lineHeight: "0.75rem",
							color: colors.white,
							backgroundColor: colors.primaryGreen,
							border: "0.125rem solid",
							"&:hover": {
								backgroundColor: colors.white,
								border: "0.125rem solid",
								borderColor: colors.primaryGreen,
								color: colors.primaryGreen,
							},
						}}
					>
						Subscribir
					</Button>
				</DialogActions>
			</Dialog>
		</>
	) : null;
};

export default AddSubscriptions;
const Title = styled.h1`
	padding-left: 5%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 20.5423px;
	line-height: 25px;

	color: ${colors.deepGreen};
`;
