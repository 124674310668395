import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import { userApi } from "../apis/users.api";
import { authApi } from "../apis/auth.api";
import auth from "../slices/auth.slice";
import { contactsApi } from "../apis/contact.api";
import { companiesApi } from "../apis/company.api";
import { newsApi } from "../apis/news.api";
import { dataClientsApi } from "../apis/data.api";

export const store = configureStore({
	reducer: {
		[userApi.reducerPath]: userApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
		[contactsApi.reducerPath]: contactsApi.reducer,
		[companiesApi.reducerPath]: companiesApi.reducer,
		[newsApi.reducerPath]: newsApi.reducer,
		[dataClientsApi.reducerPath]: dataClientsApi.reducer,
		counter: counterReducer,
		auth,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		})
			.concat(userApi.middleware)
			.concat(authApi.middleware)
			.concat(contactsApi.middleware)
			.concat(companiesApi.middleware)
			.concat(newsApi.middleware)
			.concat(dataClientsApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
