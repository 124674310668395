import React from "react";
import styled from "styled-components";
import colors from "../../../ui/colors";

interface ButtonProps {
	backgroundColor: string;
	buttonLabel?: string;
	textColor?: string;
	OnClick?: () => void;
}
export const ButtonComponent: React.FC<ButtonProps> = ({
	backgroundColor,
	buttonLabel,
	textColor,
	OnClick,
}) => {
	return (
		<Button
			backgroundColor={backgroundColor}
			textColor={textColor}
			onClick={OnClick}
		>
			{buttonLabel}
		</Button>
	);
};

interface ButtonStyleProps extends ButtonProps {
	isActive?: boolean;
	textColor?: string;
}

const Button = styled.button<ButtonStyleProps>`
	width: 12.2%;
	height: 44.2%;
	min-width: 138px;
	min-height: 38px;
	max-width: 192px;
	max-height: 44px;
	color: ${(props: any) => props.textColor};
	background-color: ${(props: any) => props.backgroundColor};
	border-radius: 4px;
	border: 0.065rem solid ${colors.primaryGreen};

	font-family: "Poppins";
	font-style: normal;
	font-weight: ${(props: any) =>
		props.backgroundColor === colors.primaryGreen ? 700 : 400};
	font-size: 1rem;
	font-size: 1rem;
	line-height: 1.375rem;
	text-align: center;
	cursor: pointer;
`;
