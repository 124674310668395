import styled from "styled-components";
import React from "react";
import colors from "../../../ui/colors";
import { Aduana } from "../../../enum/aduana-Options";
import { Carpetas } from "../../../dto/data-clients.request.dto";
import { Grid } from "@mui/material";
import { transformDateString } from "../../../helpers/DateFormat";

export interface FolderResumeProps {
	FolderData: Carpetas;
	Role?: string;
}

export const FolderResume: React.FC<FolderResumeProps> = ({ FolderData }) => {
	const getCanalColor = (canal: string) => {
		switch (canal) {
			case "R":
				return "ROJO";
			case "V":
				return "VERDE";
			case "N":
				return "NARANJA";
			default:
				return "- - -";
		}
	};

	const folder = FolderData;

	return (
		<Grid
			container
			sx={{
				paddingTop: "1%",
				display: "flex",
				height: "100%",
				flexDirection: "column",
				justifyContent: "space-between",
				overflow: "hidden",
				border: `1px solid ${colors.primaryGreen}`,
				boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
				borderRadius: "8px",
			}}
		>
			<Information>{folder.NombreCliente}</Information>
			<Information>{`Carpeta ${folder.Carpetas.Identificacion.Carpeta}`}</Information>
			<Information>
				{" "}
				Aduana:{" "}
				<Complement>{`${folder.Carpetas.DatosDUA.AduanaOrigen} - ${
					Aduana[Number(folder.Carpetas.DatosDUA.AduanaOrigen)]
				}`}</Complement>{" "}
			</Information>
			<Information>
				{" "}
				Número de DUA:{" "}
				<Complement>{folder.Carpetas.DatosDUA.NroDUA}</Complement>{" "}
			</Information>
			<Information>
				{" "}
				Canal:{" "}
				<Complement>
					{getCanalColor(folder.Carpetas.DatosDUA.Canal)}
				</Complement>{" "}
			</Information>
			<Information>
				{" "}
				Fecha de numerado:{" "}
				<Complement>
					{transformDateString(folder.Carpetas.DatosDUA.FechaNumerado)}
				</Complement>{" "}
			</Information>
			<Information>
				{" "}
				Proveedor/Destinatario:{" "}
				<Complement>{folder.Carpetas.DatosDUA.Proveedor}</Complement>{" "}
			</Information>
			<Information></Information>
		</Grid>
	);
};

const Information = styled.div`
	margin: 0;
	height: 6%;
	padding-left: 4%;
	padding-top: 2%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.2px;
	color: ${colors.primaryGreen};
`;

const Complement = styled.h2`
	margin: 0;
	height: 6%;
	display: inline-block;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.2px;

	color: ${colors.primaryGreen};
`;
