import React from "react";
import { UserOptions } from "../../../enum/user-Option";
import ClientsTableRender from "../../../template/Tables/ClientsTableRender/ClientsTableRender";
import NewsTable from "../../../template/Tables/NewsTable/NewsTable";
import { TableTitles } from "../../../enum/table-titles";
import { DataClients } from "../../../models/Data";
import { Contact } from "../../../models/Contact";
import { News } from "../../../models/News";

interface Props {
	CompanySelectedId: string;
	activeButton: string;
	ElementsData: News[] | Contact[];
	isFolder: boolean;
	isUserName: boolean;
	tableData?: DataClients[];
	Role: string;
	UserRegister: string;
	UserSelected: (UserName: string, folderQuest: string) => void;
	SelectedCompany: string;
	SelectedFolder: string;
	DateRange: string[];
	Filter: string;
	searchMain: string | undefined;
	searchClient: string | undefined;
}

export const MainRender: React.FC<Props> = ({
	activeButton,
	ElementsData,
	isFolder,
	isUserName,
	Role,
	UserRegister,
	UserSelected,
	SelectedCompany,
	SelectedFolder,
	DateRange,
	Filter,
	searchMain,
	searchClient,
}) => {
	const handleClickedName = (userName: string, folderQuest: string) => {
		UserSelected(userName, folderQuest);
	};
	if (activeButton === UserOptions.Company) {
		return (
			<ClientsTableRender
				Filter={Filter}
				FolderStatus={isFolder}
				UserStatus={isUserName}
				UserRegister={UserRegister}
				UserSelected={handleClickedName}
				SelectedCompany={SelectedCompany}
				SelectedFolder={SelectedFolder}
				DateRange={DateRange}
				searchMain={searchMain}
				searchClient={searchClient}
			/>
		);
	} else if (activeButton === UserOptions.News) {
		return (
			<NewsTable
				Data={ElementsData as News[]}
				Titles={TableTitles.Novedad}
				UserRole={Role}
			/>
		);
	} else {
		return (
			<ClientsTableRender
				Filter={Filter}
				FolderStatus={isFolder}
				UserStatus={isUserName}
				UserRegister={UserRegister}
				UserSelected={handleClickedName}
				SelectedCompany={SelectedCompany}
				SelectedFolder={SelectedFolder}
				DateRange={DateRange}
				searchMain={searchMain}
				searchClient={searchClient}
			/>
		);
	}
};

export default MainRender;
