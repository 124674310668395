import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { News } from "../models/News";
import { CreateNewsRequest } from "../dto/create-new-request.dto";
import { RootState } from "../app/store";

export const newsApi = createApi({
	reducerPath: "newsAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BACK_URL}/news`,
		prepareHeaders: (headers, { getState }) => {
			const state = getState() as RootState;
			const token = state.auth.token;
			if (token) {
				headers.set("authentication", `${token}`);
			}
			return headers;
		},
	}),
	endpoints: (build) => ({
		getAllNews: build.query<News[], void>({
			query: () => "/",
		}),
		createNews: build.mutation<News, CreateNewsRequest>({
			query: (createNewsRequest) => ({
				url: "/",
				method: "POST",
				body: createNewsRequest,
			}),
		}),
		deleteNews: build.mutation<void, string>({
			query: (id) => ({
				url: `/${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

export const {
	useGetAllNewsQuery,
	useCreateNewsMutation,
	useDeleteNewsMutation,
} = newsApi;
