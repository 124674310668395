import { useLocation } from "react-router-dom";
import { useGetAllUsersQuery } from "../apis/users.api";
export const UpdateData = () => {
	const location = useLocation();
	const userInfo = location.state?.userInfo;

	const {
		data: allUsersRaw,
		isLoading: allUsersDataLoadingRaw,
		refetch: refetchAllUsers,
	} = useGetAllUsersQuery();

	const shouldUseUsers = ["Administrador", "MRM", "Creator"].includes(
		userInfo?.Role
	);
	const allUsers = shouldUseUsers ? allUsersRaw : [];
	const allUsersDataLoading = shouldUseUsers ? allUsersDataLoadingRaw : false;

	return {
		allUsers,
		allUsersDataLoading,
		refetchAllUsers,
	};
};
