import React, { useEffect, useState } from "react";
import InputField from "../../Input/InputField";
import styled from "styled-components";
import { colors } from "../../../ui/colors";
import { FormikErrors } from "formik";
import { ONLY_LETTERS, REQUIRED } from "../../../schemas/YupValidations";

type FormContact = {
	nameCompany: string;
};
interface ContactProps {
	onChange: (values: Record<string, string>) => void;
	errors: FormikErrors<FormContact>;
}

export const CompanyForm: React.FC<ContactProps> = ({ onChange, errors }) => {
	const [values, setValues] = useState<Record<string, string>>({});
	const [errorStatus, setErrorStatus] = useState({
		nameError: false,
	});
	useEffect(() => {
		setErrorStatus({
			nameError:
				errors.nameCompany === REQUIRED || errors.nameCompany === ONLY_LETTERS,
		});
	}, [errors]);
	const handleInputChange = (name: string, value: string) => {
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
		onChange(values);
	};

	return (
		<>
			<Title>Empresa</Title>
			<InputField
				name={"Nombre"}
				type={"text"}
				placeholder={"Ingresa nombre de la empresa"}
				onInputChange={(value) => handleInputChange("nameCompany", value)}
				errorValue={errorStatus.nameError}
				isRequired={true}
			/>
		</>
	);
};

export default CompanyForm;

const Title = styled.h1`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 20.5423px;
	line-height: 25px;

	color: ${colors.deepGreen};
`;
