import React from "react";
import MRMLogo from "../../../assets/images/LogoMRM-HD.png";
import MRMname from "../../../assets/images/murillo-HD.png";
import colors from "../../colors";
import styled from "styled-components";
import { SideNav } from "../sideNav.tsx/SideNav";
import { Link } from "react-router-dom";

const StyledNav = styled.nav`
	height: 8.5rem;
	width: 100%;
	background-color: ${colors.white};
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	position: fixed;
	z-index: 3;
	transition: background-color 500ms linear;
`;

const StyledNameImg = styled.img`
	transform: scale(0.6);
	margin: 0 0 0 -8rem;
`;

const StyledLogoDiv = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledLogoImg = styled.img`
	transform: scale(0.5);
`;

const StyledOutlinedButtonLink = styled(Link)`
	border: 1.5px solid ${colors.green};
	padding: 0.5rem 1rem;
	margin: 0 1rem 0 0;
	color: ${colors.deepGreen};
	background-color: ${colors.white};
	text-decoration: none;
	border-radius: 6px;
`;

const StyledFilledButtonLink = styled(Link)`
	border: 1.5px solid ${colors.green};
	padding: 0.5rem 1rem;
	background-color: ${colors.deepGreen};
	color: ${colors.white};
	text-decoration: none;
	border-radius: 6px;
`;
const StyledButtonWrapperDiv = styled.div`
	@media (max-width: 950px) {
		flex-direction: column;
		display: flex;
	}
`;

const StyledWrapperNavDiv = styled.div`
	display: flex;
	width: 100%;
	padding: 2rem 2rem;
	align-items: center;
	justify-content: space-between;
`;

const StyledSideNavDiv = styled.div`
	display: none;
`;

const StyledLink = styled(Link)`
	padding: 0;
`;

interface LinksProps {
	onClickSecurityPolicy?: (e: any) => void | undefined;
	onClickQualityPolicy?: (e: any) => void | undefined;
	onClickServiceCompromise?: (e: any) => void | undefined;
}

export const AppBar: React.FC<LinksProps> = ({
	onClickSecurityPolicy,
	onClickQualityPolicy,
	onClickServiceCompromise,
}) => {
	return (
		<header>
			<StyledNav>
				<StyledWrapperNavDiv>
					<StyledLogoDiv>
						<Link to="/">
							<StyledLogoImg src={MRMLogo} />
						</Link>
						<StyledLink to="/">
							<StyledNameImg src={MRMname} />
						</StyledLink>
					</StyledLogoDiv>
					<StyledButtonWrapperDiv>
						<StyledOutlinedButtonLink to="/login">
							Iniciar Sesión
						</StyledOutlinedButtonLink>
						<StyledFilledButtonLink to="/links">
							Contacto
						</StyledFilledButtonLink>
					</StyledButtonWrapperDiv>
					<StyledSideNavDiv>
						<SideNav
							onClickSecurityPolicy={onClickSecurityPolicy}
							onClickQualityPolicy={onClickQualityPolicy}
							onClickServiceCompromise={onClickServiceCompromise}
						/>
					</StyledSideNavDiv>
				</StyledWrapperNavDiv>
			</StyledNav>
		</header>
	);
};

export default AppBar;
