export enum SortOptions {
	"DatePlus" = "Fecha +",
	"DateMinus" = "Fecha -",
	"Name" = "Nombre",
	"Folder" = "Carpeta",
}
export enum SortOptionsReturn {
	"Fecha +" = "DatePlus",
	"Fecha -" = "DateMinus",
	"Nombre" = "Name",
	"Carpeta" = "Folder",
}
