import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputField from "../../../template/Input/InputField";
import colors from "../../../ui/colors";
import InputSelector from "../../InputSelector/InputSelector";
import useCustomForm from "../../../hooks/useForm";
import {
	REQUIRED,
	VALID_PASSWORD,
	VALID_EMAIL,
} from "../../../schemas/YupValidations";
import {
	CreateUserInitial,
	CreateUserSchema,
} from "../../../schemas/YupValidations";
import Loader from "../../Loader/Loader";
import MultiSubscriptor from "../../MultiSubscriptor/MultiSubscriptor";
import { CreationDataClients } from "../../../models/Data";
import { Roles } from "../../../enum/roles-Options";
import { getAllSubscribeData } from "../../../apis/data.api";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

interface AddUserProps {
	CreateHandler: (
		company: string,
		email: string,
		password: string,
		position: string,
		role: string,
		username: string,
		createdBy: string,
		subscriptions: string[]
	) => void;
	setTrigger: (arg0: boolean) => void;
	userCreating: string;
	isLoading: boolean;
	UserRole: string;
}
export const AddUserForm: React.FC<AddUserProps> = ({
	CreateHandler,
	setTrigger,
	userCreating,
	isLoading,
	UserRole,
}) => {
	let RolesM;
	if (UserRole === Roles.MRM) {
		RolesM = [Roles.MRM, Roles.Client];
	} else {
		RolesM = [Roles.Admin, Roles.MRM, Roles.Client];
	}
	const token = useSelector((state: RootState) => state.auth.token);
	const [subscribeData, setSubscribeData] = useState<CreationDataClients[]>([]);
	const [subscriptions, setSubscriptions] = useState<string[]>([]);
	const fetchSubscribedData = async () => {
		try {
			const data = await getAllSubscribeData(token as string);
			setSubscribeData(data);
		} catch (error) {
			console.error(error);
		} finally {
		}
	};

	useEffect(() => {
		fetchSubscribedData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUserCreation = () => {
		CreateHandler(
			values.username,
			values.password,
			values.email,
			values.role,
			values.position.toUpperCase(),
			values.company.toUpperCase(),
			userCreating,
			subscriptions
		);
	};
	const { values, setFieldValue, errors } = useCustomForm(
		CreateUserInitial,
		CreateUserSchema,
		handleUserCreation
	);
	const [errorStatus, setErrorStatus] = useState({
		usernameError: false,
		passwordError: false,
		emailError: false,
		roleError: false,
		positionError: false,
		companyError: false,
	});
	useEffect(() => {
		setErrorStatus({
			usernameError: errors.username === REQUIRED,
			passwordError: errors.password === VALID_PASSWORD,
			emailError: errors.email === VALID_EMAIL,
			roleError: errors.role === REQUIRED,
			positionError: errors.position === REQUIRED,
			companyError: errors.company === REQUIRED,
		});
	}, [errors]);
	return subscribeData ? (
		<>
			<Title>Agregar Usuario</Title>
			<InputField
				name={"Usuario"}
				type={"text"}
				placeholder={"Ingresar usuario"}
				onInputChange={(value) => {
					setFieldValue("username", value);
				}}
				isRequired={true}
				errorValue={errorStatus.usernameError}
			/>
			<br />
			<InputField
				name={"Email"}
				type={"email"}
				placeholder={"Ingresar email"}
				onInputChange={(value) => {
					setFieldValue("email", value);
				}}
				isRequired={true}
				errorValue={errorStatus.emailError}
			/>
			<br />
			<InputContainer>
				<InputField
					name={"Contraseña"}
					type={"text"}
					inputWidth="217.5px"
					placeholder={"Ingresar contraseña"}
					onInputChange={(value) => {
						setFieldValue("password", value);
					}}
					isRequired={true}
					errorValue={errorStatus.passwordError}
				/>
				<InputField
					name={"Empresa"}
					type={"text"}
					inputWidth="217.5px"
					placeholder={"Ingresar cargo"}
					onInputChange={(value) => {
						setFieldValue("company", value);
					}}
					isRequired={true}
					errorValue={errorStatus.companyError}
				/>
				<InputField
					name={"Cargo"}
					type={"text"}
					inputWidth="217.5px"
					placeholder={"Ingresar cargo"}
					onInputChange={(value) => {
						setFieldValue("position", value);
					}}
					isRequired={false}
					errorValue={false}
				/>
				<InputSelector
					name="Role"
					placeholder="Seleccione role"
					onInputChange={(value) => {
						setFieldValue("role", value);
					}}
					inputWidth="217.5px"
					isRequired={true}
					userTypes={RolesM}
					errorValue={errorStatus.roleError}
				/>
			</InputContainer>
			<MultiSubscriptorDiv>
				<InputLabel>{"Subscripciones"}</InputLabel>
				<MultiSubscriptor
					companiesData={subscribeData}
					onSelectionChange={(selectedIds) => {
						setSubscriptions(selectedIds);
					}}
				/>
			</MultiSubscriptorDiv>
			<ButtonsWrap>
				{isLoading ? (
					<CenteredDiv>
						<Loader color={colors.deepGreen} />
					</CenteredDiv>
				) : (
					<ButtonPop
						onClick={handleUserCreation}
						type="submit"
						backgroundColor={`${colors.primaryGreen}`}
						textColor={`${colors.white}`}
					>
						{`Agregar usuario`}
					</ButtonPop>
				)}

				<ButtonPop
					onClick={() => setTrigger(false)}
					backgroundColor={`${colors.white}`}
					textColor={`${colors.primaryGreen}`}
				>
					{`Cancelar`}
				</ButtonPop>
			</ButtonsWrap>
		</>
	) : null;
};
export const InputLabel = styled.p`
	margin: 0;
	padding: 0;
	padding-bottom: 2.5%;
	width: auto;
	height: auto;
	left: 0;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12.0247px;
	line-height: 15px;

	color: ${colors.primaryGreen};
`;
export const CenteredDiv = styled.div`
	width: 21rem;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const MultiSubscriptorDiv = styled.div`
	margin-top: 1rem;
	height: 40;
`;
const InputContainer = styled.div`
	margin-top: 2.5%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 2 auto;
	grid-column-gap: 20px;
	grid-row-gap: 10px;
`;

const Title = styled.h1`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 20.5423px;
	line-height: 25px;

	color: ${colors.deepGreen};
`;
const ButtonsWrap = styled.div`
	margin-top: 10%;
	display: grid;
	padding-left: 15%;
	grid-template-columns: 1fr;
	grid-auto-rows: 65px;
	margin-bottom: 5%;
`;
const ButtonPop = styled.button<{
	backgroundColor: string;
	textColor: string;
	boderColor?: string;
}>`
	width: 341.2px;
	height: 46.64px;
	background: ${({ backgroundColor }) => backgroundColor};
	color: ${({ textColor }) => textColor};
	border: 2px solid ${colors.primaryGreen};
	border-radius: 6px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 0.825rem;
	line-height: 18px;

	:hover {
		color: ${({ textColor }) =>
			textColor === colors.white ? colors.primaryGreen : colors.white};
		background: ${({ backgroundColor }) =>
			backgroundColor === colors.primaryGreen
				? colors.white
				: colors.primaryGreen};
		border: 2px solid
			${({ backgroundColor }) =>
				backgroundColor === colors.primaryGreen
					? colors.primaryGreen
					: colors.white};
	}
`;
